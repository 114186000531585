import {
  getShopProductAccessoriesArgsSchema,
  getShopProductAccessoriesArtifactSchema,
  getShopProductAccessoriesContentSchema,
} from '@kanbu/schema/contracts';
import { plural } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { motion } from 'framer-motion';
import { Search } from 'lucide-react';

import { ShopProductCarousel } from './ShopProductCarousel';
import { createToolComponent } from '../../../toolRegistry';
import { ToolError } from '../components/ToolError';
import { ToolStatus } from '../components/ToolStatus';

export const GetShopProductAccessoriesTool = createToolComponent(
  {
    schema: {
      args: getShopProductAccessoriesArgsSchema,
      artifact: getShopProductAccessoriesArtifactSchema,
      content: getShopProductAccessoriesContentSchema,
    },
    validate: false,
  },
  ({ state, error, artifact }) => {
    const { accessories } = artifact ?? {};

    switch (state) {
      case 'call':
        return (
          <ToolStatus loading>
            <Trans>Analyzing your request...</Trans>
          </ToolStatus>
        );

      case 'call-partial':
      case 'call-complete':
        return (
          <ToolStatus loading>
            <Trans>Looking for accessories...</Trans>
          </ToolStatus>
        );

      case 'error':
        return (
          <ToolError
            message={<Trans>No accessories found</Trans>}
            error={error}
          />
        );

      case 'result':
        if (!accessories || accessories.length === 0) {
          return null;
        }

        return (
          <motion.div
            className='w-full'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className='flex flex-col gap-1.5'>
              <ToolStatus icon={<Search />}>
                {plural(
                  {
                    count: accessories?.length ?? 0,
                  },
                  {
                    one: 'Found 1 accessory',
                    other: 'Found # accessories',
                  },
                )}
              </ToolStatus>
            </div>

            <ShopProductCarousel items={accessories} />
          </motion.div>
        );

      default:
        return null;
    }
  },
);
