import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';
import { FeedSource, FeedStatus } from '../enums';

export const FeedSchema = baseOrganizationEntitySchema.extend({
  name: z.string().max(100),
  url: z.string().url(),
  source: z.nativeEnum(FeedSource),
  chat: z.string().uuid(),
  updateFrequencyMinutes: z.number().int().positive().nullish(),
  status: z.nativeEnum(FeedStatus).nullish(),
  importMetadata: z
    .object({
      retries: z.number().int().positive().nullish(),
      lastError: z.string().nullish(),
    })
    .nullish(),
});

export const createFeedSchema = FeedSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  organization: true,
  status: true,
  importMetadata: true,
  updateFrequencyMinutes: true,
}).strict();

export const updateFeedSchema = FeedSchema.partial()
  .required({
    id: true,
  })
  .omit({
    organization: true,
  })
  .strict();

export type FeedDTO = z.infer<typeof FeedSchema>;
export type CreateFeedDTO = z.infer<typeof createFeedSchema>;
export type UpdateFeedDTO = z.infer<typeof updateFeedSchema>;
