import { memo } from 'react';

import { OnlineIndicator } from '@/components/onlineIndicator/OnlineIndicator';
import { useOnlineStatus } from '@/hooks';

export interface OnlineStatusProps {
  lastActiveAt: string | null | undefined;
  showWhenOffline?: boolean;
  withText?: boolean;
}

export const OnlineStatus = memo(
  ({
    lastActiveAt,
    withText = true,
    showWhenOffline = true,
  }: OnlineStatusProps) => {
    const status = useOnlineStatus(lastActiveAt);

    if (!showWhenOffline && status === 'offline') {
      return null;
    }

    return <OnlineIndicator status={status} withText={withText} />;
  },
);
