import type { Config } from '@kanbu/config';

export type ConfigType = {
  debug: boolean;
};

const envConfig =
  __KANBU_CONFIG__ as any as Config['app']['adminApp']['webConfig'] & {
    debug: boolean;
    version: string;
  };

export const AppSettings = {
  ...envConfig,
  debug: document.cookie.includes('debug=true') || envConfig.debug,
};
