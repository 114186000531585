import { Role } from '@kanbu/schema/enums';
import { useLingui } from '@lingui/react/macro';
import { useFieldState, useFieldApi } from 'informed';
import { memo } from 'react';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useBoundStore } from '@/store/store';

export const TierConfigFields = memo(function TierConfigFields() {
  const { t } = useLingui();

  const user = useBoundStore(state => state.user);

  const { value: disableAllInboxUnread } = useFieldState(
    'tierConfig.disableAllInboxUnread',
  );

  const { setValueQuietly } = useFieldApi('tierConfig.disableAiInboxUnread');

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'name',
        label: t`Name`,
        required: true,
      },
      {
        name: 'monthlyUsageLimit',
        label: t`Monthly usage limit`,
        type: 'number',
        defaultValue: 5,
        required: true,
        uiProps: {
          addonAfter: '$',
        },
      },
      {
        $relevant: {
          $when: () => user?.role === Role.SuperAdmin,
          $fields: [
            {
              name: 'tierConfig.usersLimit',
              label: t`Users limit`,
              type: 'number',
              uiProps: {
                min: 1,
              },
              required: true,
            },
            {
              name: 'tierConfig.voiceBot',
              label: t`Voice bot`,
              type: 'checkbox',
              uiControl: 'switch',
              defaultValue: false,
            },
          ],
        },
      },
      {
        name: 'tierConfig.disableAiInboxUnread',
        label: t`Disable AI inbox unread notifications`,
        tooltip: t`Threads that are controlled by operators will still be notified`,
        type: 'checkbox',
        uiControl: 'switch',
        defaultValue: false,
        uiProps: {
          readOnly: disableAllInboxUnread,
        },
      },
      {
        name: 'tierConfig.disableAllInboxUnread',
        label: t`Disable all inbox unread notifications`,
        type: 'checkbox',
        uiControl: 'switch',
        defaultValue: false,
        uiProps: {
          onCheckedChange: () => {
            setValueQuietly(!disableAllInboxUnread);
          },
        },
      },
    ],
    [t, disableAllInboxUnread],
  );

  return (
    <Card title={t`Basic details`}>
      <SchemaFields schema={detailsSchema} />
    </Card>
  );
});
