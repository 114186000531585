import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import type { ReactNode } from 'react';

export interface ToolStatusProps {
  icon?: ReactNode;
  children: ReactNode;
  loading?: boolean;
}

export function ToolStatus({ icon, children, loading }: ToolStatusProps) {
  return (
    <motion.div
      className='flex items-center gap-2 text-sm'
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <span className='flex items-center justify-center h-6 w-6 rounded-md border border-border bg-gray-100 [&>svg]:size-4'>
        {loading ? <Loader2 className='animate-spin' /> : icon}
      </span>
      <span className='font-medium text-sm'>{children}</span>
    </motion.div>
  );
}
