import type { OrganizationType } from '@kanbu/schema';
import { isTimeRangeValid } from '@kanbu/shared';
import { Trans, useLingui } from '@lingui/react/macro';
import { Separator } from '@utima/ui';
import { Input } from '@utima/ui-informed';
import { useFieldApi, useFieldState } from 'informed';
import { memo, useCallback, useMemo } from 'react';

import { Card } from '@/components/card/Card';

import { WorkingHoursDay } from './WorkingHoursDay';

type Props = {
  name: string;
};

export const WorkingHours = memo(function WorkingHours({ name }: Props) {
  const { t } = useLingui();

  const { setValueQuietly } = useFieldApi(name);

  const workingHours = useFieldState(name)
    .value as OrganizationType['workingHours'];

  /**
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
   * Sunday - Saturday : 0 - 6
   *
   * TODO: Add support for first day of the week based on locale.
   * https://github.com/tc39/proposal-intl-locale-info
   */
  const days = useMemo(
    () => [
      { day: '1', label: t`Monday` },
      { day: '2', label: t`Tuesday` },
      { day: '3', label: t`Wednesday` },
      { day: '4', label: t`Thursday` },
      { day: '5', label: t`Friday` },
      { day: '6', label: t`Saturday` },
      { day: '0', label: t`Sunday` },
    ],
    [t],
  );

  const onChange = useCallback(
    (value: Partial<typeof workingHours>) => {
      setValueQuietly({
        ...workingHours,
        ...value,
      });
    },
    [setValueQuietly, workingHours],
  );

  const validate = useCallback(
    (value: unknown) => {
      const workingHours = value as OrganizationType['workingHours'];

      if (!workingHours) {
        return;
      }

      for (const day of Object.values(workingHours)) {
        if (!Array.isArray(day)) {
          continue;
        }

        for (const time of day) {
          if (!isTimeRangeValid(time.from, time.to)) {
            return t`Invalid time range.`;
          }
        }
      }
    },
    [t],
  );

  return (
    <Card title={t`Working hours`}>
      <p className='text-sm'>
        <Trans>
          Set working hours during which users can request assistance from a
          human operator.
        </Trans>
      </p>
      <Separator />
      <div className='grid grid-cols-[auto_max-content_1fr] items-start gap-2'>
        {days.map(({ day, label }) => {
          return (
            <WorkingHoursDay
              key={day}
              label={label}
              day={day as keyof typeof workingHours}
              workingHoursDay={workingHours?.[day as keyof typeof workingHours]}
              onChange={onChange}
            />
          );
        })}
      </div>
      <Input name={name} type='hidden' validate={validate} />
    </Card>
  );
});
