import { cn } from '@utima/ui';
import { useContext, useMemo, type ReactNode, memo } from 'react';

import type { MessagesGroup } from '../../types';
import { ActivityIndicator } from '../ActivityIndicator';
import {
  type MessagePosition,
  type MessageVariant,
  MessageContext,
  type MessageContextValue,
} from '../message/MessageContext';

/**
 * Shared props for all specific message components.
 */
export interface SharedMessageProps {
  group: MessagesGroup;
  variant?: MessageVariant;
  position?: MessagePosition;
}

export interface MessageRootProps extends MessageContextValue {
  children: ReactNode;
  className?: string;
}

/**
 * Root component for the Message system. Provides context for message styling and positioning.
 * Wraps all other Message components and handles the overall layout and alignment.
 *
 * @example
 * <Message.Root variant="primary" position="left">
 *   <Message.Content>...</Message.Content>
 * </Message.Root>
 */
function MessageRoot({
  variant = 'primary',
  position = 'left',
  children,
  className,
}: MessageRootProps) {
  const contextValue = useMemo(
    () => ({ variant, position }),
    [variant, position],
  );

  return (
    <MessageContext.Provider value={contextValue}>
      <div
        className={cn(
          'flex flex-col gap-1',
          position === 'right' ? 'items-end' : 'items-start',
          className,
        )}
      >
        {children}
      </div>
    </MessageContext.Provider>
  );
}

/**
 * Displays header content for a message, such as sender name or timestamp.
 * Automatically aligns based on message position (left/right).
 *
 * @example
 * <Message.Header>John Doe</Message.Header>
 */
function MessageHeader({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const { position } = useContext(MessageContext);

  return (
    <div
      className={cn(
        'text-sm font-medium',
        position === 'right' ? 'text-right' : 'text-left',
        className,
      )}
    >
      {children}
    </div>
  );
}

/**
 * Wrapper component that handles the layout of message content and sidebar elements.
 * Provides proper spacing and alignment for message components.
 *
 * @example
 * <Message.Wrapper>
 *   <Message.Avatar />
 *   <Message.Content />
 * </Message.Wrapper>
 */
function MessageWrapper({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const { position } = useContext(MessageContext);

  return (
    <div
      className={cn(
        'flex items-start gap-2 max-w-[90%]',
        position === 'right' ? 'flex-row-reverse' : 'flex-row',
        className,
      )}
    >
      {children}
    </div>
  );
}

/**
 * Container for sidebar elements like avatars or action buttons.
 * Handles proper alignment based on message position.
 *
 * @example
 * <Message.Sidebar>
 *   <Avatar src="..." />
 * </Message.Sidebar>
 */
function MessageSidebar({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const { position } = useContext(MessageContext);

  return (
    <div
      className={cn(
        'flex items-end gap-2',
        position === 'right' ? 'flex-row-reverse' : 'flex-row',
        className,
      )}
    >
      {children}
    </div>
  );
}

/**
 * Main container for message content. Handles alignment and spacing of message bubbles
 * and other content elements.
 *
 * @example
 * <Message.Content>
 *   <Message.BubbleGroup>
 *     <Message.Bubble>Hello!</Message.Bubble>
 *   </Message.BubbleGroup>
 * </Message.Content>
 */
function MessageContent({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const { position } = useContext(MessageContext);

  return (
    <div
      className={cn(
        'flex flex-col gap-2 max-w-full',
        {
          'items-end': position === 'right',
          'items-start': position === 'left',
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

/**
 * Individual message bubble component. Handles styling and appearance of a single message.
 * Supports different variants (primary/secondary) and adapts corners based on grouping.
 *
 * @example
 * <Message.Bubble>This is a message</Message.Bubble>
 */
const MessageBubble = memo(
  ({ children, className }: { children: ReactNode; className?: string }) => {
    const { variant, position } = useContext(MessageContext);

    return (
      <div
        className={cn(
          'min-h-11 text-[15px] px-3.5 py-3 [&>*:last-child]:mb-0 [&>*:first-child]:mt-0',
          {
            'bg-primary text-primary-fg': variant === 'primary',
            'bg-background-secondary': variant === 'secondary',
            'bg-background-secondary border-primary border':
              variant === 'tertiary',
          },
          'rounded-3xl',
          // Handle grouped messages border radiuses
          position === 'right'
            ? '[&:not(:first-child)]:rounded-tr-md [&:not(:last-child)]:rounded-br-md rounded-bl-3xl'
            : '[&:not(:first-child)]:rounded-tl-md [&:not(:last-child)]:rounded-bl-md rounded-br-3xl',
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

/**
 * Groups multiple message bubbles together, providing proper spacing and visual continuity.
 * Useful for consecutive messages from the same sender.
 *
 * @example
 * <Message.BubbleGroup>
 *   <Message.Bubble>First message</Message.Bubble>
 *   <Message.Bubble>Second message</Message.Bubble>
 * </Message.BubbleGroup>
 */
function MessageBubbleGroup({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const { position } = useContext(MessageContext);

  return (
    <div
      className={cn(
        'flex flex-col gap-0.5',
        position === 'right' ? 'items-end' : 'items-start',
        className,
      )}
    >
      {children}
    </div>
  );
}

/**
 * Container for avatar images or icons. Handles proper sizing and layout.
 *
 * @example
 * <Message.Avatar>
 *   <img src="avatar.jpg" alt="User avatar" />
 * </Message.Avatar>
 */
function MessageAvatar({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return <div className={cn('shrink-0', className)}>{children}</div>;
}

/**
 * Displays footer content below a message, such as message status or timestamp.
 * Automatically aligns based on message position.
 *
 * @example
 * <Message.Footer>Sent at 12:34 PM</Message.Footer>
 */
function MessageFooter({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const { position } = useContext(MessageContext);

  return (
    <div
      className={cn(
        'text-xs text-placeholder mt-1',
        position === 'right' ? 'text-right' : 'text-left',
        className,
      )}
    >
      {children}
    </div>
  );
}

/**
 * Displays a typing indicator animation within a message bubble.
 * Used to show when someone is composing a message.
 *
 * @example
 * <Message.Typing />
 */
function MessageTyping({ className }: { className?: string }) {
  return (
    <MessageBubble className={cn('min-h-12 flex items-center', className)}>
      <ActivityIndicator />
    </MessageBubble>
  );
}

export const Message = {
  Root: MessageRoot,
  Header: MessageHeader,
  Sidebar: MessageSidebar,
  Content: MessageContent,
  Wrapper: MessageWrapper,
  Bubble: MessageBubble,
  BubbleGroup: MessageBubbleGroup,
  Avatar: MessageAvatar,
  Footer: MessageFooter,
  Typing: MessageTyping,
};
