import type { DocumentTypeMetadata, DocumentType } from '@kanbu/schema';
import { ContentType, DocumentStatus, Role } from '@kanbu/schema/enums';
import { useLingui } from '@lingui/react/macro';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Button, cn, Dialog, Tooltip } from '@utima/ui';
import {
  Globe,
  Link,
  FileText,
  FlaskConical,
  FileSymlink,
  DatabaseBackup,
  FileX2,
} from 'lucide-react';
import { useMemo } from 'react';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';
import { IconWrapper } from '@/components/iconWrapper/IconWrapper';
import { Protected } from '@/components/protected/Protected';
import { useEnumTranslations } from '@/locale/enumTranslations';
import { trpc } from '@/services/trpc';

import { useDocumentMutation } from './useDocumentMutation';
import { WeightSelector } from '../components/addDocumentsModal/websiteRecords/WeightSelector';
import { GenerateTestCasesDialog } from '../components/GenerateTestCasesDialog';

const columnHelper = createColumnHelper<DocumentType>();

export function useDocumentCols(chatId: string) {
  const { t } = useLingui();
  const enumTranslations = useEnumTranslations();
  const utils = trpc.useUtils();

  const {
    remove: { mutateAsync: remove },
    refresh: { mutateAsync: refresh },
    resetEmbedding: { mutateAsync: resetEmbedding },
  } = useDocumentMutation();

  const { mutateAsync: updateWeights } =
    trpc.documents.updateWeights.useMutation({
      onSuccess: () => {
        utils.documents.findAll.invalidate();
        utils.chats.findOne.invalidate({ id: chatId });
      },
    });

  return useMemo(
    () => [
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: t`Name`,
        meta: {
          sortable: 'name',
        },
        cell: ({ row }) => {
          const data = row.original;
          const description = data.description || data.shortSummary;
          const truncatedDescription = description
            ? description.length > 80
              ? description.substring(0, 80) + '...'
              : description
            : null;

          return (
            <div className='flex min-w-64 items-center gap-3'>
              <IconWrapper
                size='sm'
                className={cn(
                  data.type === ContentType.Document && 'text-danger',
                  data.type === ContentType.Website && 'text-primary',
                  data.type === ContentType.ExternalDocument &&
                    'text-amber-500',
                )}
                Icon={
                  data.type === ContentType.Document
                    ? FileText
                    : data.type === ContentType.Website
                      ? Globe
                      : FileSymlink
                }
              />
              <div className='flex flex-col gap-0.5'>
                <p className='break-all font-medium leading-tight'>
                  {data.name}
                </p>
                {truncatedDescription && (
                  <p className='hidden text-xs text-gray-600 md:block'>
                    {truncatedDescription}
                  </p>
                )}
                {data.status === DocumentStatus.ParsingError &&
                data.parsingMetadata.lastParsingError ? (
                  <span className='text-xs text-danger'>
                    {data.parsingMetadata.lastParsingError}
                  </span>
                ) : data.status === DocumentStatus.EmbeddingError &&
                  data.parsingMetadata.lastEmbeddingError ? (
                  <span className='text-xs text-danger'>
                    {data.parsingMetadata.lastEmbeddingError}
                  </span>
                ) : (
                  data.type === ContentType.Document && (
                    <p className='text-xs text-gray-600'>
                      {data.metadata?.size
                        ? (
                            (data.metadata as DocumentTypeMetadata)?.size /
                            1024 /
                            1024
                          ).toFixed(2) + ' MB'
                        : ''}
                    </p>
                  )
                )}
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        id: 'status',
        header: t`Status`,
        meta: {
          sortable: 'status',
        },
        cell: ({ row }) => (
          <Badge
            size='sm'
            variant={
              row.original.status === DocumentStatus.ParsingError ||
              row.original.status === DocumentStatus.EmbeddingError
                ? 'danger'
                : row.original.status === DocumentStatus.Done
                  ? 'success'
                  : 'info'
            }
            outline
          >
            {enumTranslations.documentStatus[row.original.status]}
          </Badge>
        ),
      }),
      columnHelper.accessor(row => row.embeddingsCount, {
        id: 'embeddingsCount',
        header: t`Embeddings count`,
        meta: {
          sortable: 'embeddingsCount',
          align: 'right',
        },
        cell: ({ row }) => Number(row.original.embeddingsCount) || '-',
      }),
      columnHelper.accessor(row => row.weight, {
        id: 'weight',
        header: t`Importance`,
        cell: ({ row }) => (
          <WeightSelector
            value={row.original.weight}
            onChange={async weight => {
              await updateWeights({
                ids: [row.original.id],
                weight,
                chatId,
              });
            }}
            showLabels={false}
            disabled={row.original.status !== DocumentStatus.Done}
          />
        ),
        meta: {
          sortable: 'weight',
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: t`Actions`,
        cell: ({ row }) => (
          <ActionsCell
            onDelete={async () => {
              await remove({ id: row.original.id });
              await utils.chats.findOne.invalidate({ id: chatId });
            }}
          >
            <Tooltip title={t`Refresh data`}>
              <Button
                variant='ghost'
                className='text-info'
                outline
                size='icon-xs'
                onClick={async () => {
                  await refresh({ id: row.original.id });
                  await utils.chats.findOne.invalidate({ id: chatId });
                }}
              >
                <DatabaseBackup className='size-4' />
              </Button>
            </Tooltip>
            <Protected roles={[Role.SuperAdmin]}>
              <Tooltip title={t`Refresh embeddings`}>
                <Button
                  variant='ghost'
                  className='text-info'
                  outline
                  size='icon-xs'
                  onClick={async () => {
                    await resetEmbedding({ id: row.original.id });
                    await utils.chats.findOne.invalidate({ id: chatId });
                  }}
                >
                  <FileX2 className='size-4' />
                </Button>
              </Tooltip>
            </Protected>
            <Button asChild variant='ghost' outline size='icon-xs'>
              <a href={row.original.publicUrl} target='_blank' rel='noreferrer'>
                <Link className='size-4' />
              </a>
            </Button>
            <Protected roles={[Role.SuperAdmin]}>
              <Dialog.Root>
                <Tooltip title={t`Generate test cases`}>
                  <Dialog.Trigger asChild>
                    <Button variant='ghost' outline size='icon-xs'>
                      <FlaskConical className='size-4' />
                    </Button>
                  </Dialog.Trigger>
                </Tooltip>
                <GenerateTestCasesDialog document={row.original} />
              </Dialog.Root>
            </Protected>
          </ActionsCell>
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [
      t,
      enumTranslations,
      remove,
      refresh,
      utils.chats.findOne,
      resetEmbedding,
      updateWeights,
      chatId,
    ],
  );
}
