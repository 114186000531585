import {
  getShopProductArgsSchema,
  getShopProductArtifactSchema,
  getShopProductContentSchema,
} from '@kanbu/schema/contracts';
import { Trans } from '@lingui/react/macro';
import { Badge, Button } from '@utima/ui';
import { motion } from 'framer-motion';
import { Gift, Package, Tag } from 'lucide-react';
import { useState } from 'react';

import { createToolComponent } from '../../../toolRegistry';
import { Card } from '../../ui';
import { ToolError } from '../components/ToolError';
import { ToolStatus } from '../components/ToolStatus';

export function Collapsible({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);

  if (isOpen) {
    return children;
  }

  return (
    <div className='space-y-2 max-h-32px relative'>
      <div className='absolute inset-0 bg-gradient-to-b from-transparent to-black/50' />
      <div className='overflow-hidden h-32'>{children}</div>
      <div className='absolute left-1/2 -translate-x-1/2 bottom-2'>
        <Button
          variant='secondary'
          outline
          size='xs'
          onClick={() => setIsOpen(!isOpen)}
        >
          <Trans>Show more</Trans>
        </Button>
      </div>
    </div>
  );
}

export const GetShopProductTool = createToolComponent(
  {
    validate: false,
    schema: {
      args: getShopProductArgsSchema,
      artifact: getShopProductArtifactSchema,
      content: getShopProductContentSchema,
    },
  },
  ({ state, artifact, error }) => {
    switch (state) {
      case 'call':
        return (
          <ToolStatus loading>
            <Trans>Fetching product details...</Trans>
          </ToolStatus>
        );

      case 'call-partial':
      case 'call-complete':
        return (
          <ToolStatus loading>
            <Trans>Parsing product details...</Trans>
          </ToolStatus>
        );

      case 'error':
        return (
          <ToolError message={<Trans>Product not found</Trans>} error={error} />
        );

      case 'result':
        return (
          <motion.div
            className='w-full'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Card.Root className='shadow-none overflow-hidden border border-border'>
              <div className='relative h-36 w-full border-b border-border bg-gray-50'>
                {artifact?.imageUrl && (
                  <img
                    src={artifact?.imageUrl}
                    alt={artifact?.name}
                    className='object-cover size-full'
                  />
                )}

                {/* Price badge */}
                <div className='absolute top-3 right-3'>
                  <Badge
                    variant='primary'
                    className='text-base font-bold px-3 py-1'
                  >
                    {artifact?.price} Kč
                  </Badge>
                </div>
              </div>
              <Collapsible>
                <Card.Header>
                  <Card.Title className='text-xl'>
                    {artifact?.title || artifact?.name}
                  </Card.Title>
                </Card.Header>

                <Card.Content className='space-y-2'>
                  {/* Attributes section */}
                  {artifact?.attributes && artifact.attributes.length > 0 && (
                    <div className='space-y-2'>
                      <div className='flex items-center gap-2 text-secondary font-semibold'>
                        <Tag size={16} />
                        <h3>
                          <Trans>Specifications</Trans>
                        </h3>
                      </div>
                      <div className='flex flex-col gap-y-2 text-sm'>
                        {artifact.attributes.map(attribute => (
                          <div
                            key={attribute.id}
                            className='flex justify-between gap-1'
                          >
                            <span className='text-muted-foreground font-semibold'>
                              {attribute.attribute.displayName}:
                            </span>
                            <span className='font-medium'>
                              {attribute.value}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Gifts section */}
                  {artifact?.gifts && artifact.gifts.length > 0 && (
                    <div className='space-y-2'>
                      <div className='flex items-center gap-2 text-secondary font-semibold'>
                        <Gift size={16} />
                        <h3>
                          <Trans>Included gifts</Trans>
                        </h3>
                      </div>
                      <div className='space-y-1 ml-2'>
                        {artifact.gifts.map(gift => (
                          <div key={gift} className='flex items-center gap-2'>
                            <span className='px-2 py-1 bg-secondary/5'>
                              {gift}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Accessories section */}
                  {artifact?.accessories && artifact.accessories.length > 0 && (
                    <div className='space-y-2'>
                      <div className='flex items-center gap-2 text-secondary font-semibold'>
                        <Package size={16} />
                        <h3>
                          <Trans>Recommended accessories</Trans>
                        </h3>
                      </div>
                      <ul className='list list-disc text-sm ml-4'>
                        {artifact.accessories.map(accessory => (
                          <li key={accessory.id}>
                            <a
                              href={accessory.url}
                              className='hover:underline cursor-pointer'
                            >
                              {accessory.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <div className='flex justify-end pt-2'>
                    <Button asChild variant='secondary' size='sm'>
                      <a
                        href={artifact?.url}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='inline-flex items-center gap-2 bg-primary text-primary-fg px-3 py-2 font-medium rounded-md hover:bg-primary/90 transition-colors'
                      >
                        <Trans>View product details</Trans>
                      </a>
                    </Button>
                  </div>
                </Card.Content>
              </Collapsible>
            </Card.Root>
          </motion.div>
        );

      default:
        return null;
    }
  },
);
