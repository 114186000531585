import { Link, type Register } from '@tanstack/react-router';
import { Layout, Separator, Breadcrumb as UIBreadcrumb } from '@utima/ui';
import { PanelLeft } from 'lucide-react';
import { Fragment, memo, type ReactNode } from 'react';
import type { LiteralUnion } from 'type-fest';

export type BreadCrumbItem =
  | { type: 'more' }
  | {
      type?: 'link';
      label?: string;
      to?: LiteralUnion<keyof Register['router']['routesByPath'], string>;
      params?: Record<string, string>;
    };

export type BreadCrumbProps = {
  items: BreadCrumbItem[];
  title?: ReactNode;
  children?: ReactNode;
};

export const BreadCrumb = memo(function BreadCrumb({
  items,
  title,
  children,
}: BreadCrumbProps) {
  return (
    <div className='mb-4 flex w-full flex-col items-start justify-between gap-6 md:flex-row'>
      <div className='flex flex-col gap-2'>
        <UIBreadcrumb.List>
          <UIBreadcrumb.Item className='flex sidebar:hidden'>
            <Layout.SidebarTrigger>
              <PanelLeft className='size-6 text-foreground' />
            </Layout.SidebarTrigger>
          </UIBreadcrumb.Item>
          <UIBreadcrumb.Separator className='flex sidebar:hidden'>
            <Separator orientation='vertical' className='mx-1 h-6' />
          </UIBreadcrumb.Separator>
          {items.map((item, index) => (
            <Fragment key={index}>
              {index > 0 && <UIBreadcrumb.Separator />}
              {item.type === 'more' ? (
                <UIBreadcrumb.Item>...</UIBreadcrumb.Item>
              ) : (
                <UIBreadcrumb.Item>
                  <UIBreadcrumb.Link asChild>
                    {/*@ts-expect-error - I don't have time to fix broken types right now*/}
                    <Link to={item.to} params={item.params}>
                      {item.label}
                    </Link>
                  </UIBreadcrumb.Link>
                </UIBreadcrumb.Item>
              )}
            </Fragment>
          ))}
        </UIBreadcrumb.List>

        <div className='flex flex-col flex-wrap gap-2'>
          {title && <h1 className='text-3xl font-bold'>{title}</h1>}
        </div>
      </div>
      <div className='flex shrink-0 grow items-center justify-end gap-2'>
        {children}
      </div>
    </div>
  );
});
