import { Trans } from '@lingui/react/macro';
import { memo } from 'react';

export type ActivityIndicatorProps = {};

export const ActivityIndicator = memo(
  function ActivityIndicator({}: ActivityIndicatorProps) {
    return (
      <div className='flex items-center justify-center gap-2'>
        <span className='sr-only'>
          <Trans>Loading...</Trans>
        </span>
        <div className='size-2.5 animate-bounce rounded-full bg-foreground [animation-delay:-0.3s]' />
        <div className='size-2.5 animate-bounce rounded-full bg-foreground [animation-delay:-0.15s]' />
        <div className='size-2.5 animate-bounce rounded-full bg-foreground' />
      </div>
    );
  },
);
