import type { ThreadType } from '@kanbu/schema';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatSidebar } from '@/components/chat/ChatSidebar';
import type { DataTableRowHandler } from '@/components/dataTable/DataTable';
import { TablePage } from '@/components/page/TablePage';
import { SidebarPane } from '@/components/sidebarPane/SidebarPane';
import { useThreadsCols } from '@/features/threads/useThreadsCols';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute(
  '/_baseLayout/chat-users/$chatUserId/threads/',
)({
  component: ChatUserThreadsPage,
});

export function ChatUserThreadsPage() {
  const { chatUserId } = Route.useParams();
  const { t } = useTranslation(['globals', 'chatUsers', 'threads']);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [thread, setThread] = useState<ThreadType | null>(null);
  const columns = useThreadsCols();

  const handleRow: DataTableRowHandler<ThreadType> = useCallback(
    (event, row) => {
      setIsDrawerOpen(true);
      setThread(row.original);
    },
    [],
  );

  const handleQuery = useTableQuery(trpcClient.threads.findAll.query, {
    populate: [
      'chatMessageCount',
      'messages.feedback',
      'feedbackCount',
      'messages',
      'messages.user',
      'messages.feedback',
      'chat.chatbotConfig',
      'chat.themeConfig',
    ],
    where: {
      chatUser: {
        id: chatUserId,
      },
    },
  });

  return (
    <SidebarPane isOpen={isDrawerOpen}>
      <SidebarPane.Container>
        <div className='flex grow flex-col'>
          <TablePage
            title={t('threads:texts.manage')}
            breadcrumbs={[
              { label: t('globals:routeNames.chatUsers'), to: '/chat-users' },
              {
                label: chatUserId,
                to: '/chat-users/$chatUserId/threads',
              },
            ]}
            columns={columns}
            queryKey={[...getQueryKey(trpc.threads.findAll), chatUserId]}
            onQueryFn={handleQuery}
            onRow={handleRow}
          />
        </div>
      </SidebarPane.Container>
      <SidebarPane.Drawer>
        <ChatSidebar thread={thread} setDrawerOpen={setIsDrawerOpen} />
      </SidebarPane.Drawer>
    </SidebarPane>
  );
}
