import { ContentType } from '@kanbu/schema/enums';
import { Trans } from '@lingui/react/macro';
import { Button, cn, Dialog } from '@utima/ui';
import { File, FileSymlink, Globe } from 'lucide-react';
import { memo, useEffect, useState, useRef } from 'react';

import { useEnumTranslations } from '@/locale/enumTranslations';
import { useBoundStore } from '@/store/store';

import { AddExternalDocument } from './AddExternalDocument';
import { AddWebsites } from './AddWebsites';
import { UploadDocuments } from './UploadDocuments';

export type AddDocumentsModalProps = {
  chatId: string;
  defaultOpen?: boolean;
  defaultUrl?: string;
};

export const AddDocumentsModal = memo(function AddDocumentsModal({
  chatId,
  defaultOpen = false,
  defaultUrl,
}: AddDocumentsModalProps) {
  const enumTranslations = useEnumTranslations();
  const [open, setOpen] = useState(defaultOpen);
  const { type, setType } = useBoundStore(state => ({
    type: state.type,
    setType: state.setType,
  }));

  const websiteButtonRef = useRef<HTMLButtonElement>(null);
  const externalDocButtonRef = useRef<HTMLButtonElement>(null);
  const documentButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (defaultUrl) {
      setType(ContentType.Website);
    }
  }, [defaultUrl, setType, type]);

  const handleOpenAutoFocus = (event: Event) => {
    event.preventDefault();
    switch (type) {
      case 'website':
        websiteButtonRef.current?.focus();
        break;
      case 'externalDocument':
        externalDocButtonRef.current?.focus();
        break;
      case 'document':
        documentButtonRef.current?.focus();
        break;
    }
  };

  return (
    <Dialog.Root defaultOpen={defaultOpen} open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <Button>
          <Trans>Add resources</Trans>
        </Button>
      </Dialog.Trigger>
      <Dialog.Content
        className='flex max-h-full min-h-[400px] w-[1000px] max-w-full flex-col'
        onOpenAutoFocus={handleOpenAutoFocus}
      >
        <Dialog.Header>
          <Dialog.Title>
            <Trans>Add resources</Trans>
          </Dialog.Title>
          <Dialog.Description>
            <Trans>
              Below you can upload your own documents, or add websites as
              sources.
            </Trans>
          </Dialog.Description>
        </Dialog.Header>

        <div className='flex flex-row flex-wrap gap-2 md:gap-4'>
          <Button
            ref={websiteButtonRef}
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'website' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType(ContentType.Website)}
          >
            <Globe className='size-5' />
            <span className='hidden sm:inline'>
              {enumTranslations.contentType[ContentType.Website]}
            </span>
          </Button>
          <Button
            ref={externalDocButtonRef}
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'externalDocument' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType(ContentType.ExternalDocument)}
          >
            <FileSymlink className='size-5' />
            <span className='hidden sm:inline'>
              {enumTranslations.contentType[ContentType.ExternalDocument]}
            </span>
          </Button>
          <Button
            ref={documentButtonRef}
            variant='ghost'
            outline
            className={cn(
              'py-4 flex grow flex-row items-center gap-2',
              type === 'document' && 'bg-gray-100 text-primary',
            )}
            onClick={() => setType(ContentType.Document)}
          >
            <File className='size-5' />
            <span className='hidden sm:inline'>
              {enumTranslations.contentType[ContentType.Document]}
            </span>
          </Button>
        </div>

        {type === 'website' && (
          <AddWebsites
            chatId={chatId}
            setOpen={setOpen}
            defaultUrl={defaultUrl}
          />
        )}
        {type === 'externalDocument' && (
          <AddExternalDocument chatId={chatId} setOpen={setOpen} />
        )}
        {type === 'document' && <UploadDocuments chatId={chatId} />}
      </Dialog.Content>
    </Dialog.Root>
  );
});
