import type { ShopProductPreview } from '@kanbu/schema/contracts';
import { Trans } from '@lingui/react/macro';
import { AnimatePresence, motion } from 'framer-motion';

import { Card, Carousel } from '../../ui';

export interface ShopProductCarousel {
  items: ShopProductPreview[];
}

/**
 * Carousel component, can be used to display a list of products
 * in the chat UI.
 */
export function ShopProductCarousel({ items }: ShopProductCarousel) {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <Carousel.Root className='[&>*]:overflow-visible mt-2'>
      <Carousel.Content>
        <AnimatePresence>
          {items?.map((item, index) => (
            <Carousel.Item className='basis-[90%]' key={item.id}>
              <motion.div
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 0.1 + index * 0.05,
                  ease: 'easeOut',
                }}
              >
                <Card.Root
                  className='shadow-none w-full overflow-hidden'
                  key={item.id}
                >
                  <div className='flex h-full'>
                    {item.imageUrl ? (
                      <div className='relative h-auto w-28 flex-shrink-0 bg-gray-100 border-r'>
                        <img
                          src={item.imageUrl}
                          className='object-cover size-full'
                        />
                      </div>
                    ) : null}
                    <Card.Content className='flex-1 p-2 select-none'>
                      <h3 className='text-base font-semibold mb-0.5'>
                        {item.title || item.name}
                      </h3>
                      <p className='line-clamp-2 text-sm text-muted-foreground mb-2'>
                        {item.description}
                      </p>
                      <div className='flex items-center gap-2 justify-between'>
                        <span className='whitespace-nowrap text-sm font-bold text-secondary'>
                          {item.price} Kč
                        </span>
                        <a
                          href={item.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='inline-flex items-center gap-1 text-xs bg-primary text-primary-fg px-1 py-0.5 font-medium rounded-md hover:underline'
                        >
                          <Trans>Details</Trans>
                        </a>
                      </div>
                    </Card.Content>
                  </div>
                </Card.Root>
              </motion.div>
            </Carousel.Item>
          ))}
        </AnimatePresence>
      </Carousel.Content>
    </Carousel.Root>
  );
}
