import {
  ragResultToolOperator,
  type RagResultToolOperator,
} from '@kanbu/schema/contracts';
import { ThreadMode } from '@kanbu/schema/enums';
import { Trans } from '@lingui/react/macro';
import { Button } from '@utima/ui';
import { useCallback, useState } from 'react';
import type { PartialDeep } from 'type-fest';

import type { ToolComponentProps } from './ToolProvider';
import { useWorkingHours } from '../../hooks/useWorkingHours';
import { createToolComponent } from '../../toolRegistry';
import { Markdown } from '../Markdown';
import { OperatorInfo } from '../operator/OperatorInfo';
import { Message } from '../ui/Message';

export interface OperatorResultToolProps
  extends ToolComponentProps<PartialDeep<RagResultToolOperator>> {
  onTakeover?: (accepted: boolean) => Promise<void>;
  mode?: ThreadMode;
}

export const OperatorResultTool = createToolComponent<
  OperatorResultToolProps,
  typeof ragResultToolOperator
>(
  {
    schema: {
      args: ragResultToolOperator,
    },
  },
  ({ toolCall, onTakeover, mode }) => {
    const { operator, content } = toolCall.args;

    const [isLoading, setIsLoading] = useState(false);

    const { isOpen } = useWorkingHours();

    const handleTakeover = useCallback(async () => {
      try {
        if (!onTakeover) {
          return;
        }

        setIsLoading(true);
        await onTakeover(mode === ThreadMode.AI);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }, [mode, onTakeover]);

    return (
      <>
        <Message.BubbleGroup>
          <Message.Bubble>
            <Markdown>{content ?? ''}</Markdown>
          </Message.Bubble>
          <OperatorInfo operator={operator} />
        </Message.BubbleGroup>
        {onTakeover && isOpen && (
          <div className='flex justify-start gap-1'>
            <Button
              loading={isLoading}
              disabled={isLoading}
              variant={mode === ThreadMode.Human ? 'ghost' : 'primary'}
              outline={mode === ThreadMode.Human}
              onClick={handleTakeover}
              className='!rounded-full'
            >
              {mode === ThreadMode.Human ? (
                <Trans>Go back to AI</Trans>
              ) : (
                <Trans>Yes, connect me</Trans>
              )}
            </Button>
          </div>
        )}
      </>
    );
  },
);
