import type { UserType } from '@kanbu/schema';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { UserXIcon } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useUsersCols } from '@/features/users/useUsersCols';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export const Route = createFileRoute('/_baseLayout/users/')({
  component: UsersPage,
});

export function UsersPage() {
  const { t } = useTranslation(['globals', 'users']);
  const [limitReached, setLimitReached] = useState(false);
  const columns = useUsersCols();

  const user = useBoundStore(state => state.user);

  const handleQuery = useTableQuery(trpcClient.users.findAll.query, {
    onSuccess: data => {
      setLimitReached(
        data.length >= (user?.organization.tierConfig.usersLimit || 3),
      );
    },
  });

  const handleRow = useDetailRowHandler<UserType>(row => ({
    to: '/users/$userId/edit',
    params: {
      userId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t('users:texts.manage')}
      breadcrumbs={[{ label: t('globals:routeNames.users'), to: '/users' }]}
      createButton={{
        to: '/users/create',
        label: t('users:actions.create'),
      }}
      actions={[
        limitReached ? (
          <p className='flex items-center gap-2 font-medium text-red-500'>
            <UserXIcon /> {t('users:texts.userLimitReached')}
          </p>
        ) : null,
      ]}
      columns={columns}
      queryKey={getQueryKey(trpc.users.findAll)}
      onQueryFn={handleQuery}
      onRow={handleRow}
    />
  );
}
