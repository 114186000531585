import { Trans } from '@lingui/react/macro';
import { Mail, Phone, User } from 'lucide-react';

import { Message } from '../ui/Message';

export type OperatorInfoProps = {
  operator?: {
    name?: string;
    email?: string;
    phone?: string;
  };
};

export function OperatorInfo({ operator }: OperatorInfoProps) {
  if (!operator?.name && !operator?.email && !operator?.phone) {
    return null;
  }

  return (
    <Message.Bubble>
      <h4 className='text-sm font-bold mb-2'>
        <Trans>Operator Contact Information</Trans>
      </h4>
      <ul className='flex flex-col gap-1'>
        {operator?.name && (
          <li className='grid grid-cols-[1fr_auto] items-center gap-1'>
            <div className='inline-flex items-center gap-2 font-semibold'>
              <User className='relative top-0.5 size-4 shrink-0' />
              <span className='break-all'>{operator.name}</span>
            </div>
          </li>
        )}
        {operator.email && (
          <li className='grid grid-cols-[1fr_auto] items-center gap-1'>
            <div className='inline-flex items-center gap-2'>
              <Mail className='relative top-0.5 size-4 shrink-0' />
              <a
                href={`mailto:${operator.email}`}
                className='break-all underline hover:no-underline'
              >
                {operator.email}
              </a>
            </div>
          </li>
        )}
        {operator.phone && (
          <li className='grid grid-cols-[1fr_auto] items-center gap-1'>
            <div className='inline-flex items-center gap-2'>
              <Phone className='relative top-0.5 size-4 shrink-0' />
              <a
                href={`tel:${operator.phone}`}
                className='break-all underline hover:no-underline'
              >
                {operator.phone}
              </a>
            </div>
          </li>
        )}
      </ul>
    </Message.Bubble>
  );
}
