import type { FeedType } from '@kanbu/schema';
import { FeedStatus } from '@kanbu/schema/enums';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { useLingui } from '@lingui/react/macro';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Tooltip, Button } from '@utima/ui';
import { DatabaseBackup, Rss } from 'lucide-react';
import { useMemo } from 'react';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';
import { IconWrapper } from '@/components/iconWrapper/IconWrapper.js';
import { useEnumTranslations } from '@/locale/enumTranslations.js';

import { useFeedMutation } from './useFeedMutation.js';

const columnHelper = createColumnHelper<FeedType>();

export function useFeedsCols() {
  const { t } = useLingui();
  const {
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useFeedMutation();
  const enumTranslations = useEnumTranslations();

  return useMemo(
    () => [
      columnHelper.accessor(row => row.name, {
        id: 'name',
        header: t`Name`,
        cell: ({ row }) => {
          const data = row.original;

          return (
            <div className='flex min-w-64 items-center gap-3'>
              <IconWrapper size='sm' className='text-danger' Icon={Rss} />
              <div className='flex flex-col gap-0.5'>
                <p className='break-all font-medium leading-tight'>
                  {data.name}
                </p>
                {data.url && (
                  <p className='hidden text-xs text-gray-600 md:block'>
                    {data.url}
                  </p>
                )}
                {data.status === FeedStatus.Failed && (
                  <span className='text-xs text-danger'>
                    {data.importMetadata?.lastError}
                  </span>
                )}
              </div>
            </div>
          );
        },
        meta: {
          sortable: 'name',
        },
      }),
      columnHelper.accessor(row => row.source, {
        id: 'source',
        header: t`Source`,
        cell: ({ row }) => (
          <Badge variant='primary' outline size='sm'>
            {enumTranslations.feedSource[row.original.source]}
          </Badge>
        ),
        meta: {
          sortable: 'source',
        },
      }),
      columnHelper.accessor(row => row.status, {
        id: 'status',
        header: t`Status`,
        cell: ({ row }) => (
          <Badge
            size='sm'
            variant={
              row.original.status === FeedStatus.Pending ||
              row.original.status === FeedStatus.Processing
                ? 'info'
                : row.original.status === FeedStatus.Done
                  ? 'success'
                  : 'danger'
            }
            outline
          >
            {enumTranslations.feedStatus[row.original.status]}
          </Badge>
        ),
        meta: {
          sortable: 'status',
        },
      }),
      columnHelper.accessor(row => row.finishedImportAt, {
        id: 'finishedImportAt',
        header: t`Last imported at`,
        cell: row =>
          row.getValue()
            ? formatUtils.date(row.getValue()!, DateFormat.DateTime)
            : '-',
        meta: {
          sortable: 'finishedImportAt',
        },
      }),
      columnHelper.accessor(row => row.updatedAt, {
        id: 'updatedAt',
        header: t`Updated at`,
        cell: row => formatUtils.date(row.getValue(), DateFormat.DateTime),
        meta: {
          sortable: 'updatedAt',
        },
      }),
      columnHelper.accessor(row => row.productsCount, {
        id: 'productsCount',
        header: t`Products`,
        cell: row => row.getValue() ?? '0',
      }),
      columnHelper.display({
        id: 'actions',
        header: t`Actions`,
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })}>
            <Tooltip title={t`Refresh data`}>
              <Button
                variant='ghost'
                className='text-info'
                outline
                size='icon-xs'
                onClick={async () => {
                  await update({
                    id: row.original.id,
                    status: FeedStatus.Pending,
                    importMetadata: null,
                  });
                }}
              >
                <DatabaseBackup className='size-4' />
              </Button>
            </Tooltip>
          </ActionsCell>
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [
      t,
      enumTranslations.feedSource,
      enumTranslations.feedStatus,
      remove,
      update,
    ],
  );
}
