import { Checkbox, Label } from '@utima/ui';
import { memo, type CSSProperties } from 'react';

import type { WebsiteRecordValue } from '@/store/slices/documentModalSlice';

import { WeightSelector } from './WeightSelector';

export type WebsiteRecordProps = {
  id: string;
  description: string;
  onRecordChange: (id: string, data: Partial<WebsiteRecordValue>) => void;
  weight: number;
  style: CSSProperties;
  checked: boolean;
};

export const WebsiteRecord = memo(
  ({
    id,
    description,
    onRecordChange,
    weight,
    style,
    checked,
  }: WebsiteRecordProps) => {
    return (
      <div
        className='mb-2 flex h-7 w-full items-center justify-between px-1'
        style={style}
      >
        <Label
          htmlFor={`url.${id}`}
          className='flex items-center gap-2 font-normal'
        >
          <Checkbox
            size='sm'
            id={`url.${id}`}
            name={`url.${id}`}
            checked={checked}
            onCheckedChange={(checked: boolean) => {
              onRecordChange(id, {
                selected: checked,
              });
            }}
          />
          {description}
        </Label>

        <div className='flex'>
          <WeightSelector
            value={weight}
            onChange={value => {
              onRecordChange(id, {
                weight: value,
              });
            }}
          />
        </div>
      </div>
    );
  },
);
