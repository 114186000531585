import type {
  OrganizationType,
  OrganizationWorkingHoursDay,
} from '@kanbu/schema';
import { DateFormat, formatUtils, isTimeRangeValid } from '@kanbu/shared';
import { useLingui } from '@lingui/react/macro';
import { Button, Input, Switch } from '@utima/ui';
import { PlusCircle, MinusCircle } from 'lucide-react';
import { memo, useCallback, type ChangeEvent, type MouseEvent } from 'react';

type Props = {
  label: string;
  day: keyof NonNullable<OrganizationType['workingHours']>;
  workingHoursDay?: OrganizationWorkingHoursDay;
  onChange: (value: Partial<OrganizationType['workingHours']>) => void;
};

export const WorkingHoursDay = memo(function WorkingHoursDay({
  label,
  day,
  workingHoursDay,
  onChange,
}: Props) {
  const { t } = useLingui();

  const isOpen = !!workingHoursDay;

  const onToggleChange = useCallback(
    (isOpen: boolean) => {
      const today = new Date();
      today.setMinutes(0);

      onChange({
        [day]: isOpen
          ? [
              {
                from: new Date(today.setHours(9)).toISOString(),
                to: new Date(today.setHours(17)).toISOString(),
              },
            ]
          : false,
      });
    },
    [day, onChange],
  );

  const onAddHours = useCallback(() => {
    if (!isOpen || !Array.isArray(workingHoursDay)) {
      return;
    }

    const today = new Date();
    today.setMinutes(0);

    onChange({
      [day]: [
        ...workingHoursDay,
        {
          from: new Date(today.setHours(9)).toISOString(),
          to: new Date(today.setHours(17)).toISOString(),
        },
      ],
    });
  }, [day, isOpen, onChange, workingHoursDay]);

  const onRemoveHours = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!isOpen || !Array.isArray(workingHoursDay)) {
        return;
      }

      const index = Number.parseInt(
        event.currentTarget.dataset.index as string,
      );

      const newWorkingHoursDay = [...workingHoursDay];
      newWorkingHoursDay.splice(index, 1);

      onChange({
        [day]: newWorkingHoursDay.length > 0 ? newWorkingHoursDay : false,
      });
    },
    [day, isOpen, onChange, workingHoursDay],
  );

  const onChangeHours = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!isOpen || !Array.isArray(workingHoursDay)) {
        return;
      }

      const index = Number.parseInt(
        event.currentTarget.dataset.index as string,
      );
      const [hours, minutes] = event.currentTarget.value.split(':');
      const today = new Date();

      today.setHours(Number.parseInt(hours));
      today.setMinutes(Number.parseInt(minutes));

      const newWorkingHoursDay = [...workingHoursDay];
      newWorkingHoursDay[index] = {
        ...newWorkingHoursDay[index],
        [event.currentTarget.name]: today.toISOString(),
      };

      onChange({
        [day]: newWorkingHoursDay,
      });
    },
    [day, isOpen, onChange, workingHoursDay],
  );

  return (
    <>
      <div className='flex h-7 min-w-0 items-center'>
        <span className='truncate'>{label}</span>
      </div>
      <div className='flex h-7 items-center'>
        <Switch checked={isOpen} onCheckedChange={onToggleChange} size='sm' />
      </div>
      <div className='flex min-h-7 grow flex-col items-center gap-2'>
        <div className='flex h-7 w-full items-center justify-between'>
          <span>{isOpen ? t`Open` : t`Closed`}</span>
          {isOpen && (
            <Button
              size='icon-xs'
              variant='primary'
              outline
              onClick={onAddHours}
            >
              <PlusCircle className='size-4' />
            </Button>
          )}
        </div>
        {isOpen &&
          Array.isArray(workingHoursDay) &&
          workingHoursDay.map((times, index) => (
            <div
              className='flex w-full items-center justify-between gap-2'
              key={`${times.from}-${times.to}`} // This is fine. Times are stored as whole dates, so there is no risk of collision.
            >
              <div className='flex grow flex-wrap gap-2'>
                <div className='min-w-20 grow'>
                  <Input
                    name='from'
                    type='time'
                    value={formatUtils.date(times.from, DateFormat.InputTime)}
                    size='sm'
                    data-index={index}
                    onChange={onChangeHours}
                    variant={
                      isTimeRangeValid(times.from, times.to)
                        ? 'default'
                        : 'danger'
                    }
                  />
                </div>
                <div className='min-w-20 grow'>
                  <Input
                    name='to'
                    type='time'
                    value={formatUtils.date(times.to, DateFormat.InputTime)}
                    size='sm'
                    data-index={index}
                    onChange={onChangeHours}
                    variant={
                      isTimeRangeValid(times.from, times.to)
                        ? 'default'
                        : 'danger'
                    }
                  />
                </div>
              </div>
              <Button
                className='shrink-0'
                size='icon-xs'
                variant='danger'
                outline
                data-index={index}
                onClick={onRemoveHours}
              >
                <MinusCircle className='size-4' />
              </Button>
            </div>
          ))}
      </div>
    </>
  );
});
