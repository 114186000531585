import { AgentType } from '@kanbu/schema/enums';
import { useLingui } from '@lingui/react/macro';
import { useFieldState } from 'informed';
import { memo } from 'react';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

export const PersonalizationFields = memo(() => {
  const { t } = useLingui();
  const typeFieldState = useFieldState('agentType');

  const { schema } = useFieldsSchema(
    [
      {
        name: 'chatbotConfig.initialMessage',
        label: t`Initial message`,
        type: 'text',
        uiControl: 'textarea',
        defaultValue: t`Hello, I'm AI Honza! How can I help you today?`,
        showOptional: false,
      },
      {
        name: 'chatbotConfig.showPopup',
        label: t`Show bubble popup`,
        type: 'checkbox',
        uiControl: 'switch',
        defaultValue: true,
        showOptional: false,
      },
      {
        $relevant: {
          $when: ({ formState }) =>
            (formState?.values as any)?.chatbotConfig?.showPopup,
          $fields: [
            {
              name: 'chatbotConfig.bubbleTitle',
              label: t`Bubble title`,
              type: 'text',
              defaultValue: t`Hello, I'm AI Honza!`,
              showOptional: false,
            },
            {
              name: 'chatbotConfig.bubbleDescription',
              label: t`Bubble description`,
              type: 'text',
              defaultValue: t`Ask me anything!`,
              showOptional: false,
            },
            {
              name: 'chatbotConfig.showPopupMobile',
              label: t`Show bubble popup on mobile`,
              type: 'checkbox',
              uiControl: 'switch',
              defaultValue: false,
              showOptional: false,
            },
          ],
        },
      },
    ],
    [t],
  );

  // These fields should be visible only if the agent type is Chat
  if (typeFieldState.value !== AgentType.Chat) {
    return null;
  }

  return (
    <Card title={t`Personalization`}>
      <SchemaFields schema={schema} />
    </Card>
  );
});
