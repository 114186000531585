import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

export interface ChatRendererConfig {
  insert?: (message: string) => void;
  append?: (message: string) => void;
}

export const ChatRendererContext = createContext<ChatRendererConfig | null>(
  null,
);

interface ChatRendererProps extends PropsWithChildren {
  insert?: (message: string) => void;
  append?: (message: string) => void;
}

/**
 * Provider for chat renderer, provides options for chat renderer.
 */
export function ChatRendererProvider({
  insert,
  append,
  children,
}: ChatRendererProps) {
  const contextValue = useMemo(() => ({ insert, append }), [insert, append]);

  return (
    <ChatRendererContext.Provider value={contextValue}>
      {children}
    </ChatRendererContext.Provider>
  );
}

/**
 * Hook for accessing chat renderer context.
 */
export function useChatRenderer() {
  const context = useContext(ChatRendererContext);

  if (!context) {
    throw new Error('Missing ChatConfigContext.Provider in the tree');
  }

  return context;
}
