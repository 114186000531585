import { usageLimitsErrorToolArgsSchema } from '@kanbu/schema/contracts';
import { Trans } from '@lingui/react/macro';

import { createToolComponent } from '../../toolRegistry';
import { OperatorInfo } from '../operator/OperatorInfo';
import { Message } from '../ui/Message';

export const UsageLimitsErrorTool = createToolComponent(
  {
    schema: {
      args: usageLimitsErrorToolArgsSchema,
    },
  },
  ({ toolCall }) => {
    const { operator } = toolCall.args || {};
    const hasOperator = operator?.name && (operator?.email || operator?.phone);

    return (
      <Message.BubbleGroup>
        <Message.Bubble>
          <Trans>
            I am sorry but I am currently unavailable. Please try again later
            {hasOperator ? <Trans> or contact physical operator:</Trans> : '.'}
          </Trans>
        </Message.Bubble>
        {hasOperator && <OperatorInfo operator={operator} />}
      </Message.BubbleGroup>
    );
  },
);
