import type { ChatMessageType } from '@kanbu/schema';
import { FeedbackCategory, Rating } from '@kanbu/schema/enums';
import { IconThumbDownFilled, IconThumbUpFilled } from '@tabler/icons-react';
import { memo } from 'react';

import { useEnumTranslations } from '@/locale/enumTranslations';

interface AdminFeedbackProps {
  message: ChatMessageType;
}

export const AdminFeedback = memo(function Feedback({
  message,
}: AdminFeedbackProps) {
  const feedback = message.feedback;
  const { feedbackCategory } = useEnumTranslations();

  if (!feedback) {
    return null;
  }

  const getFeedbackCategoryText = (category?: FeedbackCategory): string => {
    if (!category) return '';

    const categories = {
      [FeedbackCategory.InsufficientDetail]:
        feedbackCategory.insufficientDetail,
      [FeedbackCategory.IrrelevantInformation]:
        feedbackCategory.irrelevantInformation,
      [FeedbackCategory.MissingInformation]:
        feedbackCategory.missingInformation,
      [FeedbackCategory.IncorrectInformation]:
        feedbackCategory.incorrectInformation,
      [FeedbackCategory.Other]: feedbackCategory.other,
    };

    return categories[category] || '';
  };

  return (
    <div className='flex flex-row items-start gap-1'>
      <div className='mb-1 shrink-0 rounded-full bg-zinc-900 p-2 text-white'>
        {feedback.rating === Rating.Negative && (
          <IconThumbDownFilled className='size-4' />
        )}
        {feedback.rating === Rating.Positive && (
          <IconThumbUpFilled className='size-4' />
        )}
      </div>

      {(feedback.category || feedback.note) && (
        <div className='w-max max-w-[85%] rounded-lg bg-zinc-900 px-3 py-2'>
          {feedback.category && (
            <div className='text-xs font-medium text-zinc-200'>
              {getFeedbackCategoryText(feedback.category)}
            </div>
          )}
          {feedback.note && (
            <div className='whitespace-pre-wrap text-xs text-zinc-400'>
              {feedback.note}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
