import { useInfiniteQuery } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { useMemo } from 'react';

import { trpc, trpcClient } from '@/services/trpc';

export interface UseInboxThreadsParams {
  initialPage?: number;
  pageSize?: number;
}

/**
 * Handles threads fetching with infinite pagination.
 */
export function useInboxThreads({
  initialPage = 1,
  pageSize = 50,
}: UseInboxThreadsParams = {}) {
  /**
   * Infinite query to fetch threads and paginate through them.
   */
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: getQueryKey(trpc.threads.findAll),
      queryFn: ({ pageParam = 1 }) =>
        trpcClient.threads.findAll.query({
          orderBy: {
            lastUserMessageAt: 'desc',
          },
          pagination: { page: pageParam, pageSize },
          populate: ['chatMessageCount'],
        }),
      getNextPageParam: lastPage =>
        lastPage.meta.page + 1 > lastPage.meta.pageCount
          ? undefined
          : lastPage.meta.page + 1,
      initialPageParam: initialPage,
    });

  /**
   * Memoize threads and merge them into a single array and filter
   * out empty threads.
   */
  const threads = useMemo(
    () => data?.pages.flatMap(page => page.items) ?? [],
    [data],
  );

  return {
    threads,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
}
