import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { ConnectionStatus } from './components/ConnectionStatus';
import { InboxConversation } from './components/InboxConversation';
import { InboxSidebar } from './components/InboxSidebar';
import { ThreadMetadata } from './components/ThreadMetadata';
import { useInboxSocket } from './hooks/useInboxSocket';
import { useInboxThread } from './hooks/useInboxThread';
import { useInboxThreads } from './hooks/useInboxThreads';
import { useInboxUnread } from './hooks/useInboxUnread';

export const Inbox = memo(() => {
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const [showMetadata, setShowMetadata] = useState(true);
  const { markAsRead, isUnread } = useInboxUnread();

  /**
   * Fetch threads and selected thread
   */
  const { threads, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInboxThreads();

  const previousThreads = useRef(threads);

  const {
    thread,
    handleSubmit,
    hasConnectionFailed: hasThreadConnectionFailed,
    isLoading: isThreadLoading,
  } = useInboxThread(selectedThreadId);

  const { refetchUnread } = useInboxUnread();

  /**
   * Initiate connection with admin websocket
   */
  const { hasConnectionFailed: hasAdminConnectionFailed } = useInboxSocket();

  /**
   * Select first thread as default when no thread is selected
   */
  useEffect(() => {
    if (threads.length && !selectedThreadId) {
      setSelectedThreadId(threads[0].id);
    }
  }, [threads, selectedThreadId]);

  /**
   * Set the selected thread and mark it as read.
   */
  const handleThreadSelect = useCallback((threadId: string) => {
    setSelectedThreadId(threadId);
  }, []);

  useEffect(() => {
    if (selectedThreadId && isUnread(selectedThreadId)) {
      markAsRead(selectedThreadId);
    }
  }, [selectedThreadId, markAsRead, isUnread]);

  useEffect(() => {
    if (previousThreads.current !== threads) {
      refetchUnread();
      previousThreads.current = threads;
    }
  }, [previousThreads, refetchUnread, threads]);

  return (
    <div className='relative flex h-screen overflow-hidden bg-white'>
      {(hasThreadConnectionFailed || hasAdminConnectionFailed) && (
        <ConnectionStatus className='absolute inset-x-4 top-4 z-10 shadow' />
      )}
      <div className='w-[100px] border-r border-gray-200 md:w-[180px] lg:w-[300px] xl:w-[360px]'>
        <InboxSidebar
          threads={threads}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          onFetchNextPage={fetchNextPage}
          activeThreadId={selectedThreadId}
          onSelectThread={handleThreadSelect}
          hasNextPage={hasNextPage}
          isUnread={isUnread}
        />
      </div>
      <div className='flex-1'>
        <InboxConversation
          onSubmit={handleSubmit}
          thread={thread}
          isLoading={isThreadLoading}
          onToggleMetadata={() => setShowMetadata(v => !v)}
        />
      </div>
      {showMetadata && thread && (
        <ThreadMetadata
          thread={thread}
          onClose={() => setShowMetadata(false)}
        />
      )}
    </div>
  );
});
