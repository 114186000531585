import type { ChatMessageType } from '@kanbu/schema';
import { Trans } from '@lingui/react/macro';
import { memo } from 'react';

import { AdminChatRenderer } from '../../chat/AdminChatRenderer';

export interface MessageListProps {
  agentName: string | undefined;
  agentAvatar: string | undefined;
  messages: ChatMessageType[] | undefined;
}

export const InboxChat = memo(
  ({ agentName, agentAvatar, messages }: MessageListProps) => {
    if (!Array.isArray(messages) || messages.length === 0) {
      return (
        <div className='flex h-full flex-col items-center justify-start pt-12'>
          <p className='text-sm text-muted-fg'>
            <Trans>No messages</Trans>
          </p>
        </div>
      );
    }

    return (
      <div className='flex flex-col gap-4 p-4'>
        <div className='flex grow flex-col gap-6 [&>*:first-child]:mt-auto'>
          <AdminChatRenderer
            messages={messages}
            agentName={agentName}
            agentAvatar={agentAvatar}
          />
        </div>
      </div>
    );
  },
);
