import { DocumentStatus, ContentType } from '@kanbu/schema/enums';
import { Trans, useLingui } from '@lingui/react/macro';
import { Button, Dialog } from '@utima/ui';
import { Input, type TypedFormState } from '@utima/ui-informed';
import type { FormApi } from 'informed';
import { memo, useCallback, useRef } from 'react';
import { z } from 'zod';

import { BasicForm } from '@/components/form/BasicForm';
import { useBoundStore } from '@/store/store';

import { useDocumentMutation } from '../../hooks/useDocumentMutation';

export type ExternalDocumentFormProps = {
  chatId: string;
  setOpen: (open: boolean) => void;
};

export const AddExternalDocument = memo(function ExternalDocumentForm({
  chatId,
  setOpen,
}: ExternalDocumentFormProps) {
  const { t } = useLingui();
  const { create } = useDocumentMutation();
  const formApi = useRef<FormApi>(null);
  const { externalDocumentUri, setExternalDocumentUri } = useBoundStore(
    state => ({
      externalDocumentUri: state.externalDocumentUri,
      setExternalDocumentUri: state.setExternalDocumentUri,
    }),
  );

  const handleAdd = useCallback(() => {
    formApi.current?.submitForm();
    setExternalDocumentUri(undefined);
  }, []);

  const handleSubmit = async ({ values }: TypedFormState<{ uri: string }>) => {
    await create.mutateAsync({
      chat: chatId,
      status: DocumentStatus.Ready,
      type: ContentType.ExternalDocument,
      name: values.uri,
      uri: values.uri,
      publicUrl: values.uri,
    });

    setOpen(false);
  };

  const onChange = useCallback(() => {
    const currentUri = formApi.current?.getValue('uri') as string;
    setExternalDocumentUri(currentUri);
  }, [setExternalDocumentUri]);

  return (
    <>
      <BasicForm
        formApiRef={formApi}
        onSubmit={handleSubmit}
        className='h-full max-h-[500px] min-h-0 grow space-y-4'
      >
        <Input
          name='uri'
          label={t`URL`}
          zodItemSchema={z.string().url()}
          placeholder='https://example.com/document.pdf'
          required
          onChange={onChange}
          defaultValue={externalDocumentUri}
        />
      </BasicForm>

      <Dialog.Footer className='z-50 flex flex-row justify-end gap-2 md:flex-row'>
        <Button
          disabled={create.isPending}
          loading={create.isPending}
          onClick={handleAdd}
        >
          <Trans>Add</Trans>
        </Button>
      </Dialog.Footer>
    </>
  );
});
