import { Button } from '@utima/ui';
import {
  CheckCircle2,
  FileText,
  Image as ImageIcon,
  Loader2,
  Trash,
  X,
} from 'lucide-react';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { UploadingFile } from '../fileUploadTypes';

export interface FileItemProps {
  file: UploadingFile;
  onDelete?: () => void;
  onClose?: () => void;
  autoCloseMs?: number;
}

export const FileItem = memo(function FileItem({
  file,
  onDelete,
  onClose,
  autoCloseMs,
}: FileItemProps) {
  const { t } = useTranslation(['globals', 'glossary']);
  const fileName = file.file.name.split('.');
  const extension = fileName.pop();

  useEffect(() => {
    if (autoCloseMs && onClose && file.status === 'completed') {
      const timeout = setTimeout(() => {
        onClose?.();
      }, autoCloseMs);

      return () => clearTimeout(timeout);
    }
  }, [autoCloseMs, file.status, onClose]);

  return (
    <div className='mt-2 flex h-16 w-full flex-row items-center justify-between gap-2 rounded-lg border border-solid border-border bg-white px-4 hover:bg-gray-50'>
      <div className='flex h-full min-w-0 flex-row items-center gap-4'>
        {file.file.type === 'application/pdf' ? (
          <FileText className='size-6 shrink-0 text-rose-700' />
        ) : (
          <ImageIcon className='size-6 shrink-0 text-rose-700' />
        )}
        <div className='flex min-w-0 flex-col'>
          <div className='truncate text-[0.85rem] font-medium leading-snug'>
            {file.file.name}
          </div>
          <div className='flex items-center gap-1 text-[0.7rem] leading-tight text-muted-fg'>
            <span className='shrink-0'>.{extension}</span>
            <span className='shrink-0'>•</span>
            <span className='shrink-0'>
              {(file.file.size / (1024 * 1024)).toFixed(2)} MB
            </span>
            {file.status === 'uploading' && (
              <span className='flex shrink-0 items-center gap-1'>
                <span>•</span>
                <Loader2 className='size-3 animate-spin' />
                <span>{t('globals:fileUpload.fileItem.uploading')}</span>
              </span>
            )}
            {file.status === 'completed' && (
              <span className='flex shrink-0 items-center gap-1'>
                <span>•</span>
                <CheckCircle2 className='size-3 text-green-600' />
                <span>{t('globals:fileUpload.fileItem.uploaded')}</span>
              </span>
            )}
            {file.status === 'error' && (
              <span className='shrink-0 text-red-500'>
                {t('globals:fileUpload.fileItem.error', {
                  message: file.errorMessage,
                })}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='flex items-center gap-2'>
        {file.status === 'completed' && onDelete && (
          <Button
            type='button'
            variant='ghost'
            outline
            className='text-danger'
            size='icon-sm'
            onClick={onDelete}
            aria-label={t('glossary:actions.delete')}
          >
            <Trash className='size-4' />
          </Button>
        )}
        {file.status !== 'uploading' && onClose && (
          <Button
            type='button'
            variant='ghost'
            outline
            size='icon-sm'
            onClick={onClose}
            aria-label={t('glossary:actions.close')}
          >
            <X className='size-4' />
          </Button>
        )}
      </div>
    </div>
  );
});
