import { Form, type FormProps } from '@utima/ui-informed';

import { FormSchemaContext, defaultAdapterContext } from './FormSchemaContext';
import { UIFormDevtools } from './UIFormDevtools';

export interface BasicFormProps<T> extends FormProps<T> {}

/**
 * Basic wrapper around Form component, providing debug tools and
 * confirmation dialogs for delete action. It also handles success
 */
export function BasicForm<T>({
  children,
  readOnly,
  initialValues,
  ...restProps
}: BasicFormProps<T>) {
  return (
    <FormSchemaContext.Provider value={defaultAdapterContext}>
      <Form
        showOptional
        allowEmptyStrings
        initialValues={initialValues}
        readOnly={readOnly}
        {...restProps}
      >
        {children}
        <UIFormDevtools />
      </Form>
    </FormSchemaContext.Provider>
  );
}
