import { Trans } from '@lingui/react/macro';
import { Button, cn } from '@utima/ui';
import { CornerDownLeft } from 'lucide-react';
import { memo, useEffect, useRef, useState } from 'react';

export interface MessageComposerProps {
  className?: string;
  onSubmit: (message: string) => void;
  disabled?: boolean;
  placeholder?: string;
}

export const MessageComposer = memo(
  ({
    onSubmit,
    className,
    placeholder,
    disabled = true,
  }: MessageComposerProps) => {
    const [message, setMessage] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    /**
     * Handles the auto-resizing of the textarea based on the content.
     */
    useEffect(() => {
      const textarea = textareaRef.current;

      if (!textarea) {
        return;
      }

      const resizeTextarea = () => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      // Initial resize
      resizeTextarea();

      // Create a ResizeObserver to watch for content changes
      const resizeObserver = new ResizeObserver(resizeTextarea);
      resizeObserver.observe(textarea);

      // Handle input events directly on the DOM element
      const handleInput = () => {
        resizeTextarea();
      };

      /**
       * Add the event listener directly to the DOM element,
       * this operates outside of React's control to prevent
       * performance impacts of the observer.
       */
      textarea.addEventListener('input', handleInput);

      return () => {
        resizeObserver.disconnect();
        textarea.removeEventListener('input', handleInput);
      };
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      if (!message.trim() || !disabled) {
        return;
      }

      onSubmit(message);
      setMessage('');
    };

    /**
     * Handles the change event of the textarea.
     */
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!disabled) {
        return;
      }

      setMessage(e.target.value);
    };

    /**
     * Submits the message when the Enter key is pressed without the Shift key.
     */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!disabled) {
        return;
      }

      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    return (
      <form
        onSubmit={handleSubmit}
        className={cn(className, 'absolute inset-x-0 bottom-0 p-4')}
      >
        <div
          className={cn(
            'grid cursor-text gap-2 overflow-hidden rounded-3xl border border-border bg-gray-50 p-2 relative',
            !disabled && 'opacity-70',
          )}
          onClick={() => disabled && textareaRef.current?.focus()}
        >
          <textarea
            ref={textareaRef}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            rows={1}
            disabled={!disabled}
            className='max-h-[150px] flex-1 resize-none overflow-y-auto border-none bg-transparent px-2 pt-2 outline-none disabled:cursor-not-allowed'
          />
          <div className='flex justify-end'>
            <Button
              type='submit'
              variant='primary'
              className='self-end !rounded-full'
              disabled={!disabled || !message.trim()}
            >
              <Trans>Send</Trans>
              <CornerDownLeft className='size-4' />
            </Button>
          </div>
        </div>
      </form>
    );
  },
);
