import { Trans } from '@lingui/react/macro';
import { Button, Input as UIInput } from '@utima/ui';
import { t } from 'i18next';
import { ListChecks, ListTodo, Search } from 'lucide-react';
import { memo, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useShallow } from 'zustand/react/shallow';

import type { WebsiteRecordValue } from '@/store/slices/documentModalSlice';
import { useBoundStore } from '@/store/store';

import { WebsiteRecord } from './WebsiteRecord';

/**
 * Check if the url matches the search query.
 */
function isUrlMatch(url: string, search: string) {
  return url.toLowerCase().includes(search.toLowerCase());
}

export type WebsiteRecordsProps = {
  search: string;
  setSearch: (search: string) => void;
};

export const WebsiteRecords = memo(
  ({ search, setSearch }: WebsiteRecordsProps) => {
    const { webUrls, setWebUrls } = useBoundStore(
      useShallow(state => ({
        webUrls: state.webUrls,
        setWebUrls: state.setWebUrls,
      })),
    );

    /**
     * Handles the (de)selection of all urls that match the search query.
     */
    const handleMultiple = useCallback(
      (selected: boolean) => {
        setWebUrls(
          webUrls.map(record => {
            if (isUrlMatch(record.url, search)) {
              return {
                ...record,
                selected,
              };
            }

            return record;
          }),
        );
      },
      [search, setWebUrls, webUrls],
    );

    /**
     * Handle the check/uncheck of a website record checkbox.
     */
    const handleRecordChange = useCallback(
      (id: string, data: Partial<WebsiteRecordValue>) => {
        setWebUrls(
          webUrls.map(rec => {
            if (rec.id === id) {
              return {
                ...rec,
                ...data,
              };
            }

            return rec;
          }),
        );
      },
      [webUrls, setWebUrls],
    );

    const filteredUrls = useMemo(() => {
      return webUrls.filter(val => isUrlMatch(val.url, search));
    }, [webUrls, search]);

    const selectedCount = useMemo(() => {
      return webUrls.filter(val => val.selected).length;
    }, [webUrls]);

    return (
      <div className='flex max-h-[400px] min-h-0 grow flex-col space-y-2 overflow-hidden rounded-radius border border-border bg-gray-50 p-4 md:max-h-[500px]'>
        <div className='flex-none text-sm font-bold text-gray-600'>
          <Trans>Select web sources</Trans>
        </div>
        <div className='flex-none'>
          <UIInput
            name='search'
            placeholder={t`Filter`}
            value={search}
            onChange={e => setSearch(e.target.value)}
            addonBefore={<Search className='size-4' />}
          />
        </div>

        <div className='min-h-0 grow overflow-hidden rounded-lg border border-border bg-white'>
          <div className='size-full p-4 text-xs'>
            <div className='mb-4 flex w-full items-center justify-between font-bold text-gray-600'>
              <div>
                <Trans>Page URL</Trans>
              </div>
              <div>
                <Trans>Information importance</Trans>
              </div>
            </div>

            <div className='h-[200px] md:h-[300px]'>
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemCount={filteredUrls.length}
                    itemSize={28}
                  >
                    {({ index, style }) => (
                      <WebsiteRecord
                        key={filteredUrls[index].id}
                        id={filteredUrls[index].id}
                        description={filteredUrls[index].url}
                        weight={filteredUrls[index].weight}
                        checked={filteredUrls[index].selected}
                        style={style}
                        onRecordChange={handleRecordChange}
                      />
                    )}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>

        <div className='mt-2 flex items-start justify-between'>
          <div className='flex items-center gap-2'>
            <Button
              outline
              variant='ghost'
              size='xs'
              onClick={() => handleMultiple(true)}
            >
              <ListChecks className='size-4' />
              <Trans>Select all</Trans>
            </Button>
            <Button
              outline
              variant='ghost'
              size='xs'
              onClick={() => handleMultiple(false)}
            >
              <ListTodo className='size-4' />
              <Trans>Deselect all</Trans>
            </Button>
          </div>
          <div className='text-sm font-bold text-gray-600'>
            {selectedCount}/{webUrls.length}
          </div>
        </div>
      </div>
    );
  },
);
