import {
  getShopProductsArgsSchema,
  getShopProductsArtifactSchema,
  getShopProductsContentSchema,
} from '@kanbu/schema/contracts';
import { plural } from '@lingui/core/macro';
import { Trans, useLingui } from '@lingui/react/macro';
import { motion, AnimatePresence } from 'framer-motion';
import { Tag, Search, Wallet, BadgeCent } from 'lucide-react';

import { ShopProductCarousel } from './ShopProductCarousel';
import { createToolComponent } from '../../../toolRegistry';
import { ScrollArea } from '../../ui';
import { ToolError } from '../components/ToolError';
import { ToolStatus } from '../components/ToolStatus';

function FilterChip({ icon, label }: { icon: React.ReactNode; label: string }) {
  return (
    <motion.span
      className='bg-white border border-secondary rounded-full px-1.5 py-0.5 inline-flex items-center gap-1 [&_svg]:size-3 [&_svg]:shrink-0 whitespace-nowrap'
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.2 }}
    >
      {icon} {label}
    </motion.span>
  );
}

export const GetShopProductsTool = createToolComponent(
  {
    schema: {
      args: getShopProductsArgsSchema,
      artifact: getShopProductsArtifactSchema,
      content: getShopProductsContentSchema,
    },
    validate: false,
  },
  ({ state, toolCall, artifact, error }) => {
    const { t } = useLingui();
    const { args } = toolCall;

    // All products have to have at least price, name url to be displayed
    const products = artifact?.products?.filter(
      p => p.price && p.name && p.url,
    );

    switch (state) {
      case 'call':
        return (
          <ToolStatus loading>
            <Trans>Analyzing your request...</Trans>
          </ToolStatus>
        );

      case 'call-partial':
      case 'call-complete':
        return (
          <ToolStatus loading>
            <Trans>Processing your request...</Trans>
          </ToolStatus>
        );

      case 'error':
        return (
          <ToolError message={<Trans>No products found</Trans>} error={error} />
        );

      case 'result':
        if (!products || products.length === 0) {
          return null;
        }

        return (
          <motion.div
            className='w-full'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className='flex flex-col gap-1.5'>
              <ToolStatus icon={<Search />}>
                {plural(products?.length ?? 0, {
                  one: 'Found 1 product',
                  other: 'Found # products',
                })}{' '}
                ({artifact?.totalCount}
                {artifact?.hasMore ? '+' : ''})
              </ToolStatus>

              <ScrollArea.Root className='w-full'>
                <AnimatePresence>
                  <motion.div
                    className='flex items-center flex-wrap gap-1 text-secondary text-xs'
                    initial={{ y: 5, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    {args.query ? (
                      <FilterChip
                        icon={<Search />}
                        label={t`"${args.query}"`}
                      />
                    ) : null}
                    {args.minPrice ? (
                      <FilterChip
                        icon={<Wallet />}
                        label={t`od ${args.minPrice} Kč`}
                      />
                    ) : null}
                    {args.maxPrice ? (
                      <FilterChip
                        icon={<Wallet />}
                        label={t`do ${args.maxPrice} Kč`}
                      />
                    ) : null}
                    {args.isOnSale ? (
                      <FilterChip icon={<BadgeCent />} label={t`Ve slevě`} />
                    ) : null}
                    {artifact?.categories?.map((category, index) => (
                      <FilterChip
                        key={category.id}
                        icon={<Tag className='size-3' />}
                        label={category.displayName ?? category.name}
                      />
                    ))}
                  </motion.div>
                </AnimatePresence>
              </ScrollArea.Root>
            </div>

            <ShopProductCarousel items={products} />
          </motion.div>
        );

      default:
        return null;
    }
  },
);
