import type { StateCreator } from 'zustand';

export type InboxUnreadSlice = {
  unreadThreadIds?: string[];
  setUnreadThreadIds: (unreadThreadIds: string[]) => void;
};

export const inboxUnreadSlice: StateCreator<InboxUnreadSlice> = set => ({
  unreadThreadIds: undefined,
  setUnreadThreadIds: unreadThreadIds => set({ unreadThreadIds }),
});
