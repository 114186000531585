import { ContentType } from '@kanbu/schema/enums';
import type { StateCreator } from 'zustand';

export type WebsiteRecordValue = {
  id: string;
  url: string;
  weight: number;
  selected: boolean;
};

export interface DocumentModalSlice {
  type: ContentType;
  setType: (type: ContentType) => void;
  externalDocumentUri: string | undefined;
  setExternalDocumentUri: (externalDocumentUri: string | undefined) => void;
  uri: string;
  setUri: (uri: string) => void;
  webUrls: WebsiteRecordValue[];
  setWebUrls: (urls: WebsiteRecordValue[]) => void;
}

export const documentModalSlice: StateCreator<DocumentModalSlice> = set => ({
  type: ContentType.Website,
  setType: type => set({ type }),
  uri: '',
  setUri: uri => set({ uri }),
  externalDocumentUri: undefined,
  setExternalDocumentUri: externalDocumentUri => set({ externalDocumentUri }),
  webUrls: [],
  setWebUrls: urls => set({ webUrls: urls }),
});
