import { Link } from '@tanstack/react-router';
import { Button } from '@utima/ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export type ErrorProps = {
  statusCode: number;
  title: string;
  description: string;
};

export const Error = memo(function Error({
  statusCode,
  title,
  description,
}: ErrorProps) {
  const { t } = useTranslation(['glossary']);

  return (
    <section>
      <div className='mx-auto flex h-screen max-w-screen-xl items-center justify-center px-4 py-8 lg:px-6 lg:py-16'>
        <div className='mx-auto max-w-screen-sm text-center'>
          <h1 className='mb-4 text-7xl font-extrabold tracking-tight text-secondary lg:text-9xl'>
            {statusCode}
          </h1>
          <p className='mb-4 text-3xl font-bold tracking-tight'>{title}</p>
          <p className='mb-4 text-lg font-light'>{description}</p>
          <Button variant='primary' asChild>
            <Link to='/inbox'>{t('glossary:actions.backHome')}</Link>
          </Button>
        </div>
      </div>
    </section>
  );
});
