import type { ThreadType } from '@kanbu/schema';
import { Trans } from '@lingui/react/macro';
import { Button } from '@utima/ui';
import { Loader2 } from 'lucide-react';
import { memo } from 'react';

import { ThreadItem } from './ThreadItem';

export interface ThreadListProps {
  threads: ThreadType[];
  isLoading?: boolean;
  selectedThreadId: string | null;
  isUnread?: (threadId: string) => boolean;
  onSelectThread: (threadId: string) => void;
  isFetchingNextPage?: boolean;
  onFetchNextPage: () => void;
  hasNextPage: boolean;
}

export const ThreadList = memo(
  ({
    threads,
    isLoading,
    selectedThreadId,
    isFetchingNextPage,
    onFetchNextPage,
    onSelectThread,
    hasNextPage,
    isUnread,
  }: ThreadListProps) => {
    if (isLoading) {
      return (
        <div className='flex h-full flex-col items-center justify-start pt-12'>
          <Loader2 className='size-10 animate-spin text-primary' />
        </div>
      );
    }

    if (!threads.length) {
      return (
        <div className='flex h-full flex-col items-center justify-start pt-12'>
          <p className='text-sm text-muted-fg'>
            <Trans>No threads found</Trans>
          </p>
        </div>
      );
    }

    return (
      <div>
        <div className='divide-y divide-gray-200'>
          {threads.map(thread => (
            <ThreadItem
              key={thread.id}
              onClick={() => onSelectThread(thread.id)}
              active={selectedThreadId === thread.id}
              thread={thread}
              isUnread={isUnread?.(thread.id)}
            />
          ))}
        </div>
        <div className='flex justify-center border-t border-gray-200 p-6'>
          {hasNextPage && (
            <Button
              variant='ghost'
              outline
              disabled={isFetchingNextPage}
              loading={isFetchingNextPage}
              onClick={onFetchNextPage}
            >
              <Trans>Load more</Trans>
            </Button>
          )}
        </div>
      </div>
    );
  },
);
