import { memo } from 'react';

import type { SharedMessageProps } from '../ui/Message';

export interface StatusMessageProps extends SharedMessageProps {}

export const StatusMessage = memo(({ group }: StatusMessageProps) => {
  const { messages } = group;

  return messages.map(message => {
    if (message.content) {
      return (
        <div
          className='my-2 px-4 font-medium text-center text-sm text-placeholder'
          key={message.id}
        >
          {message.content}
        </div>
      );
    }

    return null;
  });
});
