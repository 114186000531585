import {
  Gender,
  Agent,
  AgentType,
  CapabilitiesPreset,
  ContentType,
  ResponseLengthPreset,
  TonePreset,
  DocumentStatus,
  FeedbackCategory,
  SourcesFormat,
  FeedSource,
  FeedStatus,
} from '@kanbu/schema/enums';
import type { SupportedLanguageCode } from '@kanbu/shared';
import { t } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';
import { useMemo } from 'react';

/**
 * This type contains all the translations for the enums
 * that can be reused in the admin app.
 *
 * It is used mainly to ensure type safetry on the
 * returned translations from `getEnumTranslations()`.
 */
export type EnumTranslations = {
  tonePreset: Record<TonePreset, string>;
  agentType: Record<AgentType, string>;
  agent: Record<Agent, string>;
  capabilities: Record<CapabilitiesPreset, string>;
  capabilitiesPreset: Record<CapabilitiesPreset, string>;
  responseLengthPreset: Record<ResponseLengthPreset, string>;
  contentType: Record<ContentType, string>;
  supportedLanguages: Record<SupportedLanguageCode, string>;
  gender: Record<Gender, string>;
  documentStatus: Record<DocumentStatus, string>;
  feedbackCategory: Record<FeedbackCategory, string>;
  sourcesFormat: Record<SourcesFormat, string>;
  feedSource: Record<FeedSource, string>;
  feedStatus: Record<FeedStatus, string>;
};

/**
 * This function contains translations for enums and other constants
 * that can be reused in the admin app. It is memoized to avoid
 * re-rendering the same translations.
 */
export function getEnumTranslations() {
  return {
    tonePreset: {
      [TonePreset.Friendly]: t`Friendly`,
      [TonePreset.Casual]: t`Casual`,
      [TonePreset.Balanced]: t`Balanced`,
      [TonePreset.Professional]: t`Professional`,
      [TonePreset.Formal]: t`Formal`,
    },
    agentType: {
      [AgentType.Chat]: t`Chat`,
      [AgentType.Voice]: t`Voice`,
    },
    gender: {
      [Gender.None]: t`None`,
      [Gender.Male]: t`Male`,
      [Gender.Female]: t`Female`,
    },
    feedbackCategory: {
      [FeedbackCategory.InsufficientDetail]: t`Insufficient detail`,
      [FeedbackCategory.IrrelevantInformation]: t`Irrelevant information`,
      [FeedbackCategory.MissingInformation]: t`Missing information`,
      [FeedbackCategory.IncorrectInformation]: t`Incorrect information`,
      [FeedbackCategory.Other]: t`Other`,
    },
    agent: {
      [Agent.ConversationalRagV3]: t`Conversational RAG v3`,
      [Agent.VoicebotRag]: t`Voicebot RAG`,
      [Agent.VoiceChat]: t`Voice chat`,
      [Agent.ShopAssistant]: t`Shop assistant`,
    },
    capabilitiesPreset: {
      [CapabilitiesPreset.CustomerSupport]: t`Customer support`,
      [CapabilitiesPreset.Sales]: t`Sales`,
      [CapabilitiesPreset.HR]: t`HR`,
    },
    capabilities: {
      [CapabilitiesPreset.CustomerSupport]: t`Answering questions about the company, their products and services to provide users with sufficient information to solve problems`,
      [CapabilitiesPreset.Sales]: t`Answering questions about the company, their products and services for sales purposes`,
      [CapabilitiesPreset.HR]: t`Answering questions about the company, their products and services for user care`,
    },
    contentType: {
      [ContentType.ExternalDocument]: t`Online files`,
      [ContentType.Website]: t`Websites`,
      [ContentType.Document]: t`Uploaded files`,
    },
    sourcesFormat: {
      [SourcesFormat.Simple]: t`Simple`,
      [SourcesFormat.Academic]: t`Academic`,
    },
    responseLengthPreset: {
      [ResponseLengthPreset.Concise]: t`Concise`,
      [ResponseLengthPreset.Balanced]: t`Balanced`,
      [ResponseLengthPreset.Detailed]: t`Detailed`,
    },
    supportedLanguages: {
      en: t`English`,
      de: t`German`,
      fr: t`French`,
      es: t`Spanish`,
      it: t`Italian`,
      nl: t`Dutch`,
      pl: t`Polish`,
      pt: t`Portuguese`,
      da: t`Danish`,
      fi: t`Finnish`,
      no: t`Norwegian`,
      sv: t`Swedish`,
      is: t`Icelandic`,
      ru: t`Russian`,
      uk: t`Ukrainian`,
      cs: t`Czech`,
      sk: t`Slovak`,
      hu: t`Hungarian`,
      bg: t`Bulgarian`,
      ro: t`Romanian`,
      sr: t`Serbian`,
      hr: t`Croatian`,
      mk: t`Macedonian`,
      sl: t`Slovenian`,
      sq: t`Albanian`,
      lt: t`Lithuanian`,
      lv: t`Latvian`,
      et: t`Estonian`,
      el: t`Greek`,
      tr: t`Turkish`,
      he: t`Hebrew`,
      ca: t`Catalan`,
      gd: t`Scottish Gaelic`,
      cy: t`Welsh`,
      ga: t`Irish`,
      bs: t`Bosnian`,
      be: t`Belarusian`,
      ka: t`Georgian`,
      hy: t`Armenian`,
    },
    documentStatus: {
      [DocumentStatus.ParsingError]: t`Parsing error`,
      [DocumentStatus.Uploading]: t`Uploading`,
      [DocumentStatus.Ready]: t`Ready`,
      [DocumentStatus.Parsing]: t`Parsing`,
      [DocumentStatus.ParsingAsync]: t`Parsing (async)`,
      [DocumentStatus.EmbeddingError]: t`Embedding error`,
      [DocumentStatus.Parsed]: t`Parsed`,
      [DocumentStatus.Embedding]: t`Embedding`,
      [DocumentStatus.Done]: t`Done`,
    },
    feedSource: {
      [FeedSource.Heureka]: t`Heureka`,
    },
    feedStatus: {
      [FeedStatus.Pending]: t`Pending`,
      [FeedStatus.Processing]: t`Processing`,
      [FeedStatus.Done]: t`Done`,
      [FeedStatus.Failed]: t`Failed`,
    },
  } satisfies EnumTranslations;
}

/**
 * Use in react components, we memoize the translations
 * to avoid re-rendering the same translations.
 */
export function useEnumTranslations() {
  const { t } = useLingui();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getEnumTranslations(), [t]);
}
