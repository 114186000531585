import { Trans } from '@lingui/react/macro';
import { cn } from '@utima/ui';
import { AlertCircle } from 'lucide-react';
import { memo } from 'react';

export interface ConnectionStatusProps {
  className?: string;
}

/**
 * Component that displays the WebSocket connection status.
 */
export const ConnectionStatus = memo(({ className }: ConnectionStatusProps) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between gap-2 p-3 bg-red-50 border border-red-200 rounded-md',
        className,
      )}
    >
      <div className='flex items-center gap-2'>
        <AlertCircle className='size-5 shrink-0 text-red-500' />
        <span className='text-sm font-medium text-red-700'>
          <Trans>
            Connection lost. Unable to receive real-time updates. Please try to
            refresh the page to reconnect.
          </Trans>
        </span>
      </div>
    </div>
  );
});
