import { createFileRoute, Outlet, Link } from '@tanstack/react-router';
import { Layout } from '@utima/ui';

import logo from '@/assets/images/logo-black.svg';
import { LanguageSwitcher } from '@/components/languageSwitcher/LanguageSwitcher';
import { Menu } from '@/components/menu/Menu';
import { UserDropdown } from '@/components/userDropdown/UserDropdown';
import { UsageLimitBanner } from '@/features/organizations/components/UsageLimitBanner';
import { useUserMeData } from '@/features/users/useUserMeData';

export const Route = createFileRoute('/_fullWidthLayout')({
  beforeLoad: async ({ context: { authGuard } }) => authGuard(),
  component: FullWidthLayout,
});

export function FullWidthLayout() {
  useUserMeData();

  return (
    <Layout.Root hasHeader={false} headerHeight={0}>
      <Layout.Sidebar className='flex flex-col justify-between border-r p-3 text-foreground'>
        <div>
          <div className='mx-1 my-2 mb-3 flex items-center justify-between'>
            <Link to='/' className='block p-1'>
              <img src={logo} alt='Kanbu.ai' className='h-8 w-auto' />
            </Link>
            <div>
              <LanguageSwitcher />
            </div>
          </div>
          <Menu />
        </div>
        <UserDropdown />
      </Layout.Sidebar>
      <Layout.Content>
        <UsageLimitBanner />
        <Outlet />
      </Layout.Content>
    </Layout.Root>
  );
}
