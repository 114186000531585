import { CommonToolNames } from './commonToolContract';
import { ShopProductToolNames } from './shopAssistant/shopProductToolsContract';

export * from './shopAssistant/shopProductToolsContract';
export * from './commonToolContract';

export const ToolNames = {
  ...ShopProductToolNames,
  ...CommonToolNames,
} as const;

export type ToolName = (typeof ToolNames)[keyof typeof ToolNames];
