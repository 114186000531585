import { AgentType } from '@kanbu/schema/enums';
import { useLingui } from '@lingui/react/macro';
import { Label } from '@utima/ui';
import { Select } from '@utima/ui-informed';
import { useFieldState } from 'informed';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useAccess } from '@/hooks/useAccess';

import { VoicePreview } from '../voice/ElevenLabsPreview';

const ElevenLabsVoices = {
  uYFJyGaibp4N2VwYQshk: 'Adam',
  KIDKfqJyZ6ASuyzsKfh5: 'Jan',
  nCmK9bolkZa2wzrAjnEe: 'Ondřej',
  SZXidiHhq5QYe3jRboSZ: 'Anet',
};

export function VoiceConfigFields() {
  const { hasAccess } = useAccess();
  const { t } = useLingui();

  const voiceIdField = useFieldState('voiceConfig.elevenLabs.voiceId');
  const initialMessageField = useFieldState('chatbotConfig.initialMessage');

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'chatbotConfig.initialMessage',
        label: t`Initial message`,
        type: 'text',
        uiControl: 'textarea',
        defaultValue: t`Hi, I'm a voicebot. How can I help you today?`,
        showOptional: false,
      },
      {
        name: 'voiceConfig.keywords',
        label: t`Keywords (serparated by ",")`,
        type: 'text',
        uiControl: 'textarea',
        tooltip: t`Use keywords specific to your domain, to help voicebot better understand specific terms and phrases.`,
        showOptional: false,
        // @ts-expect-error - invalid types
        parser: (value: string) =>
          (value ?? '').split(',').map(keyword => keyword.trim()),
        initialize: (value: string[]) => (value ?? []).join(', '),
      },
      {
        name: 'voiceConfig.endpointing',
        label: t`Endpointing (min 10)`,
        type: 'number',
        showOptional: false,
        defaultValue: 10,
        tooltip: t`Time to wait for silence before processing the audio. Lower values will result in faster processing but may result in incomplete audio.`,
        uiProps: {
          addonAfter: 'ms',
        },
      },
    ],
    [t, hasAccess],
  );

  const typeFieldState = useFieldState('agentType');

  // These fields should be visible only if the agent type is Voice
  if (typeFieldState.value !== AgentType.Voice) {
    return null;
  }

  return (
    <Card title={t`Voice settings`}>
      <div className='flex flex-col gap-2'>
        <Label>{t`Voice`}</Label>
        <div className='flex items-center gap-1'>
          <Select
            name='voiceConfig.elevenLabs.voiceId'
            defaultValue={Object.keys(ElevenLabsVoices)[0]}
          >
            {Object.entries(ElevenLabsVoices).map(([value, label]) => (
              <Select.Item key={value} value={value}>
                {label}
              </Select.Item>
            ))}
          </Select>
          <VoicePreview
            voiceId={voiceIdField.value as string}
            text={initialMessageField.value as string}
          />
        </div>
      </div>
      <SchemaFields schema={detailsSchema} />
    </Card>
  );
}
