import { DEFAULT_DOCUMENT_WEIGHT } from '@kanbu/shared';
import { Trans, useLingui } from '@lingui/react/macro';
import { Button, Dialog, Input, Label } from '@utima/ui';
import { Globe } from 'lucide-react';
import { memo, useCallback, useState } from 'react';
import { v7 as uuidV7 } from 'uuid';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

import { WebsiteRecords } from './websiteRecords/WebsiteRecords';

export type WebsiteFormProps = {
  chatId: string;
  setOpen: (open: boolean) => void;
  defaultUrl?: string;
};

export const AddWebsites = memo(function WebsiteForm({
  chatId,
  setOpen,
  defaultUrl,
}: WebsiteFormProps) {
  const { t } = useLingui();
  const [isCrawling, setIsCrawling] = useState(false);
  const [isUriValid, setIsUriValid] = useState(true);
  const [search, setSearch] = useState('');
  const utils = trpc.useUtils();
  const { uri, setUri, webUrls, setWebUrls, user } = useBoundStore(
    useShallow(state => ({
      uri: state.uri,
      setUri: state.setUri,
      webUrls: state.webUrls,
      setWebUrls: state.setWebUrls,
      user: state.user,
    })),
  );

  const {
    mutateAsync: mapUrl,
    isPending,
    reset: resetMapUrl,
  } = trpc.documents.mapUrl.useMutation();

  const { mutateAsync: createWebsite, isPending: isCreatingWebsite } =
    trpc.documents.createWebsite.useMutation({
      onSuccess: async () =>
        Promise.all([
          utils.documents.findAll.invalidate(),
          utils.chats.findOne.invalidate({ id: chatId }),
        ]),
    });

  const selectedUrls = webUrls.filter(url => url.selected);

  /**
   * Validates the uri.
   */
  const validateUri = useCallback(() => {
    const isValid = z
      .string()
      .url()
      .regex(
        /^https?:\/\/(?:www\.)?[\w#%+.:=@~-]{1,256}\.[\d()A-Za-z]{1,6}\b[\w#%&()+./:=?@~-]*$/,
        {
          message: 'Must be a valid HTTP or HTTPS URL',
        },
      )
      .safeParse(uri).success;
    setIsUriValid(isValid);

    return isValid;
  }, [uri]);

  /**
   * Changes local search state and updates the store.
   */
  const handleUriChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUri(e.target.value);
      setIsUriValid(true);
    },
    [setUri],
  );

  /**
   * Handle the crawl of a website.
   */
  const handleCrawl = useCallback(async () => {
    if (!validateUri()) {
      return;
    }

    if (!uri) {
      return;
    }

    setIsCrawling(true);

    // Reset the state
    setWebUrls([]);
    setSearch('');

    const response = await mapUrl({ url: uri });

    // Store the new URLs in the global state
    setWebUrls(
      response?.map(url => ({
        id: uuidV7(),
        url,
        selected: true,
        weight: DEFAULT_DOCUMENT_WEIGHT,
      })) ?? [],
    );

    setIsCrawling(false);
  }, [mapUrl, setWebUrls, uri, validateUri]);

  /**
   * Reset all urls and search queries.
   */
  const handleReset = useCallback(() => {
    setUri('');
    setSearch('');
    setWebUrls([]);
  }, [setUri, setSearch, setWebUrls]);

  /**
   * Handle the submission of the selected urls.
   */
  const handleAdd = useCallback(async () => {
    let finalUrls: string[] = [];
    let weights: Record<string, number> = {};

    // Validate the uri
    if (!validateUri()) {
      return;
    }

    // Handle submission of single url
    if (uri && !selectedUrls.length) {
      finalUrls = [uri];
      weights = { [uri]: DEFAULT_DOCUMENT_WEIGHT };
    } else {
      // Validate urls and remove duplicates
      finalUrls = Array.from(new Set(selectedUrls.map(url => url.url)));
      weights = selectedUrls.reduce(
        (acc, url) => {
          acc[url.url] =
            typeof url.weight === 'number'
              ? url.weight
              : DEFAULT_DOCUMENT_WEIGHT;

          return acc;
        },
        {} as Record<string, number>,
      );
    }

    await createWebsite({
      urls: finalUrls,
      chat: chatId,
      weights,
    });

    // Replace current URL to remove the 'open' query parameter
    const url = new URL(window.location.href);
    url.searchParams.delete('open');
    window.history.replaceState(null, '', url.toString());

    resetMapUrl();
    handleReset();
    setOpen(false);
  }, [
    validateUri,
    uri,
    selectedUrls,
    createWebsite,
    chatId,
    resetMapUrl,
    handleReset,
    setOpen,
  ]);

  return (
    <>
      <div>
        <div className='mb-3 flex flex-col gap-2'>
          <div className='flex w-full items-end gap-2'>
            <div className='flex w-full flex-col gap-2'>
              <Label htmlFor='uri'>{t`URL`}:</Label>
              <Input
                name='uri'
                placeholder='https://www.yourwebsite.com'
                onChange={handleUriChange}
                value={uri ?? defaultUrl ?? user?.organization.url ?? ''}
                required
              />
            </div>

            <Button
              size='sm'
              disabled={isPending || isCrawling || !uri}
              className='whitespace-nowrap'
              loading={isPending || isCrawling}
              onClick={handleCrawl}
              icon={<Globe className='size-4' />}
            >
              <Trans>Crawl website</Trans>
            </Button>
          </div>

          {!isUriValid && (
            <span className='text-sm text-red-500'>
              {t`URL is not valid, it should start with https://`}
            </span>
          )}
        </div>

        <WebsiteRecords search={search} setSearch={setSearch} />
      </div>

      <Dialog.Footer className='z-50 flex flex-row justify-end gap-2 md:flex-row'>
        {webUrls.length > 0 && (
          <Button variant='secondary' size='sm' onClick={() => handleReset()}>
            <Trans>Reset</Trans>
          </Button>
        )}
        <Button
          disabled={isCreatingWebsite || (!selectedUrls.length && !uri)}
          loading={isCreatingWebsite}
          onClick={handleAdd}
        >
          <Trans>Add</Trans>
        </Button>
      </Dialog.Footer>
    </>
  );
});
