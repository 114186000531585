import { useLingui } from '@lingui/react/macro';
import { createFileRoute } from '@tanstack/react-router';

import { FormPage } from '@/components/page/FormPage';
import { FeedForm } from '@/features/feeds/FeedForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chats/$chatId/feeds/create')(
  {
    component: FeedsCreatePage,
    loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
      trpcUtils.chats.findOne.ensureData({ id: chatId }),
  },
);

export function FeedsCreatePage() {
  const { chatId } = Route.useParams();
  const { t } = useLingui();
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });

  return (
    <FormPage
      title={t`Create Feed`}
      breadcrumbs={[
        {
          label: data.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t`Feeds`,
          to: '/chats/$chatId/feeds',
          params: { chatId },
        },
        { label: t`Create Feed` },
      ]}
    >
      <FeedForm chatId={chatId} />
    </FormPage>
  );
}
