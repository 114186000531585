import {
  ragResultToolDef,
  type RagResultToolDef,
} from '@kanbu/schema/contracts';
import { ContentType, SourcesFormat } from '@kanbu/schema/enums';
import { Trans } from '@lingui/react/macro';
import { ChevronRight, File, FileSymlink, Globe } from 'lucide-react';
import { memo } from 'react';

import { createToolComponent } from '../../toolRegistry';
import { Markdown } from '../Markdown';
import { Message } from '../ui/Message';

/**
 * Parses the URL and adds the page number to the URL if it is a PDF file.
 * Otherwise, it returns the original URL.
 */
function getHref(url: string | undefined, pages: string | undefined) {
  if (!url || !pages) {
    return url;
  }

  try {
    const parsedUrl = new URL(url);

    /**
     * Page can contain multiple pages separated by commas,
     * we want to use the first one.
     */
    const [page] = pages.split(',');

    if (parsedUrl.pathname.toLowerCase().endsWith('.pdf')) {
      return `${parsedUrl}#page=${page}`;
    }

    return url;
  } catch {
    return url;
  }
}

export type RagResultSourceProps = {
  format: SourcesFormat;
  source: NonNullable<RagResultToolDef['sources']>[number];
};

/**
 * Renders a single source in the list of sources.
 */
const RagResultSource = memo(({ source, format }: RagResultSourceProps) => {
  return (
    <li className='grid grid-cols-[1fr_auto] items-center gap-1'>
      <a
        className='inline-flex items-baseline break-all hover:underline'
        href={getHref(source.url, source.page)}
        target='_blank'
        rel='noreferrer'
      >
        {source?.type === ContentType.Document ? (
          <File className='relative top-0.5 mr-1 size-4 shrink-0' />
        ) : source?.type === ContentType.Website ? (
          <Globe className='relative top-0.5 mr-1 size-4 shrink-0' />
        ) : source?.type === ContentType.ExternalDocument ? (
          <FileSymlink className='relative top-0.5 mr-1 size-4 shrink-0' />
        ) : null}

        <span>
          {format === SourcesFormat.Simple ? (
            source.fileName
          ) : format === SourcesFormat.Academic ? (
            <>
              {source.fileName}
              {source.header && (
                <>
                  {' '}
                  | <strong key='header'>{source.header}</strong>
                </>
              )}
              {source.article && ` | ${source.article}`}
              {source.page && (
                <>
                  {' '}
                  | <Trans context='page-shortcut'>p</Trans>. {source.page}
                </>
              )}
            </>
          ) : null}
        </span>
      </a>
      <ChevronRight className='size-4 shrink-0' />
    </li>
  );
});

export interface RagResultToolProps {
  sourcesFormat?: SourcesFormat;
}

/**
 * This is for testing, but I will leave it here, until there are some
 * tools for inspiration.
 */
export const RagResultTool = createToolComponent<
  RagResultToolProps,
  typeof ragResultToolDef
>(
  {
    schema: {
      args: ragResultToolDef,
    },
  },
  ({ toolCall, sourcesFormat = SourcesFormat.Simple }) => {
    const { content, sources = [] } = toolCall.args;
    const filteredSources = sources?.filter(source => source.url);

    return (
      <Message.BubbleGroup>
        <Message.Bubble>
          <Markdown>{content ?? ''}</Markdown>
        </Message.Bubble>
        {sources.length > 0 && (
          <Message.Bubble>
            <h4 className='text-sm font-bold mb-2'>
              <Trans>Sources</Trans>
            </h4>
            <ul className='flex flex-col gap-1'>
              {filteredSources?.map((source, index) => (
                <RagResultSource
                  key={index}
                  source={source}
                  format={sourcesFormat}
                />
              ))}
            </ul>
          </Message.Bubble>
        )}
      </Message.BubbleGroup>
    );
  },
);
