import type { OrganizationWorkingHours } from '@kanbu/schema';
import { createContext, useContext, useMemo } from 'react';

export const WorkingHoursContext =
  createContext<OrganizationWorkingHours | null>(null);

export function useWorkingHours() {
  const workingHours = useContext(WorkingHoursContext);

  const isOpen = useMemo(() => {
    if (!workingHours) {
      return false;
    }

    const now = new Date();
    const workingHoursDay =
      workingHours[`${now.getDay()}` as keyof typeof workingHours];

    if (workingHoursDay && Array.isArray(workingHoursDay)) {
      for (const hours of workingHoursDay) {
        const from = new Date(hours.from);
        const to = new Date(hours.to);

        /**
         * The `from` and `to` dates are in the past, so we need to
         * convert them to the current date to simplify the comparison.
         * We are only interested in hours and minutes.
         */
        if (
          now >=
            new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              from.getHours(),
              from.getMinutes(),
            ) &&
          now <=
            new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              to.getHours(),
              to.getMinutes(),
            )
        ) {
          return true;
        }
      }
    }

    return false;
  }, [workingHours]);

  return { workingHours, isOpen };
}
