import type { ThreadType } from '@kanbu/schema';
import { ThreadMode } from '@kanbu/schema/enums';
import { Trans, useLingui } from '@lingui/react/macro';
import { Button, cn } from '@utima/ui';
import { Info, Loader2 } from 'lucide-react';
import { memo, useEffect, useMemo, useRef } from 'react';

import avatar from '@/assets/images/avatar.webp';
import kanbuAvatar from '@/assets/images/bubble-kanbu.webp';
import { useOnlineStatus } from '@/hooks';

import { InboxChat } from './InboxChat';
import { MessageComposer } from './MessageComposer';
import { OnlineStatus } from './OnlineStatus';
import { TakeoverRequest } from './TakeoverRequest';
import { useInboxTakeover } from '../hooks/useInboxTakeover';

export interface InboxConversationProps {
  thread: ThreadType | undefined;
  isLoading?: boolean;
  onSubmit: (message: string) => void;
  onToggleMetadata?: () => void;
}

export const InboxConversation = memo(
  ({
    thread,
    isLoading,
    onSubmit,
    onToggleMetadata,
  }: InboxConversationProps) => {
    const { t } = useLingui();
    const systemAvatar = thread?.chat?.chatbotConfig?.systemAvatar;
    const avatarSrc =
      systemAvatar ??
      (thread?.chat?.themeConfig?.preset === 'kanbu' ? kanbuAvatar : avatar);

    const onlineStatus = useOnlineStatus(thread?.lastActiveAt);
    const {
      pendingTakeover,
      requestTakeover,
      cancelTakeover,
      isTakeoverLoading,
    } = useInboxTakeover(thread?.id);

    // Track the last message id for scroll to bottom
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const lastMessageId = useMemo(
      () => thread?.messages?.at(-1)?.id,
      [thread?.messages],
    );
    const prevMessageId = useRef(lastMessageId);

    // Only scroll if we have a new message ID
    useEffect(() => {
      if (
        messagesContainerRef.current &&
        lastMessageId &&
        lastMessageId !== prevMessageId.current
      ) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
        prevMessageId.current = lastMessageId;
      }
    }, [lastMessageId]);

    if (isLoading) {
      return (
        <div className='flex h-full flex-col items-center justify-start pt-12'>
          <Loader2 className='size-10 animate-spin text-primary' />
        </div>
      );
    }

    if (!thread) {
      return (
        <div className='flex h-full items-center justify-center text-muted-fg'>
          <Trans>No thread selected</Trans>
        </div>
      );
    }

    const isUserOnline = onlineStatus === 'online';
    const isUserChattingWithAI = isUserOnline && thread.mode === ThreadMode.AI;
    const showTakeover = isUserChattingWithAI;

    return (
      <div className='relative flex h-full flex-col'>
        <div className='border-b border-gray-200 p-4'>
          <div className='flex items-center justify-between'>
            <div>
              <h2 className='text-lg font-semibold'>
                {thread.title || <Trans>Untitled</Trans>}
              </h2>
              <div className='mt-1 flex items-center gap-2'>
                <OnlineStatus lastActiveAt={thread.lastActiveAt} />
                {isUserChattingWithAI && (
                  <div className='flex items-center gap-1 text-xs text-amber-600'>
                    <Trans>User is chatting with AI</Trans>
                  </div>
                )}
              </div>
            </div>
            <Button
              variant='ghost'
              size='icon-sm'
              onClick={onToggleMetadata}
              className='hidden items-center gap-1 sm:flex'
            >
              <Info className='size-5' />
            </Button>
          </div>
        </div>

        <div
          ref={messagesContainerRef}
          className={cn('flex-1 overflow-auto', {
            // Add bottom padding so there is some space above the input
            'pb-32': !showTakeover,
          })}
        >
          <InboxChat
            messages={thread.messages}
            agentName={thread.chat?.agentName}
            agentAvatar={avatarSrc}
          />
        </div>

        {showTakeover ? (
          <TakeoverRequest
            onRequestTakeover={requestTakeover}
            hasPendingRequest={!!pendingTakeover.data}
            isLoading={isTakeoverLoading}
            onCancel={cancelTakeover}
          />
        ) : (
          <MessageComposer
            onSubmit={onSubmit}
            disabled={
              !isUserOnline ||
              (isUserOnline && thread.mode === ThreadMode.Human)
            }
            placeholder={t`Type your message...`}
          />
        )}
      </div>
    );
  },
);
