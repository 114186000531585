import { ChatRole } from '@kanbu/schema/enums';
import { sortMessagesChronologically } from '@kanbu/shared';
import { motion, AnimatePresence } from 'framer-motion';
import {
  type ComponentType,
  Fragment,
  type ReactNode,
  memo,
  useMemo,
} from 'react';

import { AdministratorMessage } from './message/AdministratorMessage';
import { AssistantMessage } from './message/AssistantMessage';
import { StatusMessage } from './message/StatusMessage';
import { UserMessage } from './message/UserMessage';
import type { SharedMessageProps } from './ui/Message';
import type { ToolRegistry } from '../toolRegistry';
import type { ChatMessageItem, MessagesGroup } from '../types';

export type ChatRendererRoleConfig = Pick<
  SharedMessageProps,
  'variant' | 'position'
>;

export type ChatRendererRolesConfig = Record<
  Exclude<ChatRole, ChatRole.Status>,
  ChatRendererRoleConfig
>;

export interface ChatRendererProps {
  rolesConfig?: ChatRendererRolesConfig;
  agentName?: string;
  agentAvatar?: string;
  messages: ChatMessageItem[];
  toolRegistry?: ToolRegistry;
  className?: string;
  isFetching?: boolean;
  messageGroupingThreshold?: number;
  children?: ReactNode;
  renderAfterGroup?: (group: MessagesGroup, index: number) => ReactNode;
  shouldRenderFeedback?: (group: MessagesGroup) => boolean;
  FeedbackComponent?: ComponentType<{
    message: ChatMessageItem;
  }>;
}

/**
 * Takes a messages and configuration and renders the data into
 * chat interface. Groups messages next to each other from same role
 * to a group within certain timestamp.
 */
export const ChatRenderer = memo(
  ({
    className = '',
    messages,
    agentName,
    agentAvatar,
    toolRegistry,
    rolesConfig,
    isFetching,
    FeedbackComponent,
    messageGroupingThreshold = 15 * 60 * 1000, // 15 minutes
    children,
    renderAfterGroup,
    shouldRenderFeedback,
  }: ChatRendererProps) => {
    /**
     * Creates a message groups by roles. We group multiple messages
     * from the same role into one group. This allows us to show only
     * one avatar, and only one timestamp.
     *
     * Since we use uuid v7 which is time based, we can sort the messages
     * by id to get the correct order.
     */
    const groupedMessages = useMemo(() => {
      const sortedMessages = messages.slice().sort(sortMessagesChronologically);
      const groups = sortedMessages.reduce<MessagesGroup[]>(
        (acc, message, index) => {
          const prevMessage = sortedMessages[index - 1];
          const lastGroup = acc.at(-1);

          if (
            !prevMessage ||
            prevMessage.role !== message.role ||
            Math.abs(
              new Date(message.createdAt).getTime() -
                new Date(prevMessage.createdAt).getTime(),
            ) > messageGroupingThreshold
          ) {
            acc.push({
              id: message.id,
              messages: [message],
              role: message.role,
              createdAt: new Date(message.createdAt),
              isInitial: message.isInitial,
              isLast: false,
            });
          } else {
            lastGroup?.messages.push(message);
          }

          return acc;
        },
        [],
      );

      // Set isLast to true for the last message in each group
      if (groups.length > 0) {
        groups.at(-1)!.isLast = true;
      }

      return groups;
    }, [messages, messageGroupingThreshold]);

    return (
      <div
        className={`flex grow flex-col gap-3 [&>*:first-child]:mt-auto ${className}`}
      >
        <AnimatePresence mode='sync'>
          {groupedMessages.map((group, index) => (
            <Fragment key={group.id}>
              <motion.div
                initial={{ y: 10, opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
              >
                {group.role === ChatRole.User ? (
                  <UserMessage group={group} {...rolesConfig?.[group.role]} />
                ) : group.role === ChatRole.Assistant ? (
                  <AssistantMessage
                    group={group}
                    name={agentName}
                    avatar={agentAvatar}
                    toolRegistry={toolRegistry}
                    isFetching={isFetching}
                    shouldRenderFeedback={shouldRenderFeedback}
                    FeedbackComponent={FeedbackComponent}
                    {...rolesConfig?.[group.role]}
                  />
                ) : group.role === ChatRole.Administrator ? (
                  <AdministratorMessage
                    group={group}
                    {...rolesConfig?.[group.role]}
                  />
                ) : group.role === ChatRole.Status ? (
                  <StatusMessage group={group} />
                ) : null}
              </motion.div>
              {renderAfterGroup?.(group, index)}
            </Fragment>
          ))}
          {children}
        </AnimatePresence>
      </div>
    );
  },
);
