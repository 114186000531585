import { useState } from 'react';

import { useUser } from '@/hooks';
import { trpc } from '@/services/trpc';

/**
 * Handles the takeover requests for selected thread.
 */
export function useInboxTakeover(
  threadId: string | undefined,
  refetchInterval = 60000,
) {
  const { user } = useUser();
  const utils = trpc.useUtils();
  const [isTakeoverLoading, setIsTakeoverLoading] = useState(false);

  /**
   * Query to check if there's a pending takeover request.
   */
  const pendingTakeover = trpc.threads.getPendingTakeoverRequest.useQuery(
    {
      id: threadId!,
    },
    {
      enabled: !!threadId,
      notifyOnChangeProps: 'all',
      refetchOnMount: 'always',
      refetchOnWindowFocus: 'always',
      refetchInterval,
    },
  );

  /**
   * Mutation to create a takeover request.
   */
  const createTakeover = trpc.threads.createTakeoverRequest.useMutation({
    onSuccess: () => {
      if (!threadId) {
        return;
      }

      utils.threads.getPendingTakeoverRequest.invalidate({
        id: threadId,
      });
    },
  });

  /**
   * Mutation to cancel a pending takeover request.
   */
  const cancelTakeover = trpc.threads.cancelTakeoverRequest.useMutation({
    onSuccess: () => {
      if (!threadId) {
        return;
      }

      utils.threads.getPendingTakeoverRequest.invalidate({
        id: threadId,
      });
    },
  });

  /**
   * Initiates a takeover request for given thread.
   */
  const handleRequestTakeover = async (message: string) => {
    if (!threadId) {
      return;
    }

    try {
      setIsTakeoverLoading(true);

      await createTakeover.mutateAsync({
        threadId: threadId,
        message,
        user: {
          id: user?.id ?? '',
          firstName: user?.firstName ?? '',
          lastName: user?.lastName ?? '',
          photo: user?.photo ?? '',
        },
      });
    } catch (error) {
      console.error('Failed to create takeover request:', error);
    } finally {
      setIsTakeoverLoading(false);
    }
  };

  /**
   * Cancels pending takeover request for given thread.
   */
  const handleCancelTakeoverRequest = async () => {
    if (!threadId) {
      return;
    }

    try {
      setIsTakeoverLoading(true);
      await cancelTakeover.mutateAsync({
        id: threadId,
      });
    } catch (error) {
      console.error('Failed to cancel takeover request:', error);
    } finally {
      setIsTakeoverLoading(false);
    }
  };

  return {
    isTakeoverLoading,
    pendingTakeover,
    requestTakeover: handleRequestTakeover,
    cancelTakeover: handleCancelTakeoverRequest,
  };
}
