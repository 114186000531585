import { memo } from 'react';

import { useTimeAgo } from '../../hooks/useTimeAgo';
import { Message, type SharedMessageProps } from '../ui/Message';

export interface UserMessageProps extends SharedMessageProps {}

export const UserMessage = memo(
  ({ group, position = 'right', variant = 'primary' }: UserMessageProps) => {
    const { messages, createdAt } = group;
    const timeAgo = useTimeAgo(createdAt);

    return (
      <Message.Root variant={variant} position={position}>
        <Message.Wrapper>
          <Message.Content>
            <Message.BubbleGroup>
              {messages.map(message => (
                <Message.Bubble key={message.id}>
                  {message.content}
                </Message.Bubble>
              ))}
            </Message.BubbleGroup>
          </Message.Content>
        </Message.Wrapper>
        <Message.Footer>{timeAgo}</Message.Footer>
      </Message.Root>
    );
  },
);
