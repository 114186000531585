import type { ComponentType } from 'react';
import type { z } from 'zod';

import type { ToolComponentProps } from './components/tools/ToolProvider';

/**
 * Tool definition, returned from the createToolComponent function.
 */
export interface ToolDefinition<
  ArgsSchema extends z.ZodType | undefined = undefined,
  ContentSchema extends z.ZodType | undefined = undefined,
  ArtifactSchema extends z.ZodType | undefined = undefined,
  Props = {},
> {
  component: ComponentType<
    ToolComponentProps<
      Infer<ArgsSchema>,
      Infer<ContentSchema>,
      Infer<ArtifactSchema>,
      Props
    >
  >;
  schema?: {
    args?: ArgsSchema;
    content?: ContentSchema;
    artifact?: ArtifactSchema;
  };
  onParseError?: (error: z.ZodError) => string;
  validate?: boolean;
}

type Infer<TSchema> = TSchema extends z.ZodType
  ? z.infer<TSchema>
  : TSchema extends undefined
    ? unknown
    : never;

/**
 * Helper for creating a tool components with some additional
 * helpers and state management.
 *
 * @example
 *
 * const toolComponent = createToolComponent(({ toolCall, state, result }) => {
 *   if (state === 'call') {
 *     return <div>Loading...</div>;
 *   }
 *
 *   if (state === 'result') {
 *     return <div>{result}</div>;
 *   }
 *
 *   return <div>{toolCall.name}</div>;
 * });
 */
export function createToolComponent<
  Props = {},
  ArgsSchema extends z.ZodType | undefined = undefined,
  ContentSchema extends z.ZodType | undefined = undefined,
  ArtifactSchema extends z.ZodType | undefined = undefined,
>(
  params: {
    schema?: {
      args?: ArgsSchema;
      content?: ContentSchema;
      artifact?: ArtifactSchema;
    };
    validate?: boolean;
    onParseError?: (error: z.ZodError) => string;
  },
  Component: React.FC<
    ToolComponentProps<
      Infer<ArgsSchema>,
      Infer<ContentSchema>,
      Infer<ArtifactSchema>,
      Props
    >
  >,
): ToolDefinition<ArgsSchema, ContentSchema, ArtifactSchema, Props> {
  const { validate = true, onParseError, schema } = params;

  return {
    component: Component,
    schema,
    validate,
    onParseError,
  };
}

/**
 * Registry of tools. Hold all the tool component
 * definitions that can be used in the UI.
 */
export interface ToolRegistry {
  [key: string]: ToolDefinition<any, any, any, any> | undefined;
}

// TODO - should support async imports
export function createToolRegistry(registry: ToolRegistry) {
  return registry;
}
