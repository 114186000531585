import { cn } from '@utima/ui';
import { memo, type ReactNode } from 'react';

import { useSidebarPaneContext } from '@/components/sidebarPane/SidebarPaneContext';

export const SidebarPaneContainer = memo(function ({
  children,
}: {
  children: ReactNode;
}) {
  const { isOpen } = useSidebarPaneContext();

  return (
    <div
      className={cn(
        'items-around flex flex-row gap-3 transition-all duration-200',
        {
          'mr-[500px]': isOpen,
          'hidden md:flex': isOpen,
        },
      )}
    >
      {children}
    </div>
  );
});
