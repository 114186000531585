import { plural } from '@lingui/core/macro';
import { useLingui } from '@lingui/react/macro';
import { useEffect, useState, type ComponentPropsWithoutRef } from 'react';

export interface TimeAgoProps extends ComponentPropsWithoutRef<'div'> {
  date: string | Date | undefined;
}

/**
 * Calculate the time difference between the
 * provided time and the current time. It also
 * updates the time every minute.
 */
export function useTimeAgo(
  date: string | Date | undefined,
  updateInterval = 60000,
) {
  const { t } = useLingui();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, updateInterval);

    return () => clearInterval(intervalId);
  }, [updateInterval]);

  if (!date) {
    return t`Sent just now`;
  }

  const timeDifferenceInSeconds = Math.floor(
    (Number(currentTime) - Number(new Date(date))) / 1000,
  );

  if (timeDifferenceInSeconds < 60) {
    return t`Sent just now`;
  }

  let time = '';

  if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);

    time = plural(minutes, {
      one: `# minute`,
      other: `# minutes`,
    });
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);

    time = plural(hours, {
      one: `# hour`,
      other: `# hours`,
    });
  } else {
    const days = Math.floor(timeDifferenceInSeconds / 86400);

    time = plural(days, {
      one: `# day`,
      other: `# days`,
    });
  }

  return t`Sent ${time} ago`;
}
