import { create, type StateCreator } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { createAppSlice, type AppSlice } from './slices/appSlice';
import { chatSlice, type ChatSlice } from './slices/chatSlice';
import {
  documentModalSlice,
  type DocumentModalSlice,
} from './slices/documentModalSlice';
import {
  inboxUnreadSlice,
  type InboxUnreadSlice,
} from './slices/inboxUnreadSlice';
import { userSlice, type UserSlice } from './slices/userSlice';

interface AppStore
  extends UserSlice,
    ChatSlice,
    AppSlice,
    DocumentModalSlice,
    InboxUnreadSlice {}

/**
 * Middleware for Zustand store adding devtools and persisting to session storage
 */
const middleware = (f: StateCreator<AppStore>) =>
  devtools(
    persist(f, {
      name: 'kanbu_admin_store',
      storage: createJSONStorage(() => sessionStorage),
      partialize: state => ({
        user: state.user,
        jwt: state.jwt,
        organizationId: state.organizationId,
        chat: state.chat,
        language: state.language,
      }),
    }),
  );

export const useBoundStore = create<AppStore>()(
  middleware((set, get, api) => ({
    ...userSlice(set, get, api),
    ...chatSlice(set, get, api),
    ...createAppSlice(set, get, api),
    ...documentModalSlice(set, get, api),
    ...inboxUnreadSlice(set, get, api),
  })),
);
