import type { DocumentType } from '@kanbu/schema';
import { DocumentStatus, Role } from '@kanbu/schema/enums';
import type { PopulateParams } from '@kanbu/shared';
import {
  createFileRoute,
  type SearchSchemaInput,
} from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { Button, Separator, Tooltip } from '@utima/ui';
import { FileX2 } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DataTableRowHandler } from '@/components/dataTable/DataTable';
import { TablePage } from '@/components/page/TablePage';
import { Protected } from '@/components/protected/Protected';
import {
  DocumentsStatusSummary,
  type StatisticItem,
} from '@/features/chats/components/documentsStatusSummary/DocumentsStatusSummary';
import {
  RetryFailedButton,
  AddDocumentsModal,
  DocumentContentModal,
  useDocumentCols,
  useDocumentMutation,
} from '@/features/documents';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

const populate: PopulateParams = [
  'documentsCount',
  'parsingErrorDocumentsCount',
  'embeddingErrorDocumentsCount',
  'uploadingDocumentsCount',
  'readyDocumentsCount',
  'parsingDocumentsCount',
  'parsingAsyncDocumentsCount',
  'parsedDocumentsCount',
  'embeddingDocumentsCount',
  'doneDocumentsCount',
];

export const Route = createFileRoute('/_baseLayout/chats/$chatId/documents/')({
  component: DocumentsPage,
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId, populate }),
  validateSearch: (search: { open?: string } & SearchSchemaInput) => {
    if (search?.open) {
      return { open: search.open };
    }
  },
});

export function DocumentsPage() {
  const { chatId } = Route.useParams();
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId, populate });
  const search = Route.useSearch();
  const { t } = useTranslation(['globals', 'documents', 'chats']);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(
    null,
  );
  const columns = useDocumentCols(chatId);

  const utils = trpc.useUtils();
  const { resetAllEmbeddings } = useDocumentMutation();

  const handleQuery = useTableQuery(trpcClient.documents.findAll.query, {
    populate: ['parsingMetadata', 'shortSummary'],
    where: {
      chat: chatId,
    },
  });

  const handleRow: DataTableRowHandler<DocumentType> = (_, row) => {
    setSelectedDocument(row.original);
  };

  const statistics = useMemo<StatisticItem[]>(() => {
    return [
      // Error states
      {
        status: DocumentStatus.ParsingError,
        count: Number(data.parsingErrorDocumentsCount || 0),
        isError: true,
      },
      {
        status: DocumentStatus.EmbeddingError,
        count: Number(data.embeddingErrorDocumentsCount || 0),
        isError: true,
      },

      // Processing states
      {
        status: DocumentStatus.Parsing,
        count: Number(data.parsingDocumentsCount || 0),
        isProcessing: true,
      },
      {
        status: DocumentStatus.ParsingAsync,
        count: Number(data.parsingAsyncDocumentsCount || 0),
        isProcessing: true,
      },
      {
        status: DocumentStatus.Parsed,
        count: Number(data.parsedDocumentsCount || 0),
        isProcessing: true,
      },
      {
        status: DocumentStatus.Embedding,
        count: Number(data.embeddingDocumentsCount || 0),
        isProcessing: true,
      },

      // Done state
      {
        status: DocumentStatus.Done,
        count: Number(data.doneDocumentsCount || 0),
        isDone: true,
      },
    ];
  }, [data]);

  return (
    <>
      <TablePage
        title={t('documents:texts.manage')}
        onRow={handleRow}
        actions={
          <>
            <Protected roles={[Role.SuperAdmin]}>
              <RetryFailedButton chat={data} />
              <Tooltip title={t('documents:actions.resetAllEmbeddings')}>
                <Button
                  variant='danger'
                  outline
                  onClick={async () => {
                    await resetAllEmbeddings.mutateAsync({ chatId });
                    await utils.chats.findOne.invalidate({ id: chatId });
                  }}
                  size='icon-sm'
                >
                  <FileX2 className='size-4' />
                </Button>
              </Tooltip>
              <Separator orientation='vertical' className='h-10' />
            </Protected>
            <AddDocumentsModal
              chatId={chatId}
              // Search is a string if the user is opening the modal from the URL
              defaultOpen={search && 'open' in search}
              defaultUrl={search?.open}
            />
          </>
        }
        breadcrumbs={[
          {
            label: data.name,
            to: '/chats/$chatId/edit',
            params: { chatId },
          },
          {
            label: t('globals:routeNames.documents'),
            to: '/chats/$chatId/documents',
            params: { chatId },
          },
        ]}
        columns={columns}
        queryKey={[...getQueryKey(trpc.documents.findAll), { chatId }]}
        onQueryFn={handleQuery}
      >
        <DocumentsStatusSummary
          title={t('chats:texts.documentsStatistics')}
          statistics={statistics}
          total={Number(data.documentsCount || 0)}
        />
      </TablePage>

      <DocumentContentModal
        documentId={selectedDocument?.id}
        onClose={() => setSelectedDocument(null)}
      />
    </>
  );
}
