import type { ThreadType } from '@kanbu/schema';
import { Trans } from '@lingui/react/macro';
import { Layout } from '@utima/ui';
import { PanelLeft } from 'lucide-react';
import { memo } from 'react';

import { ThreadList } from './ThreadList';

export interface InboxSidebarProps {
  threads: ThreadType[];
  isLoading?: boolean;
  activeThreadId: string | null;
  isFetchingNextPage?: boolean;
  onFetchNextPage: () => void;
  hasNextPage: boolean;
  isUnread?: (threadId: string) => boolean;
  onSelectThread?: (threadId: string) => void;
}

export const InboxSidebar = memo(
  ({
    threads,
    isLoading,
    onSelectThread,
    activeThreadId,
    isFetchingNextPage,
    onFetchNextPage,
    hasNextPage,
    isUnread,
  }: InboxSidebarProps) => {
    return (
      <div className='flex h-full flex-col'>
        <div className='flex items-center gap-2 border-b border-gray-200 p-4'>
          <Layout.SidebarTrigger>
            <PanelLeft className='size-6 text-foreground' />
          </Layout.SidebarTrigger>
          <h2 className='hidden text-lg font-semibold sm:block'>
            <Trans>Inbox</Trans>
          </h2>
        </div>
        <div className='flex-1 overflow-auto'>
          <ThreadList
            onSelectThread={threadId => {
              onSelectThread?.(threadId);
            }}
            selectedThreadId={activeThreadId}
            threads={threads}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            onFetchNextPage={onFetchNextPage}
            hasNextPage={hasNextPage}
            isUnread={isUnread}
          />
        </div>
      </div>
    );
  },
);
