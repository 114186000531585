import { z } from 'zod';

import { ContentType } from '../../enums';

export const CommonToolNames = {
  RagResult: 'rag_result',
  RagResultOperator: 'rag_result_operator',
  UsageLimitsError: 'usageLimitsError',
} as const;

export const ragResultToolDef = z.object({
  content: z
    .string()
    .describe(
      'Main response with factual information from documents, formatted in markdown',
    ),
  sources: z
    .array(
      z.object({
        type: z.nativeEnum(ContentType).describe('Source document type'),
        fileName: z
          .string()
          .describe(
            'Prettified source document file name from metadata. Remove underscores and capitalize. Drop file extensions.',
          ),
        url: z.string().describe('Document URL from metadata'),
        page: z
          .string()
          .describe(
            'Number of page in the document on which the information was found. The page number is stored in the metadata.',
          ),
        header: z
          .string()
          .describe(
            'Legal Journal Reference Number in the exact format "č. {number}/{year} LJ" (e.g. "č. 4/2023 LJ"). Extract only if it matches this exact format.',
          )
          .optional(),
        article: z
          .string()
          .describe(
            'Article (článek) reference in legal documents in the exact format "čl. {number}" (e.g. "čl. 5"). This represents a specific section of the legal document. Extract only if it matches this exact format.',
          )
          .optional(),
      }),
    )
    .optional()
    .describe('List of source documents referenced in the response'),
  answered: z.boolean().describe('Whether the question was answered'),
});

export const ragResultToolOperator = z.object({
  content: z
    .string()
    .describe(
      'natural conversationally response to the user, use the chat history to form the response',
    ),
  operator: z
    .object({
      name: z.string().describe('Operator name'),
      email: z.string().describe('Operator email'),
      phone: z.string().describe('Operator phone'),
    })
    .optional()
    .describe('Operator contact information'),
});

export type RagResultToolDef = z.infer<typeof ragResultToolDef>;
export type RagResultToolOperator = z.infer<typeof ragResultToolOperator>;

/**
 * Manual "tool", we use this to provide better error messages to the
 * user when limits are exceeded. This tool is not really a LLM tool,
 * but we insert it manually on client side, leveraging the tool registry.
 */
export const usageLimitsErrorToolArgsSchema = z.object({
  operator: z
    .object({
      name: z.string().describe('Operator name').optional(),
      email: z.string().describe('Operator email').optional(),
      phone: z.string().describe('Operator phone').optional(),
    })
    .optional(),
});

export type UsageLimitsErrorToolArgs = z.infer<
  typeof usageLimitsErrorToolArgsSchema
>;
