import { AgentType } from '@kanbu/schema/enums';
import { THEME_PRESETS, AgentDefaults } from '@kanbu/shared';
import { useLingui } from '@lingui/react/macro';
import { Button, Label, Tooltip } from '@utima/ui';
import { useFieldState, useFormApi } from 'informed';
import { Bot, Link, Moon, Sun } from 'lucide-react';
import { memo } from 'react';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

export type ChatThemeFieldsProps = {};

export const ChatThemeFields = memo(
  function ChatThemeFields({}: ChatThemeFieldsProps) {
    const { t } = useLingui();
    const { setValue } = useFormApi();
    const typeFieldState = useFieldState('agentType');

    const { schema } = useFieldsSchema(
      [
        {
          name: 'themeConfig.preset',
          uiControl: 'hidden',
          defaultValue: AgentDefaults.defaultThemePreset,
        },
        {
          name: 'themeConfig.primary',
          label: t`Primary background`,
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.primary,
        },
        {
          name: 'themeConfig.primaryFg',
          label: t`Primary foreground`,
          showOptional: false,
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.primaryFg,
        },
        {
          name: 'chatbotConfig.systemAvatar',
          label: t`System avatar`,
          showOptional: false,
          uiProps: {
            addonAfter: <Link className='size-4' />,
          },
        },
        {
          name: 'themeConfig.logo',
          showOptional: false,
          label: t`Header logo`,
          uiProps: {
            addonAfter: <Link className='size-4' />,
          },
        },
        {
          name: 'themeConfig.logoBubble',
          showOptional: false,
          label: t`Logo bubble`,
          uiProps: {
            addonAfter: <Link className='size-4' />,
          },
        },
      ],
      [t],
    );

    const handleThemeChange = (selectedValue: string) => {
      if (selectedValue in THEME_PRESETS) {
        // Get the preset colors
        const preset =
          THEME_PRESETS[selectedValue as keyof typeof THEME_PRESETS];

        setValue('themeConfig.primary', preset.primary);
        setValue('themeConfig.primaryFg', preset.primaryFg);
        setValue('themeConfig.secondary', preset.secondary);
        setValue('themeConfig.secondaryFg', preset.secondaryFg);
        setValue('themeConfig.backgroundSecondary', preset.backgroundSecondary);
        setValue('themeConfig.background', preset.background);
        setValue('themeConfig.foreground', preset.foreground);
        setValue('themeConfig.placeholder', preset.placeholder);
        setValue('themeConfig.preset', selectedValue);
      }
    };

    // These fields should be visible only if the agent type is Chat
    if (typeFieldState.value !== AgentType.Chat) {
      return null;
    }

    return (
      <Card title={t`Theme config`}>
        <div className='flex flex-col gap-y-2'>
          <Label>{t`Style`}</Label>
          <div className='flex gap-x-2'>
            <Tooltip delayDuration={0} title={t`Dark`}>
              <Button
                variant='ghost'
                outline
                onClick={() => handleThemeChange('dark')}
              >
                <Moon />
              </Button>
            </Tooltip>
            <Tooltip delayDuration={0} title={t`Light`}>
              <Button
                variant='ghost'
                outline
                onClick={() => handleThemeChange('light')}
              >
                <Sun />
              </Button>
            </Tooltip>
            <Tooltip delayDuration={0} title='Kanbu'>
              <Button
                variant='ghost'
                outline
                onClick={() => handleThemeChange('kanbu')}
              >
                <Bot />
              </Button>
            </Tooltip>
          </div>
        </div>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
