import type { ToolCall } from '@kanbu/schema';
import { ToolType } from '@kanbu/schema/enums';
import type { StreamStateStatus } from '@kanbu/shared';
import { useMemo } from 'react';
import type { z } from 'zod';

import type { ToolDefinition, ToolRegistry } from '../../toolRegistry';

/**
 * Represents the possible states of a tool throughout its lifecycle
 */
export type ToolState =
  | 'call' // Tool call generation started (LLM defining the tool call)
  | 'call-partial' // Tool is being defined, args are streaming
  | 'call-complete' // Tool definition is complete with full args and starts it's execution
  | 'result' // Tool has been executed with result
  | 'error'; // Error occurred during any phase

/**
 * Props for the tool component. All components
 * registered to the tool registry will receive
 * these props.
 */
export type ToolComponentProps<
  ToolCallType extends Record<string, any> = any,
  Content = unknown,
  Artifact = unknown,
  Props = {},
> = Props & {
  toolCall: ToolCall<ToolCallType>;
  state: ToolState;
  content?: Content;
  artifact?: Artifact;
  error?: string;
  parseError?: z.ZodError;
};

/**
 * Props for the tool provider component.
 */
export interface ToolProviderProps {
  toolName: string;
  toolCallId: string;
  args: unknown;
  content: unknown;
  artifact?: unknown;
  status: StreamStateStatus | undefined;
  toolRegistry: ToolRegistry;
  index: number;
  error?: string;
}

function getToolState(
  status: StreamStateStatus | undefined,
  content?: unknown,
  hasError?: boolean,
): ToolState {
  // Error state takes precedence
  if (hasError) {
    return 'error';
  }

  // Handle result case separately
  if (content) {
    return 'result';
  }

  // Map streaming status to tool state
  switch (status) {
    case 'start':
      return 'call';

    case 'streaming':
      return 'call-partial';

    case 'end':
      return 'call-complete';

    case 'error':
      return 'error';

    default:
      return 'call';
  }
}

/**
 * Helper to parse errors with schema.
 */
function parseWithSchema<T>(
  value: unknown,
  schema?: z.ZodType<T>,
): { data: unknown; error?: z.ZodError } {
  if (!schema || value === undefined) {
    return { data: value };
  }

  try {
    const result = schema.safeParse(value);

    if (result.success) {
      return { data: result.data };
    }

    console.error('Schema parsing error:', result.error);

    return { data: value, error: result.error };
  } catch (e) {
    console.error('Schema parsing error:', e);

    return { data: value };
  }
}

/**
 * Tool provider, renders the tool component with
 * the given state and result.
 */
export function ToolProvider({
  toolName,
  toolCallId,
  args,
  content,
  artifact,
  error: initialError,
  status,
  toolRegistry,
  index = 0,
}: ToolProviderProps) {
  // Find the tool definition in registry
  const toolDef =
    toolRegistry[toolName] ?? ({} as ToolDefinition<any, any, any>);
  const { validate, schema, onParseError } = toolDef;

  /**
   * Parse args, content, and artifact using schemas to make;
   * sure the data are valid.
   */
  const parsedArgs = useMemo(
    () => (validate ? parseWithSchema(args, schema?.args) : { data: args }),
    [args, schema?.args, validate],
  );

  const parsedContent = useMemo(
    () =>
      validate ? parseWithSchema(content, schema?.content) : { data: content },
    [content, schema?.content, validate],
  );

  const parsedArtifact = useMemo(
    () =>
      validate
        ? parseWithSchema(artifact, schema?.artifact)
        : { data: artifact },
    [artifact, schema?.artifact, validate],
  );

  // Generate parsing error message if needed
  const parseError = useMemo(() => {
    if (!validate) {
      return;
    }

    const errors = [
      parsedArgs.error,
      parsedContent.error,
      parsedArtifact.error,
    ].filter(Boolean);

    if (errors.length === 0) {
      return;
    }

    if (onParseError) {
      return errors.map(error => onParseError!(error!)).join('\n');
    }

    // Default error message
    return 'Failed to parse tool response data';
  }, [
    validate,
    parsedArgs.error,
    parsedContent.error,
    parsedArtifact.error,
    onParseError,
  ]);

  // Create a standardized tool call object
  const toolCall: ToolCall<any> = useMemo(
    () => ({
      id: toolCallId,
      type: ToolType.Tool,
      name: toolName,
      args: parsedArgs.data,
      index,
      content: parsedContent.data,
      artifact: parsedArtifact.data,
    }),
    [
      toolCallId,
      toolName,
      parsedArgs.data,
      index,
      parsedContent.data,
      parsedArtifact.data,
    ],
  );

  if (!Object.keys(toolDef).length) {
    return null;
  }

  // Determine final state - error state takes precedence
  const hasError = Boolean(initialError || parseError);
  const finalState = getToolState(status, content, hasError);
  const error = initialError || parseError;

  const { component: ToolComponent } = toolDef;

  return (
    <ToolComponent
      toolCall={toolCall}
      state={finalState}
      content={parsedContent.data}
      artifact={parsedArtifact.data}
      error={error}
      parseError={
        parsedArgs.error || parsedContent.error || parsedArtifact.error
      }
    />
  );
}
