import type { ThreadType } from '@kanbu/schema';
import {
  SocketError,
  type ChatSocketAdminClientEvents,
  type ChatSocketAdminServerEvents,
} from '@kanbu/shared';
import { useLingui } from '@lingui/react/macro';
import { useQueryClient, type InfiniteData } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { toast } from '@utima/ui';
import { useEffect, useState, useCallback } from 'react';
import { io, type Socket } from 'socket.io-client';

import { AppSettings } from '@/constants/AppSettings';
import { useUser } from '@/hooks';
import { trpc } from '@/services/trpc';

const SOCKET_URL = `${new URL(AppSettings.coreAiUrl).origin}/chat/admin`;

// Create socket instance, there should be only one per app instance
const socket: Socket<ChatSocketAdminServerEvents, ChatSocketAdminClientEvents> =
  io(SOCKET_URL, {
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: 3,
    timeout: 10000,
    autoConnect: false,
  });

/**
 * Hook to handle the socket connection and actions for the
 * admin-specific side of the chat socket.
 *
 * this usually handles thread invalidations when new messages are received,
 * responding to takeover requests and handling invalidation for connection statuses.
 */
export function useInboxSocket() {
  const { t } = useLingui();
  const queryClient = useQueryClient();
  const [isConnected, setIsConnected] = useState(false);
  const [hasConnectionFailed, setHasConnectionFailed] = useState(false);
  const { user, jwt } = useUser();
  const utils = trpc.useUtils();

  /**
   * Appends new threads at the top of the current thread list
   */
  const handleNewThread: ChatSocketAdminServerEvents['newThread'] = useCallback(
    thread => {
      queryClient.setQueryData(
        getQueryKey(trpc.threads.findAll),
        (prev: unknown) => {
          const prevData = prev as InfiniteData<{ items: ThreadType[] }>;
          const { pages } = prevData;
          const [firstPage, ...restPages] = pages;

          // Check if new thread is not already in the state
          const isNewThread = !firstPage.items.some(
            item => item.id === thread.id,
          );

          // Preppend to the state
          if (isNewThread) {
            return {
              ...prevData,
              pages: [
                {
                  ...firstPage,
                  items: [thread, ...firstPage.items],
                },
                ...restPages,
              ],
            };
          }

          return prev;
        },
      );
    },
    [queryClient],
  );

  /**
   * Memoize the thread updated handler to prevent unnecessary re-renders
   */
  const handleThreadUpdated: ChatSocketAdminServerEvents['threadUpdated'] =
    useCallback(
      async data => {
        await utils.threads.findOne.invalidate({ id: data.threadId });

        /**
         * Update the thread status in the thread list when any new activity
         * is detected on the thread.
         */
        queryClient.setQueryData(
          getQueryKey(trpc.threads.findAll),
          (prev: unknown) => {
            const { threadId, data: threadData } = data;

            // Update infinite query
            if (prev && typeof prev === 'object' && 'pages' in prev) {
              const prevData = prev as InfiniteData<{ items: ThreadType[] }>;
              const newData = prevData.pages.map(page => ({
                ...page,
                items: page.items.map(item =>
                  item.id === threadId
                    ? {
                        ...item,
                        ...threadData,
                      }
                    : item,
                ),
              }));

              return {
                ...prev,
                pages: newData,
              };
            }

            return prev;
          },
        );
      },
      [utils.threads.findOne, queryClient],
    );

  /**
   * Memoize the takeover resolved handler to prevent unnecessary re-renders
   */
  const handleTakeoverResolved = useCallback(
    async (threadId: string) => {
      await Promise.all([
        utils.threads.getPendingTakeoverRequest.invalidate({ id: threadId }),
        utils.threads.findOne.invalidate({ id: threadId }),
      ]);
    },
    [utils.threads.getPendingTakeoverRequest, utils.threads.findOne],
  );

  /**
   * Initialize socket connection when user is logged in.
   */
  useEffect(() => {
    if (jwt) {
      socket.auth = { adminToken: jwt };
      socket.connect();
      setIsConnected(true);
    }

    return () => {
      socket.connected && socket.disconnect();
      setIsConnected(false);
    };
  }, [jwt]);

  /**
   * Join the admin rooms.
   */
  useEffect(() => {
    if (!isConnected) {
      return;
    }

    // Join admin room
    socket.emit('joinAdminRoom');

    // Cleanup on unmount
    return () => {
      socket.emit('leaveAdminRoom');
    };
  }, [isConnected]);

  /**
   * Initialize socket connection
   */
  useEffect(() => {
    if (!user || !isConnected) {
      return;
    }

    /**
     * Define event handlers
     */
    const handleConnect = () => {
      setHasConnectionFailed(false);
    };

    const handleDisconnect = () => {
      setHasConnectionFailed(false);
    };

    const handleConnectError = (error: Error) => {
      console.error('Socket connection error:', error);
      setHasConnectionFailed(true);
    };

    const handleError = (error: unknown) => {
      const socketError = SocketError.fromError(error);

      if (socketError.type === 'unauthorized') {
        toast.error(
          t`We were unable to authenticate your request. Please try again later.`,
        );
      } else {
        toast.error(
          t`We're sorry, but we're currently experiencing issues with our operators. Please try again later.`,
        );
      }

      console.error('Socket connection error:', error);
    };

    /**
     * Listen on socket events
     */
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    socket.on('connect_error', handleConnectError);
    socket.on('error', handleError);
    socket.on('threadUpdated', handleThreadUpdated);
    socket.on('newThread', handleNewThread);
    socket.on('takeoverResolved', handleTakeoverResolved);

    /**
     * Cleanup the socket connection
     */
    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
      socket.off('connect_error', handleConnectError);
      socket.off('error', handleError);
      socket.off('threadUpdated', handleThreadUpdated);
      socket.off('newThread', handleNewThread);
      socket.off('takeoverResolved', handleTakeoverResolved);
    };
  }, [
    handleNewThread,
    handleTakeoverResolved,
    handleThreadUpdated,
    isConnected,
    t,
    user,
  ]);

  return {
    socket,
    isConnected,
    hasConnectionFailed,
  };
}
