import type { ThreadType } from '@kanbu/schema';
import { ThreadMode } from '@kanbu/schema/enums';
import { formatUtils, DateFormat } from '@kanbu/shared';
import { Trans, useLingui } from '@lingui/react/macro';
import { Badge, Button, cn } from '@utima/ui';
import { X } from 'lucide-react';

import { DisplayValue } from '@/components/displayValue/DisplayValue';

export interface ThreadMetadataProps {
  thread: ThreadType;
  className?: string;
  onClose: () => void;
}

export function ThreadMetadata({
  thread,
  className,
  onClose,
}: ThreadMetadataProps) {
  const { t } = useLingui();

  return (
    <div
      className={cn(
        'w-[300px] sm:block hidden border-l border-gray-200 bg-white p-4 h-full overflow-auto',
        className,
      )}
    >
      <div className='mb-4 flex items-center justify-between'>
        <h3 className='text-lg font-semibold'>
          <Trans>Thread Information</Trans>
        </h3>
        <Button
          variant='ghost'
          size='icon-sm'
          className='size-8 p-0'
          onClick={onClose}
        >
          <X className='size-4' />
        </Button>
      </div>

      <div className='space-y-4'>
        <DisplayValue label={t`Thread ID`} className='text-sm'>
          {thread.id}
        </DisplayValue>

        <DisplayValue label={t`Created at`} className='text-sm'>
          {formatUtils.date(
            thread.createdAt,
            DateFormat.DateTimeNumericSeconds,
          )}
        </DisplayValue>

        <DisplayValue label={t`Updated at`} className='text-sm'>
          {formatUtils.date(
            thread.updatedAt,
            DateFormat.DateTimeNumericSeconds,
          )}
        </DisplayValue>

        <DisplayValue label={t`Last user message at`} className='text-sm'>
          {thread.lastUserMessageAt
            ? formatUtils.date(
                thread.lastUserMessageAt,
                DateFormat.DateTimeNumericSeconds,
              )
            : '-'}
        </DisplayValue>

        <DisplayValue label={t`Last active`} className='text-sm'>
          {thread.lastActiveAt
            ? formatUtils.date(
                thread.lastActiveAt,
                DateFormat.DateTimeNumericSeconds,
              )
            : '-'}
        </DisplayValue>

        <DisplayValue label={t`Conversation mode`} className='text-sm'>
          <Badge
            size='sm'
            variant={thread.mode === ThreadMode.AI ? 'secondary' : 'primary'}
          >
            {thread.mode === ThreadMode.AI ? 'AI' : 'Human'}
          </Badge>
        </DisplayValue>

        <DisplayValue label={t`Message Count`} className='text-sm'>
          {thread.messages?.length || 0}
        </DisplayValue>
      </div>
    </div>
  );
}
