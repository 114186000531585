import type { FeedType } from '@kanbu/schema';
import { useLingui } from '@lingui/react/macro';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';

import { TablePage } from '@/components/page/TablePage';
import { useFeedsCols } from '@/features/feeds/useFeedsCols';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chats/$chatId/feeds/')({
  component: FeedsPage,
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId }),
});

export default function FeedsPage() {
  const { chatId } = Route.useParams();
  const { t } = useLingui();
  const columns = useFeedsCols();
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });

  const handleQuery = useTableQuery(trpcClient.feeds.findAll.query, {
    where: {
      chat: chatId,
    },
    populate: ['productsCount'],
  });

  const handleRow = useDetailRowHandler<FeedType>(row => ({
    to: '/chats/$chatId/feeds/$feedId/edit',
    params: {
      chatId,
      feedId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t`Manage Feeds`}
      breadcrumbs={[
        {
          label: data.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t`Feeds`,
          to: '/chats/$chatId/feeds',
          params: { chatId },
        },
      ]}
      createButton={{
        to: '/chats/$chatId/feeds/create',
        params: { chatId },
        label: t`Create Feed`,
      }}
      columns={columns}
      queryKey={[...getQueryKey(trpc.feeds.findAll), { chatId }]}
      onQueryFn={handleQuery}
      onRow={handleRow}
    />
  );
}
