import { useEffect } from 'react';

import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

/**
 * This hook should be placed in every base file after auth.
 * It makes sure the user data are not stale and it refreshes
 * them every X seconds.
 */
export function useUserMeData(refreshInterval = 15_000 * 60) {
  const setUser = useBoundStore(state => state.setUser);
  const userQuery = trpc.users.me.useQuery(undefined, {
    staleTime: refreshInterval,
  });

  useEffect(() => {
    if (!userQuery.data) {
      return;
    }

    setUser(userQuery.data);
  }, [setUser, userQuery.data]);
}
