import { useCallback, useEffect, useRef } from 'react';

import { trpc, trpcClient } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export function useInboxUnread(isQueryEnabled = false) {
  const { unreadThreadIds, setUnreadThreadIds } = useBoundStore(state => ({
    unreadThreadIds: state.unreadThreadIds,
    setUnreadThreadIds: state.setUnreadThreadIds,
  }));

  const { data: fetchedUnreadThreadIds, refetch } =
    trpc.threads.getUnreadThreads.useQuery(undefined, {
      refetchInterval: 30000,
      enabled: isQueryEnabled,
    });

  const previousUnreadThreadIds = useRef(fetchedUnreadThreadIds);

  useEffect(() => {
    if (
      isQueryEnabled &&
      fetchedUnreadThreadIds !== previousUnreadThreadIds.current
    ) {
      setUnreadThreadIds(fetchedUnreadThreadIds ?? []);
      previousUnreadThreadIds.current = fetchedUnreadThreadIds;
    }
  }, [isQueryEnabled, setUnreadThreadIds, fetchedUnreadThreadIds]);

  const isUnread = useCallback(
    (threadId: string) => {
      return !!unreadThreadIds?.includes(threadId);
    },
    [unreadThreadIds],
  );

  const markAsRead = useCallback(
    async (threadId: string) => {
      setUnreadThreadIds(unreadThreadIds?.filter(id => id !== threadId) ?? []);
      await trpcClient.threads.removeUnreadThread.query({ id: threadId });
    },
    [unreadThreadIds, setUnreadThreadIds],
  );

  return {
    markAsRead,
    isUnread,
    refetchUnread: refetch,
    unreadCount: unreadThreadIds?.length ?? 0,
  };
}
