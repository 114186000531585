import type { ThreadType } from '@kanbu/schema';
import { formatUtils, DateFormat } from '@kanbu/shared';
import { Trans } from '@lingui/react/macro';
import { cn } from '@utima/ui';
import { memo } from 'react';

import { OnlineStatus } from './OnlineStatus';

export interface ThreadItemProps {
  onClick: () => void;
  active?: boolean;
  thread: ThreadType;
  isUnread?: boolean;
}

export const ThreadItem = memo(
  ({ onClick, active, thread, isUnread }: ThreadItemProps) => (
    <button
      className={cn(
        'w-full cursor-pointer p-4 text-left hover:bg-primary/15 transition-colors',
        active && 'bg-primary/30',
        isUnread && 'font-bold bg-primary/10',
      )}
      onClick={onClick}
    >
      <div className='flex flex-col-reverse justify-between truncate md:flex-row md:items-center md:gap-2'>
        <span
          className={cn(
            'inline-flex items-center gap-2 text-gray-900 font-medium',
            isUnread && 'font-bold',
          )}
        >
          <OnlineStatus
            showWhenOffline={false}
            withText={false}
            lastActiveAt={thread.lastActiveAt}
          />{' '}
          {thread.title || <Trans>Untitled</Trans>}
        </span>
        <span className='grow-0 text-xs text-muted-fg'>
          {formatUtils.date(
            thread.lastUserMessageAt ?? thread.createdAt,
            DateFormat.DateTimeNumeric,
          )}
        </span>
      </div>
      <p className='mt-1 hidden truncate text-xs text-gray-600 md:block'>
        {thread.chatUser as unknown as string}
      </p>
    </button>
  ),
);
