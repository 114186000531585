import type { ChatMessageType, ThreadType } from '@kanbu/schema';
import { ChatRole, ThreadMode } from '@kanbu/schema/enums';
import {
  type ChatSocketServerEvents,
  type ChatSocketClientEvents,
  SocketError,
} from '@kanbu/shared';
import { createMessage } from '@kanbu/shared-ui';
import { useLingui } from '@lingui/react/macro';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { toast } from '@utima/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { io, type Socket } from 'socket.io-client';

import { AppSettings } from '@/constants/AppSettings';
import { getOnlineStatus, useUser } from '@/hooks';
import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

// Create socket instance, there should be only one per app instance
const SOCKET_URL = `${new URL(AppSettings.coreAiUrl).origin}/chat`;
const socket: Socket<ChatSocketServerEvents, ChatSocketClientEvents> = io(
  SOCKET_URL,
  {
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: 3,
    timeout: 10000,
    autoConnect: false,
  },
);

const populate = [
  'messages',
  'messages.user',
  'messages.feedback',
  'chat.chatbotConfig',
];

const fields = [
  '*',
  'chat.agentName',
  'chat.chatbotConfig',
  'messages.*',
  'messages.user',
  'messages.feedback',
];

/**
 * Hook to handle socket.io connection for a chat thread and
 * proper state updates when new messages are received.
 */
export function useInboxThread(
  threadId: string | undefined | null,
  refetchInterval = 60000,
) {
  const { t } = useLingui();
  const { jwt } = useUser();
  const [isConnected, setIsConnected] = useState(false);
  const queryClient = useQueryClient();
  const [hasConnectionFailed, setHasConnectionFailed] = useState(false);
  const user = useBoundStore(state => state.user);

  const queryKey = useMemo(
    () =>
      getQueryKey(trpc.threads.findOne, {
        id: threadId!,
        populate,
        fields,
      }),
    [threadId],
  );

  /**
   * Fetch the thread details from the database. We also use this
   * to manually populate with threads when
   */
  const threadQuery = trpc.threads.findOne.useQuery(
    {
      id: threadId!,
      populate,
      fields,
    },
    {
      enabled: !!threadId,
      notifyOnChangeProps: 'all',
      refetchOnMount: 'always',
      refetchOnWindowFocus: 'always',
      refetchInterval,
    },
  );

  /**
   * Setup the new message handler which submits the message
   * to the socket.io server.
   */
  const handleSubmit = useCallback(
    async (message: string) => {
      if (!socket.connected || !threadId) {
        return;
      }

      /**
       * When user is offline, we double check if he didn't log in
       * while sending this message, and is in AI mode for a chance.
       *
       * This would otherwise allow us to send a new message, which would
       * result in conflicting messages from AI.
       */
      if (getOnlineStatus(threadQuery?.data?.lastActiveAt) === 'offline') {
        // Fetch latest status
        const { data: newThread } = await threadQuery.refetch();

        /**
         * Check if user is online and in AI mode, if so, don't
         * send the message to the server.
         */
        if (
          getOnlineStatus(newThread?.lastActiveAt) === 'online' &&
          newThread?.mode === ThreadMode.AI
        ) {
          console.warn(
            'User is online and in AI mode, skipping message submission.',
          );

          return;
        }
      }

      const newMessage = createMessage({
        message,
        role: ChatRole.Administrator,
        user: user ?? undefined,
      });

      // Update local state immediately
      queryClient.setQueryData(queryKey, (prev: ThreadType) => ({
        ...(prev ?? []),
        messages: [...(prev?.messages ?? []), newMessage],
      }));

      // Send to server
      socket.emit('message', newMessage);
    },
    [threadId, threadQuery, user, queryClient, queryKey],
  );

  /**
   * Initialize socket connection.
   */
  useEffect(() => {
    if (jwt) {
      socket.auth = { adminToken: jwt };
      socket.connect();
      setIsConnected(true);
    }

    return () => {
      setIsConnected(false);
      socket.connected && socket.disconnect();
    };
  }, [jwt]);

  /**
   * Handle re-joining thread rooms when threadId changes.
   */
  useEffect(() => {
    if (!isConnected) {
      return;
    }

    // Leave previous thread
    socket.emit('leaveThread');

    // Join new thread
    if (threadId) {
      socket.emit('joinThread', threadId);
    }

    // Cleanup on unmount
    return () => {
      socket.emit('leaveThread');
    };
  }, [threadId, isConnected]);

  /**
   * Initialize socket.io connection
   */
  useEffect(() => {
    if (!threadId || !isConnected) {
      return;
    }

    /**
     * Define event handlers
     */
    const handleConnect = () => {
      setHasConnectionFailed(false);
    };

    const handleDisconnect = () => {
      setHasConnectionFailed(false);
    };

    const handleConnectError = (error: Error) => {
      console.error('Socket connection error:', error);
      setHasConnectionFailed(true);
    };

    const handleError = (error: unknown) => {
      console.log('handleError', error);
      const socketError = SocketError.fromError(error);

      if (socketError.type === 'unauthorized') {
        toast.error(
          t`We were unable to authenticate your request. Please try again later.`,
        );
      } else {
        toast.error(
          t`We're sorry, but we're currently experiencing issues with our operators. Please try again later.`,
        );
      }

      console.error('Socket connection error:', error);
    };

    const handleMessage = (message: ChatMessageType) => {
      queryClient.setQueryData(queryKey, (prev: ThreadType) => ({
        ...(prev ?? []),
        messages: [...(prev?.messages ?? []), message],
      }));
    };

    /**
     * Listen on socket events
     */
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    socket.on('error', handleError);
    socket.on('connect_error', handleConnectError);
    socket.on('message', handleMessage);

    /**
     * Cleanup the socket connection
     */
    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
      socket.off('error', handleError);
      socket.off('connect_error', handleConnectError);
      socket.off('message', handleMessage);
    };
  }, [queryClient, queryKey, t, threadId, isConnected]);

  /**
   * Sort messages by id, since we use uuid v7 which is time based.
   */
  const thread = useMemo(() => {
    if (!threadQuery.data) {
      return;
    }

    return {
      ...threadQuery.data,
      messages: threadQuery.data.messages.sort((a, b) =>
        a.id.localeCompare(b.id),
      ),
    } satisfies ThreadType;
  }, [threadQuery.data]);

  return {
    thread,
    isLoading: threadQuery.isLoading,
    hasConnectionFailed,
    handleSubmit,
  };
}
