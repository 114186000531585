import { z } from 'zod';

import { ProductAvailabilityStatus } from '../../../enums';

export const ShopProductToolNames = {
  GetOperator: 'getOperator',
  GetShopProducts: 'getShopProducts',
  GetShopProduct: 'getShopProductInformation',
  GetShopCategories: 'getShopCategories',
  GetShopDeliveryOptions: 'getShopDeliveryOptions',
  GetShopProductAvailability: 'getShopProductAvailability',
  GetShopProductGifts: 'getShopProductGifts',
  GetShopProductAccessories: 'getShopProductAccessories',
  CompareShopProducts: 'compareShopProducts',
} as const;

/**
 * Schema for preview of a product. Used in arrays,
 * carousels, get accessories, etc.
 */
export const shopProductPreviewSchema = z.object({
  id: z.string(),
  name: z.string(),
  title: z.string().nullish(),
  price: z.number(),
  imageUrl: z.string().nullish(),
  url: z.string(),
  description: z.string().nullish(),
});

export type ShopProductPreview = z.infer<typeof shopProductPreviewSchema>;

/**
 * GetShopProducts tool contracts
 */
export const getShopProductsArgsSchema = z.object({
  query: z
    .string()
    .optional()
    .describe(
      'Full, unmodified search query from the user. Do not simplify, shorten, or modify the search term as the entire query is used for semantic and keyword search. Example: "Supplements, with B or C vitamins" should be passed as-is.',
    ),
  categoryIds: z
    .array(z.string())
    .optional()
    .describe(
      "Optional array of category IDs to filter by. Use only when user specifically mentions categories or when refining broad searches. Caution: Using categoryIds can potentially limit results that might semantically match the query but aren't in specified categories. Prefer query-only for highly specific product searches.",
    ),
  minPrice: z
    .number()
    .optional()
    .describe(
      'Minimum price filter. Only include when user explicitly mentions a price range or minimum price. Format: raw number without currency symbol (e.g., 500 not 500 Kč).',
    ),
  maxPrice: z
    .number()
    .optional()
    .describe(
      'Maximum price filter. Only include when user explicitly mentions a price range or maximum price. Format: raw number without currency symbol (e.g., 1000 not 1000 Kč).',
    ),
  isOnSale: z
    .boolean()
    .optional()
    .describe(
      'Filter by products on sale. Set to true only when user explicitly asks for discounted/sale items. Do not set this parameter unless specifically requested.',
    ),
  // TODO - add sorting, however sorting can't be used with hybrid search, so only filter queries can be applied on certain categories etc. these use cases have to be handled.
  // sortBy: z
  //   .enum(['price', 'name', 'brand'])
  //   .optional()
  //   .describe(
  //     'Sort results by this field. Use price for price-sensitive queries, name for alphabetical listings, and brand when comparing across manufacturers. Use only when user explicitly asks for sorting.',
  //   ),
  // sortOrder: z
  //   .enum(['asc', 'desc'])
  //   .optional()
  //   .describe(
  //     'Sort direction. Use "asc" for lowest-to-highest price or A-Z naming. Use "desc" for highest-to-lowest price or Z-A naming. Default is "asc" for name/brand and "asc" for price if unspecified.',
  //   ),
  // TODO
  // attributes: z
  //   .array(
  //     z.object({
  //       name: z.string(),
  //       value: z.string(),
  //     }),
  //   )
  //   .optional()
  //   .describe(
  //     'Product attributes to filter by (e.g., {name: "color", value: "red"}). Only use when user specifically mentions attribute values. Get valid attributes from GetSuggestedShopFilters first.',
  //   ),
  limit: z
    .number()
    .optional()
    .default(5)
    .describe(
      'Maximum number of products to return. Default is 10. Only change when user asks for more or fewer results.',
    ),
});

export const getShopProductsContentSchema = z.string();
export const getShopProductsArtifactSchema = z
  .object({
    totalCount: z.number(),
    hasMore: z.boolean(),
    products: z.array(shopProductPreviewSchema),
    categories: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
          displayName: z.string().nullish(),
        }),
      )
      .optional(),
  })
  .optional();

export type GetShopProductsArgs = z.infer<typeof getShopProductsArgsSchema>;
export type GetShopProductsContent = z.infer<
  typeof getShopProductsContentSchema
>;
export type GetShopProductsArtifact = z.infer<
  typeof getShopProductsArtifactSchema
>;

/**
 * GetProductInformation tool contracts
 */
export const getShopProductArgsSchema = z.object({
  productId: z.string().describe('The product ID to get information about'),
});

export const getShopProductContentSchema = z.string();
export const getShopProductArtifactSchema = z.object({
  id: z.string(),
  name: z.string(),
  title: z.string().nullish(),
  price: z.number(),
  brand: z.string().nullish(),
  description: z.string().nullish(),
  url: z.string(),
  imageUrl: z.string().nullish(),
  images: z.array(z.string()).nullish(),
  categories: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        displayName: z.string().nullish(),
      }),
    )
    .nullish(),
  gifts: z.array(z.string()).nullish(),
  accessories: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        title: z.string().nullish(),
        url: z.string(),
      }),
    )
    .nullish(),
  attributes: z.array(
    z.object({
      id: z.string(),
      value: z.string(),
      attribute: z.object({
        id: z.string(),
        displayName: z.string().nullish(),
        name: z.string(),
      }),
    }),
  ),
});

export type GetShopProductArgs = z.infer<typeof getShopProductArgsSchema>;
export type GetShopProductContent = z.infer<typeof getShopProductContentSchema>;
export type GetShopProductArtifact = z.infer<
  typeof getShopProductArtifactSchema
>;

/**
 * GetShopCategories tool contracts
 */
export const getShopCategoriesArgsSchema = z.object({
  parentCategoryId: z
    .string()
    .optional()
    .describe('Optional parent category ID to get subcategories.'),
});

export const shopCategoryPreviewSchema = z.object({
  id: z.string(),
  name: z.string(),
  displayName: z.string().nullish(),
});

export const getShopCategoriesContentSchema = z.string();
export const getShopCategoriesArtifactSchema = z.object({
  categories: z.array(
    shopCategoryPreviewSchema.merge(
      z.object({
        children: z.array(shopCategoryPreviewSchema).nullish(),
      }),
    ),
  ),
});

export type ShopCategoryPreview = z.infer<typeof shopCategoryPreviewSchema>;
export type GetShopCategoriesArgs = z.infer<typeof getShopCategoriesArgsSchema>;
export type GetShopCategoriesContent = z.infer<
  typeof getShopCategoriesContentSchema
>;
export type GetShopCategoriesArtifact = z.infer<
  typeof getShopCategoriesArtifactSchema
>;

/**
 * GetShopDeliveryOptions tool contracts
 */
export const getShopDeliveryOptionsArgsSchema = z.object({
  productId: z.string().describe('The product ID to get delivery options for'),
});

export const getShopDeliveryOptionsContentSchema = z.string();
export const getShopDeliveryOptionsArtifactSchema = z.object({
  productId: z.string(),
  deliveryDate: z.string().nullish(),
  deliveryOptions: z
    .array(
      z.object({
        carrierId: z.string(),
        price: z.number().optional(),
        priceCod: z.number().optional(),
      }),
    )
    .nullish(),
});

export type GetShopDeliveryOptionsArgs = z.infer<
  typeof getShopDeliveryOptionsArgsSchema
>;
export type GetShopDeliveryOptionsContent = z.infer<
  typeof getShopDeliveryOptionsContentSchema
>;
export type GetShopDeliveryOptionsArtifact = z.infer<
  typeof getShopDeliveryOptionsArtifactSchema
>;

/**
 * GetShopProductAvailability tool contracts
 */
export const getShopProductAvailabilityArgsSchema = z.object({
  productId: z.string().describe('The product ID to get delivery options for'),
});

export const getShopProductAvailabilityContentSchema = z.string();
export const getShopProductAvailabilityArtifactSchema = z.object({
  productId: z.string(),
  stockQuantity: z.number().int().nullish(),
  availability: z.nativeEnum(ProductAvailabilityStatus).nullish(),
});

export type GetShopProductAvailabilityArgs = z.infer<
  typeof getShopProductAvailabilityArgsSchema
>;
export type GetShopProductAvailabilityContent = z.infer<
  typeof getShopProductAvailabilityContentSchema
>;
export type GetShopProductAvailabilityArtifact = z.infer<
  typeof getShopProductAvailabilityArtifactSchema
>;

/**
 * GetShopProductGifts tool contracts
 */
export const getShopProductGiftsArgsSchema = z.object({
  productId: z.string().describe('The product ID to get gifts for'),
});

export const getShopProductGiftsContentSchema = z.string();
export const getShopProductGiftsArtifactSchema = z.object({
  productId: z.string(),
  gifts: z.array(z.string()),
});

export type GetShopProductGiftsArgs = z.infer<
  typeof getShopProductGiftsArgsSchema
>;
export type GetShopProductGiftsContent = z.infer<
  typeof getShopProductGiftsContentSchema
>;
export type GetShopProductGiftsArtifact = z.infer<
  typeof getShopProductGiftsArtifactSchema
>;

/**
 * GetShopProductAccessories tool contracts
 */
export const getShopProductAccessoriesArgsSchema = z.object({
  productId: z.string().describe('The product ID to get accessories for'),
});

export const getShopProductAccessoriesContentSchema = z.string();
export const getShopProductAccessoriesArtifactSchema = z.object({
  productId: z.string(),
  accessories: z.array(shopProductPreviewSchema),
});

export type GetShopProductAccessoriesArgs = z.infer<
  typeof getShopProductAccessoriesArgsSchema
>;
export type GetShopProductAccessoriesContent = z.infer<
  typeof getShopProductAccessoriesContentSchema
>;
export type GetShopProductAccessoriesArtifact = z.infer<
  typeof getShopProductAccessoriesArtifactSchema
>;

/**
 * GetOperator tool contracts
 */
export const getOperatorContentSchema = z.string();
export const getOperatorArtifactSchema = z.object({
  operator: z
    .object({
      id: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      email: z.string().nullish(),
      phone: z.string().nullish(),
    })
    .nullish(),
});

export type GetOperatorArtifact = z.infer<typeof getOperatorArtifactSchema>;
export type GetOperatorContent = z.infer<typeof getOperatorContentSchema>;

/**
 * Shop product comparison tool contracts
 */
export const compareShopProductsArgsSchema = z.object({
  productIds: z
    .array(z.string())
    .min(2)
    .max(5)
    .describe('Array of product IDs to compare. Min 2, max 5 products.'),
  highlightDifferences: z
    .boolean()
    .optional()
    .default(true)
    .describe(
      'If true, the comparison will focus on highlighting differences between products.',
    ),
});

export const compareShopProductsContentSchema = z.string();
export const compareShopProductsArtifactSchema = z.object({
  products: z.array(getShopProductArtifactSchema),
  comparisonMatrix: z.array(
    z.object({
      attribute: z.string().describe('Name of the compared attribute'),
      displayName: z
        .string()
        .nullish()
        .describe('Human-readable name of the attribute'),
      values: z.array(
        z.object({
          productId: z.string(),
          value: z.string().nullish(),
          isDifferent: z.boolean().optional(),
        }),
      ),
      isDifferent: z.boolean().optional(),
    }),
  ),
});

export type CompareShopProductsArgs = z.infer<
  typeof compareShopProductsArgsSchema
>;
export type CompareShopProductsContent = z.infer<
  typeof compareShopProductsContentSchema
>;
export type CompareShopProductsArtifact = z.infer<
  typeof compareShopProductsArtifactSchema
>;
