import { motion, AnimatePresence } from 'framer-motion';
import { memo } from 'react';

import { useTimeAgo } from '../../hooks/useTimeAgo';
import type { ToolRegistry } from '../../toolRegistry';
import type { ChatMessageItem, MessagesGroup } from '../../types';
import { Markdown } from '../Markdown';
import { ToolProvider } from '../tools/ToolProvider';
import { type SharedMessageProps, Message } from '../ui';
import { UserAvatar } from '../UserAvatar';

export interface AssistantMessageProps extends SharedMessageProps {
  name?: string;
  avatar?: string;
  toolRegistry?: ToolRegistry;
  isFetching?: boolean;
  insert?: (message: string) => void;
  shouldRenderFeedback?: (group: MessagesGroup) => boolean;
  FeedbackComponent?: React.ComponentType<{
    message: ChatMessageItem;
  }>;
}

export const AssistantMessage = memo(
  ({
    group,
    name,
    avatar,
    toolRegistry,
    position = 'left',
    variant = 'secondary',
    FeedbackComponent,
    shouldRenderFeedback,
    isFetching,
  }: AssistantMessageProps) => {
    const { messages, createdAt, isLast, isInitial } = group;
    const timeAgo = useTimeAgo(createdAt);
    const firstMessage = messages.at(-1);

    return (
      <Message.Root variant={variant} position={position}>
        <Message.Header>{name}</Message.Header>
        <Message.Wrapper>
          <Message.Sidebar>
            <Message.Avatar>
              <UserAvatar src={avatar} />
            </Message.Avatar>
          </Message.Sidebar>
          <Message.Content>
            {messages.map(message => {
              // Show loading indicator
              // FIXME not a good solution
              if (message.status === 'start') {
                return (
                  <Message.BubbleGroup key={message.id}>
                    <Message.Typing />
                  </Message.BubbleGroup>
                );
              }

              // Render message content when defined
              if (message.content) {
                return (
                  <Message.BubbleGroup key={message.id}>
                    <Message.Bubble>
                      <Markdown>{message.content}</Markdown>
                    </Message.Bubble>
                  </Message.BubbleGroup>
                );
              }

              // Don't continue with tool rendering if there is no registry
              if (!toolRegistry) {
                return null;
              }

              // Render tool calls when defined
              if (message.toolCalls) {
                return message.toolCalls.map(toolCall => {
                  /**
                   * FIXME the text tool is deprecated, it should not be used with new architecture,
                   * however old conversations have to be migrated before we can remove it.
                   */
                  if (toolCall.type === 'text') {
                    return (
                      <Message.BubbleGroup key={message.id}>
                        <Message.Bubble>
                          <Markdown>{toolCall.content}</Markdown>
                        </Message.Bubble>
                      </Message.BubbleGroup>
                    );
                  }

                  if (toolCall.type === 'tool') {
                    return (
                      <ToolProvider
                        status={message.status}
                        args={toolCall.args}
                        toolName={toolCall.name}
                        index={toolCall.index}
                        content={toolCall.content}
                        artifact={toolCall.artifact}
                        toolCallId={toolCall.id}
                        toolRegistry={toolRegistry}
                        key={toolCall.id}
                      />
                    );
                  }
                });
              }

              return null;
            })}

            <AnimatePresence mode='wait'>
              {FeedbackComponent &&
                firstMessage &&
                /**
                 * For the last group, in all chat history, we don't show the feedback
                 * until the stream is finished.
                 */
                !(isFetching && isLast) &&
                (shouldRenderFeedback?.(group) ?? true) && (
                  <motion.div
                    initial={
                      !isFetching && !isInitial && isLast
                        ? { opacity: 0, y: 2 }
                        : false
                    }
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      delay: 0.2,
                      duration: 0.2,
                    }}
                  >
                    <FeedbackComponent message={firstMessage} />
                  </motion.div>
                )}
            </AnimatePresence>
          </Message.Content>
        </Message.Wrapper>
        <Message.Footer>{timeAgo}</Message.Footer>
      </Message.Root>
    );
  },
);
