import { trpc } from '@/services/trpc';

export function useFeedMutation() {
  const utils = trpc.useUtils();

  const create = trpc.feeds.create.useMutation({
    onSuccess: async () => utils.feeds.findAll.invalidate(),
  });

  const update = trpc.feeds.update.useMutation({
    onSuccess: async (data, { id }) =>
      Promise.all([
        utils.feeds.findAll.invalidate(),
        utils.feeds.findOne.invalidate({ id }),
      ]),
  });

  const remove = trpc.feeds.delete.useMutation({
    onSuccess: async (data, { id }) => utils.feeds.findAll.invalidate(),
  });

  return {
    create,
    update,
    remove,
  };
}
