import {
  type ShopCategoryPreview,
  getShopCategoriesArgsSchema,
  getShopCategoriesArtifactSchema,
  getShopCategoriesContentSchema,
} from '@kanbu/schema/contracts';
import { plural } from '@lingui/core/macro';
import { Trans, useLingui } from '@lingui/react/macro';
import { Button } from '@utima/ui';
import { motion } from 'framer-motion';
import { Search } from 'lucide-react';
import { useState } from 'react';

import { createToolComponent } from '../../../toolRegistry';
import { useChatRenderer } from '../../ChatRendererProvider';
import { ToolError } from '../components/ToolError';
import { ToolStatus } from '../components/ToolStatus';

export const GetShopCategoriesTool = createToolComponent(
  {
    schema: {
      args: getShopCategoriesArgsSchema,
      artifact: getShopCategoriesArtifactSchema,
      content: getShopCategoriesContentSchema,
    },
    validate: false,
  },
  ({ state, error, artifact }) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useLingui();
    const { append } = useChatRenderer();

    const { categories } = artifact ?? {};
    const flatCategories = categories
      ?.flatMap(c => c.children)
      .filter(Boolean) as ShopCategoryPreview[];
    const filteredCategories = expanded
      ? flatCategories
      : flatCategories?.slice(0, 5);

    switch (state) {
      case 'call':
        return (
          <ToolStatus loading>
            <Trans>Looking for categories...</Trans>
          </ToolStatus>
        );

      case 'call-partial':
      case 'call-complete':
        return (
          <ToolStatus loading>
            <Trans>Fetching categories...</Trans>
          </ToolStatus>
        );

      case 'error':
        return (
          <ToolError
            message={<Trans>No categories found</Trans>}
            error={error}
          />
        );

      case 'result':
        if (!flatCategories || flatCategories.length === 0) {
          return null;
        }

        return (
          <motion.div
            className='w-full'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className='flex flex-col gap-1.5'>
              <ToolStatus icon={<Search />}>
                {plural(flatCategories?.length ?? 0, {
                  one: 'Found 1 category',
                  other: 'Found # categories',
                })}
              </ToolStatus>
            </div>

            <div className='flex flex-wrap gap-1 mt-2'>
              {filteredCategories.map(category => (
                <Button
                  variant='primary'
                  size='xs'
                  className='!rounded-full'
                  key={category.id}
                  onClick={() => {
                    append?.(
                      t`Show me products in "${category.displayName ?? category.name}" category`,
                    );
                  }}
                >
                  {category.displayName ?? category.name}
                </Button>
              ))}
              <Button
                variant='ghost'
                size='xs'
                outline
                className='!rounded-full'
                onClick={() => {
                  setExpanded(value => !value);
                }}
              >
                {expanded ? <Trans>Show less</Trans> : <Trans>Show more</Trans>}
              </Button>
            </div>
          </motion.div>
        );

      default:
        return null;
    }
  },
);
