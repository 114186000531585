import { Role } from '@kanbu/schema/enums';
import { redirect } from '@tanstack/react-router';

import { useBoundStore } from '@/store/store';

import { ForbiddenError } from './errors';

/**
 * Guard that checks if the user is authorized to access the route.
 * If the user is not authorized, it throws a ForbiddenError.
 */
export function accessGuard(roles: Role[], organizationId?: string) {
  const { user } = useBoundStore.getState();

  if (!user) {
    throw new ForbiddenError('User is not available.');
  }

  if (!roles.includes(user.role)) {
    throw new ForbiddenError('User is not authorized.');
  }

  if (
    user.role !== Role.SuperAdmin &&
    organizationId &&
    user.organization?.id !== organizationId
  ) {
    throw new ForbiddenError('User is not authorized.');
  }
}

/**
 * Guard that checks if the user is authenticated.
 * If the user is not authenticated, it redirects to the login page.
 */
export function authGuard() {
  if (useBoundStore.getState().jwt === null) {
    throw redirect({
      to: '/login',
      search: {
        // Use the current location to power a redirect after login
        redirect: location.pathname,
      },
    });
  }
}
