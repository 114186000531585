import { Trans } from '@lingui/react/macro';
import { motion } from 'framer-motion';
import { AlertCircle } from 'lucide-react';
import type { ReactNode } from 'react';

interface ToolErrorProps {
  message?: ReactNode;
  error?: string;
}

export function ToolError({ message, error }: ToolErrorProps) {
  return (
    <motion.div
      className='w-full border border-danger/20 bg-danger/5 rounded-3xl overflow-hidden'
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className='flex flex-col p-3 gap-2'>
        <div className='flex text-sm items-center gap-2 text-danger'>
          <AlertCircle className='size-5 shrink-0' />
          <span className='font-medium'>
            {message || (
              <Trans>An error occurred while processing your request</Trans>
            )}
          </span>
        </div>

        {error && (
          <div className='text-xs text-muted-foreground bg-background/50 p-2 rounded border border-border'>
            {error}
          </div>
        )}
      </div>
    </motion.div>
  );
}
