import { memo } from 'react';
import ReactMarkdown, { type Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

const components: Partial<Components> = {
  pre: ({ children }) => (
    <pre className='bg-muted p-2 rounded-md'>{children}</pre>
  ),
  img: ({ node, children, ...props }) => {
    return (
      <img className='w-full border border-border rounded-md my-3' {...props} />
    );
  },
  p: ({ node, children, ...props }) => {
    return (
      <p className='my-1' {...props}>
        {children}
      </p>
    );
  },
  ol: ({ node, children, ...props }) => {
    return (
      <ol
        className='list-decimal my-1 list-outside ml-8 [&_ul]:ml-3 [&_ol]:ml-3'
        {...props}
      >
        {children}
      </ol>
    );
  },
  li: ({ node, children, ...props }) => {
    return (
      <li className='py-0.5' {...props}>
        {children}
      </li>
    );
  },
  ul: ({ node, children, ...props }) => {
    return (
      <ul
        className='list-decimal my-1 list-outside ml-8 [&_ul]:ml-3 [&_ol]:ml-3'
        {...props}
      >
        {children}
      </ul>
    );
  },
  strong: ({ node, children, ...props }) => {
    return (
      <span className='font-semibold' {...props}>
        {children}
      </span>
    );
  },
  a: ({ node, children, ...props }) => {
    return (
      <a className='underline' target='_blank' rel='noreferrer' {...props}>
        {children}
      </a>
    );
  },
  h1: ({ node, children, ...props }) => {
    return (
      <h1 className='text-3xl font-semibold mt-4 mb-2' {...props}>
        {children}
      </h1>
    );
  },
  h2: ({ node, children, ...props }) => {
    return (
      <h2 className='text-2xl font-semibold mt-4 mb-2' {...props}>
        {children}
      </h2>
    );
  },
  h3: ({ node, children, ...props }) => {
    return (
      <h3 className='text-xl font-semibold mt-4 mb-2' {...props}>
        {children}
      </h3>
    );
  },
  h4: ({ node, children, ...props }) => {
    return (
      <h4 className='text-lg font-semibold mt-4 mb-2' {...props}>
        {children}
      </h4>
    );
  },
  h5: ({ node, children, ...props }) => {
    return (
      <h5 className='text-base font-semibold mt-4 mb-2' {...props}>
        {children}
      </h5>
    );
  },
  h6: ({ node, children, ...props }) => {
    return (
      <h6 className='text-sm font-semibold mt-4 mb-2' {...props}>
        {children}
      </h6>
    );
  },

  // Table styles
  table: ({ node, children, ...props }) => {
    return (
      <div className='overflow-x-auto my-4 max-w-[300px]'>
        <table
          className='w-full border-collapse border border-border rounded-md'
          {...props}
        >
          {children}
        </table>
      </div>
    );
  },
  thead: ({ node, children, ...props }) => {
    return (
      <thead className='bg-muted' {...props}>
        {children}
      </thead>
    );
  },
  tbody: ({ node, children, ...props }) => {
    return (
      <tbody className='divide-y divide-border' {...props}>
        {children}
      </tbody>
    );
  },
  tr: ({ node, children, ...props }) => {
    return (
      <tr className='hover:bg-muted/50' {...props}>
        {children}
      </tr>
    );
  },
  th: ({ node, children, ...props }) => {
    return (
      <th
        className='px-4 py-2 text-left font-semibold border-b border-border'
        {...props}
      >
        {children}
      </th>
    );
  },
  td: ({ node, children, ...props }) => {
    return (
      <td
        className='px-4 py-2 border-r last:border-r-0 border-border'
        {...props}
      >
        {children}
      </td>
    );
  },
};

const remarkPlugins = [remarkGfm];

function NonMemoizedMarkdown({ children }: { children: string }) {
  return (
    <ReactMarkdown remarkPlugins={remarkPlugins} components={components}>
      {children}
    </ReactMarkdown>
  );
}

export const Markdown = memo(
  NonMemoizedMarkdown,
  (prevProps, nextProps) => prevProps.children === nextProps.children,
);
