import { formatUtils } from '@kanbu/shared';
import { memo } from 'react';

import { useTimeAgo } from '../../hooks/useTimeAgo';
import { Message, type SharedMessageProps } from '../ui/Message';
import { UserAvatar } from '../UserAvatar';

export interface AdministratorMessageProps extends SharedMessageProps {}

export const AdministratorMessage = memo(
  ({
    group,
    position = 'left',
    variant = 'tertiary',
  }: AdministratorMessageProps) => {
    const { messages, createdAt } = group;
    const timeAgo = useTimeAgo(createdAt);
    const { user } = messages.at(-1) ?? {};

    if (!user) {
      return null;
    }

    const fullName = formatUtils.fullName(user);

    return (
      <Message.Root variant={variant} position={position}>
        <Message.Header>{fullName}</Message.Header>
        <Message.Wrapper>
          <Message.Sidebar>
            <Message.Avatar>
              <UserAvatar fallback={formatUtils.initials(fullName)} />
            </Message.Avatar>
          </Message.Sidebar>
          <Message.Content>
            <Message.BubbleGroup>
              {messages.map(message => (
                <Message.Bubble key={message.id}>
                  {message.content}
                </Message.Bubble>
              ))}
            </Message.BubbleGroup>
          </Message.Content>
        </Message.Wrapper>
        <Message.Footer>{timeAgo}</Message.Footer>
      </Message.Root>
    );
  },
);
