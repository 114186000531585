import type { OperatorType } from '@kanbu/schema';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useTranslation } from 'react-i18next';

import { TablePage } from '@/components/page/TablePage';
import { useOperatorsCols } from '@/features/operators/useOperatorsCols';
import { useDetailRowHandler } from '@/hooks/useDetailRowHandler';
import { useTableQuery } from '@/hooks/useTableQuery';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chats/$chatId/operators/')({
  component: OperatorsPage,
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId }),
});

export function OperatorsPage() {
  const { chatId } = Route.useParams();
  const { t } = useTranslation(['globals', 'operators']);
  const columns = useOperatorsCols();
  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });

  const handleQuery = useTableQuery(trpcClient.operators.findAll.query, {
    where: {
      chat: chatId,
    },
  });

  const handleRow = useDetailRowHandler<OperatorType>(row => ({
    to: '/chats/$chatId/operators/$operatorId/edit',
    params: {
      chatId,
      operatorId: row.original.id,
    },
  }));

  return (
    <TablePage
      title={t('operators:texts.manage')}
      breadcrumbs={[
        {
          label: data.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t('globals:routeNames.operators'),
          to: '/chats/$chatId/operators',
          params: { chatId },
        },
      ]}
      createButton={{
        to: '/chats/$chatId/operators/create',
        params: { chatId },
        label: t('operators:actions.create'),
      }}
      columns={columns}
      queryKey={[...getQueryKey(trpc.operators.findAll), { chatId }]}
      onQueryFn={handleQuery}
      onRow={handleRow}
    />
  );
}
