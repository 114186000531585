import { z } from 'zod';

import { baseEntitySchema } from './baseContract';
import { chatMessageSchema } from './chatMessageContract';
import { userSchema } from './userContract';

// Base Thread schema
export const threadSchema = baseEntitySchema.extend({
  chatUser: z.string().uuid(),
  title: z.string().max(100),
  chat: z.string().uuid(),
  messages: z.array(chatMessageSchema).optional(),
});

// Create DTO
export const createThreadSchema = threadSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    messages: true,
  })
  .strict();

// Update DTO
export const updateThreadSchema = threadSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

// Types
export type ThreadDTO = z.infer<typeof threadSchema>;
export type CreateThreadDTO = z.infer<typeof createThreadSchema>;
export type UpdateThreadDTO = z.infer<typeof updateThreadSchema>;

// Takeover types
export const takeoverRequestSchema = z.object({
  message: z.string(),
  createdAt: z.string().datetime(),
  user: userSchema.pick({
    id: true,
    firstName: true,
    lastName: true,
    photo: true,
  }),
});

export const createTakeoverRequestSchema = takeoverRequestSchema
  .extend({
    threadId: z.string().uuid(),
  })
  .omit({
    createdAt: true,
  });

export type TakeoverRequestDTO = z.infer<typeof takeoverRequestSchema>;
export type CreateTakeoverRequestDTO = z.infer<
  typeof createTakeoverRequestSchema
>;
