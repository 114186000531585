/**
 * This file contains global constants that are used throughout the application.
 * Make sure to not include any secrets as they are exposed to the client.
 */

/**
 * The threshold for the online status of a thread.
 * This is used to determine if a thread is online or offline.
 *
 * We determine user online presence based on the last active at date.
 * We use this threshold to determine if a user is online or offline.
 * If the last active date (on thread) is older than current date
 * plus the threshold, we consider the user offline.
 */
export const ONLINE_STATUS_THRESHOLD = 40000; // 40 seconds
