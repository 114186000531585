import { createFileRoute } from '@tanstack/react-router';

import { Inbox } from '../../../components/inbox/Inbox';

export const Route = createFileRoute('/_fullWidthLayout/inbox/')({
  component: RouteComponent,
});

function RouteComponent() {
  return <Inbox />;
}
