import type { FeedType } from '@kanbu/schema';
import { createFeedSchema, updateFeedSchema } from '@kanbu/schema/contracts';
import { useLingui } from '@lingui/react/macro';
import { memo } from 'react';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useEnumTranslations } from '@/locale/enumTranslations.js';

import { useFeedMutation } from './useFeedMutation.js';

export type FeedFormProps = {
  data?: FeedType;
  chatId: string;
};

export const FeedForm = memo(function FeedForm({
  data,
  chatId,
}: FeedFormProps) {
  const { t } = useLingui();
  const enumTranslations = useEnumTranslations();
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useFeedMutation();

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'name',
        label: t`Name`,
        required: true,
      },
      {
        name: 'url',
        label: t`Feed URL`,
        required: true,
      },
      {
        name: 'source',
        label: t`Feed Source`,
        type: 'select',
        options: enumTranslations.feedSource,
        required: true,
      },
      {
        name: 'updateFrequencyMinutes',
        label: t`Update Frequency`,
        type: 'number',
      },
    ],
    [t],
  );

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createFeedSchema.omit({ chat: true })}
      updateSchema={updateFeedSchema.omit({ chat: true })}
      onCreate={values => create({ ...values, chat: chatId })}
      onUpdate={update}
      onRemove={remove}
      basePath={`/chats/${chatId}/feeds`}
      idParamName='feedId'
    >
      <EntityCRUDForm.Col>
        <Card title={t`Basic Details`}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col />
      <EntityCRUDForm.Col>
        <MetaFields data={data} />
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
