import { getOperatorArtifactSchema } from '@kanbu/schema/contracts';
import { ThreadMode } from '@kanbu/schema/enums';
import { formatUtils } from '@kanbu/shared';
import { Trans } from '@lingui/react/macro';
import { Button } from '@utima/ui';
import { useState } from 'react';

import { createToolComponent } from '../../../toolRegistry';
import { OperatorInfo } from '../../operator/OperatorInfo';
import { Message } from '../../ui/Message';
import { ToolError } from '../components/ToolError';
import { ToolStatus } from '../components/ToolStatus';

export interface GetOperatorToolProps {
  onTakeover?: (accepted: boolean) => Promise<void>;
  mode?: ThreadMode;
}

export const GetOperatorTool = createToolComponent<
  GetOperatorToolProps,
  never,
  never,
  typeof getOperatorArtifactSchema
>(
  {
    schema: {
      artifact: getOperatorArtifactSchema,
    },
    validate: false,
  },
  ({ state, error, artifact, mode, onTakeover }) => {
    const { operator } = artifact ?? {};
    const [isLoading, setIsLoading] = useState(false);

    console.log(mode);

    const handleTakeover = async (accepted: boolean) => {
      try {
        if (!onTakeover) {
          return;
        }

        setIsLoading(true);
        await onTakeover(accepted);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    switch (state) {
      case 'call':
        return (
          <ToolStatus loading>
            <Trans>Searching...</Trans>
          </ToolStatus>
        );

      case 'call-partial':
      case 'call-complete':
        return (
          <ToolStatus loading>
            <Trans>Looking for operators...</Trans>
          </ToolStatus>
        );

      case 'error':
        return (
          <ToolError message={<Trans>Unknown error</Trans>} error={error} />
        );

      case 'result':
        const showOperatorContact = operator?.firstName && operator?.lastName;

        return (
          <>
            <Message.BubbleGroup>
              <Message.Bubble>
                <Trans>
                  To connect with our operator, please click the button below.
                </Trans>
              </Message.Bubble>
            </Message.BubbleGroup>
            <Button
              loading={isLoading}
              disabled={isLoading}
              variant={mode === ThreadMode.Human ? 'ghost' : 'primary'}
              outline={mode === ThreadMode.Human}
              onClick={() => handleTakeover(mode === ThreadMode.AI)}
              className='!rounded-full'
            >
              {mode === ThreadMode.Human ? (
                <Trans>Go back to AI</Trans>
              ) : (
                <Trans>Yes, connect me</Trans>
              )}
            </Button>
            {showOperatorContact && (
              <Message.BubbleGroup>
                <Message.Bubble>
                  <Trans>
                    Or you can try to contact us using the contact information
                    below.
                  </Trans>
                </Message.Bubble>
                <OperatorInfo
                  operator={{
                    name: formatUtils.fullName(operator),
                    email: operator.email ?? undefined,
                    phone: operator.phone ?? undefined,
                  }}
                />
              </Message.BubbleGroup>
            )}
          </>
        );

      default:
        return null;
    }
  },
);
