export type SocketErrorType = 'unauthorized' | 'rateLimit' | 'serverError';

/**
 * Custom error class for socket errors.
 * The Socket.io supports custom params serialization
 * in the error object.
 */
export class SocketError extends Error {
  /**
   * Additional data to be serialized to the client.
   */
  readonly data: {
    message: string;
    name: string;
    type: SocketErrorType;
  } & Record<string, unknown>;

  /**
   * Get the type of the error.
   */
  get type() {
    return this.data.type;
  }

  /**
   * Get the message of the error.
   */
  get message() {
    return this.data.message;
  }

  constructor(
    type: SocketErrorType,
    message: string,
    data?: Record<string, unknown>,
  ) {
    super(message);

    this.name = 'SocketError';
    this.data = {
      name: this.name,
      message,
      type,
      ...data,
    };
  }

  /**
   * Create a SocketError from an unknown error.
   * If the error is not a SocketError, it will be converted to a server error.
   */
  static fromError(error: unknown): SocketError {
    try {
      if (typeof error === 'object' && error !== null) {
        const { message, data, type } = error as SocketError;

        return new SocketError(type, message, data);
      }
    } catch {}

    return new SocketError('serverError', 'Unknown error');
  }
}
