import { createContext, useContext } from 'react';

export type MessageVariant = 'primary' | 'secondary' | 'tertiary';
export type MessagePosition = 'left' | 'right';

export interface MessageContextValue {
  variant: MessageVariant;
  position: MessagePosition;
}

export const MessageContext = createContext<MessageContextValue>({
  variant: 'primary',
  position: 'left',
});

export function useMessageContext() {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error('MessageContext not found');
  }

  return context;
}
