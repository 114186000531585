import type { FindOneParams } from '@kanbu/shared';
import { useLingui } from '@lingui/react/macro';
import { createFileRoute } from '@tanstack/react-router';

import { FormPage } from '@/components/page/FormPage';
import { FeedForm } from '@/features/feeds/FeedForm';
import { trpc } from '@/services/trpc';

const requestParams: Omit<FindOneParams, 'id'> = {
  populate: ['chat'],
  fields: ['*', 'chat.name', 'chat.id'],
};

export const Route = createFileRoute(
  '/_baseLayout/chats/$chatId/feeds/$feedId/edit',
)({
  component: FeedsEditPage,
  loader: async ({ context: { trpcUtils }, params: { feedId, chatId } }) =>
    Promise.all([
      trpcUtils.feeds.findOne.ensureData({
        id: feedId,
        ...requestParams,
      }),
      trpcUtils.chats.findOne.ensureData({ id: chatId }),
    ]),
});

export function FeedsEditPage() {
  const { feedId, chatId } = Route.useParams();
  const { t } = useLingui();
  const [chatData] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });
  const [data] = trpc.feeds.findOne.useSuspenseQuery({
    id: feedId,
    ...requestParams,
  });

  return (
    <FormPage
      title={t`Edit Feed` + ` - ${data.name}`}
      breadcrumbs={[
        {
          label: chatData.name,
          to: '/chats/$chatId/edit',
          params: { chatId },
        },
        {
          label: t`Feeds`,
          to: '/chats/$chatId/feeds',
          params: { chatId },
        },
        {
          label: data.name,
          to: '/chats/$chatId/feeds/$feedId/edit',
          params: { chatId, feedId },
        },
      ]}
    >
      <FeedForm chatId={chatId} data={data} />
    </FormPage>
  );
}
