import { DEFAULT_DOCUMENT_WEIGHT } from '@kanbu/shared';
import { Trans } from '@lingui/react/macro';
import { cn } from '@utima/ui';
import { Loader2 } from 'lucide-react';
import { memo, useState } from 'react';

type WeightSelectorProps = {
  value?: number;
  onChange: (value: number) => void | Promise<void>;
  segments?: number;
  min?: number;
  max?: number;
  showLabels?: boolean;
  disabled?: boolean;
};

export const WeightSelector = memo(
  ({
    value = DEFAULT_DOCUMENT_WEIGHT,
    onChange,
    segments = 5,
    min = 0,
    max = 1,
    showLabels = true,
    disabled = false,
  }: WeightSelectorProps) => {
    const defaultValue = (max - min) / 2 + min;
    const effectiveValue = value ?? defaultValue;
    const [hoveredValue, setHoveredValue] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const values = Array.from({ length: segments }, (_, i) =>
      Number((min + (i / (segments - 1)) * (max - min)).toFixed(3)),
    );

    const handleChange = async (value: number) => {
      setIsLoading(true);
      await onChange(value);
      setIsLoading(false);
    };

    const activeColors = [
      'bg-green-300',
      'bg-green-500',
      'bg-yellow-500',
      'bg-orange-500',
      'bg-red-500',
    ];

    const itemStyles = [
      'bg-green-100 hover:bg-green-100 text-green-200',
      'bg-green-200 hover:bg-green-200 text-white',
      'bg-yellow-200 hover:bg-yellow-200 text-black',
      'bg-orange-200 hover:bg-orange-200 text-white',
      'bg-red-200 hover:bg-red-200 text-white',
    ];

    const isDisabled = disabled || isLoading;

    return (
      <div className='relative flex w-full' data-table='prevent'>
        {isLoading && (
          <span className='absolute left-0 top-0 flex size-full items-center justify-center'>
            <Loader2 className='size-3 animate-spin text-muted-fg' />
          </span>
        )}
        {values.map((val, i) => {
          const isActive = Number(effectiveValue) >= val;
          const isHovered = hoveredValue !== null && val <= hoveredValue;
          const showActive = isActive;
          const showPreview =
            hoveredValue !== null &&
            hoveredValue >= effectiveValue &&
            isHovered &&
            !isActive;

          return (
            <div
              key={val}
              onClick={() => !isDisabled && handleChange(val)}
              onMouseEnter={() => !isDisabled && setHoveredValue(val)}
              onMouseLeave={() => !isDisabled && setHoveredValue(null)}
              className={cn(
                'h-4 min-w-10 flex-1 border-y border-gray-200',
                'flex items-center justify-center transition-colors',
                [itemStyles[i]],
                {
                  'bg-gray-300': isDisabled,
                  [activeColors[i]]: !isDisabled && showActive,
                  'bg-gray-100': !isDisabled && !showActive && !showPreview,
                  'cursor-pointer': !isDisabled,
                  'rounded-l-full border-r-0 border-l': i === 0,
                  'rounded-r-full border-l-0 border-r': i === segments - 1,
                },
              )}
            >
              {showLabels && i === 0 && (
                <span
                  className={cn('pl-1 text-xs font-medium', {
                    'text-gray-500': showActive && isDisabled,
                    'text-gray-400': !showActive,
                  })}
                >
                  <Trans>Low</Trans>
                </span>
              )}
              {showLabels && i === segments - 1 && (
                <span
                  className={cn('pr-3 text-xs font-medium', {
                    'text-gray-500': showActive && isDisabled,
                    'text-gray-400': !showActive,
                  })}
                >
                  <Trans>High</Trans>
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  },
);
