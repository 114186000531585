import { createRouter } from '@tanstack/react-router';

import { accessGuard, authGuard } from '@/lib/routerUtils';

import { queryClient } from './queryClient';
import { trpcUtils } from './trpc';
import { routeTree } from '../routeTree.gen';

/**
 * Tanstack router base configuration
 */
export const router = createRouter({
  routeTree,
  context: {
    accessGuard,
    authGuard,
    queryClient: queryClient,
    trpcUtils: trpcUtils,
  },
});

// Register things for typesafety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
