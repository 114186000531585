import type { OrganizationType } from '@kanbu/schema';
import {
  createOrganizationSchema,
  updateOrganizationSchema,
} from '@kanbu/schema/contracts';
import { formatUtils } from '@kanbu/shared';
import { useLingui } from '@lingui/react/macro';
import { cn } from '@utima/ui';
import { memo } from 'react';

import { DisplayValue } from '@/components/displayValue/DisplayValue';
import { MetaFields } from '@/components/fields/MetaFields';
import { EntityCRUDForm } from '@/components/form/EntityCRUDForm';
import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

import { TierConfigFields } from './TierConfigFields';
import { WorkingHours } from './workingHours/WorkingHours';
import { useOrganizationMutation } from '../hooks/useOrganizationMutation';

export type OrganizationFormProps = {
  data?: OrganizationType;
};

export const OrganizationForm = memo(function OrganizationForm({
  data,
}: OrganizationFormProps) {
  const { t } = useLingui();
  const user = useBoundStore(state => state.user);
  const setUser = useBoundStore(state => state.setUser);
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove },
    update: { mutateAsync: update },
  } = useOrganizationMutation();

  const { refetch: refetchUser } = trpc.users.me.useQuery(undefined, {
    enabled: false,
  });

  const current = data?.currentMonthlyUsage ?? 0;
  const limit = data?.monthlyUsageLimit ?? 0;

  return (
    <EntityCRUDForm
      initialData={data}
      createSchema={createOrganizationSchema}
      updateSchema={updateOrganizationSchema}
      onCreate={create}
      onUpdate={async values => {
        const updatedValue = await update(values);

        // Refetch user on organization update
        if (updatedValue.id === user?.organization.id) {
          const updatedUser = await refetchUser();

          if (updatedUser.data) {
            setUser(updatedUser.data);
          }
        }

        return updatedValue;
      }}
      onRemove={remove}
      basePath='/organizations'
      idParamName='organizationId'
    >
      <EntityCRUDForm.Col>
        <TierConfigFields />
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col>
        <WorkingHours name='workingHours' />
      </EntityCRUDForm.Col>
      <EntityCRUDForm.Col>
        <MetaFields data={data}>
          <DisplayValue label={t`Monthly usage limit`}>
            <span
              className={cn(
                'inline-flex gap-1 items-baseline',
                current > limit ? 'text-red-600' : 'text-green-600',
              )}
            >
              <span className='font-semibold'>
                {formatUtils.currencyDollar(current)}
              </span>
              /
              <span className='text-xs font-semibold'>
                {formatUtils.currencyDollar(limit)}
              </span>
            </span>
          </DisplayValue>
        </MetaFields>
      </EntityCRUDForm.Col>
    </EntityCRUDForm>
  );
});
