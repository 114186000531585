import type { ThreadType } from '@kanbu/schema';
import { IconX } from '@tabler/icons-react';
import { memo } from 'react';

import avatar from '@/assets/images/avatar.webp';
import kanbuAvatar from '@/assets/images/bubble-kanbu.webp';

import { AdminChatRenderer } from './AdminChatRenderer';

interface ChatSidebarProps {
  thread: ThreadType | null;
  setDrawerOpen: (value: boolean) => void;
}

export const ChatSidebar = memo(
  ({ thread, setDrawerOpen }: ChatSidebarProps) => {
    const systemAvatar = thread?.chat?.chatbotConfig?.systemAvatar;
    const avatarSrc =
      systemAvatar ??
      (thread?.chat?.themeConfig?.preset === 'kanbu' ? kanbuAvatar : avatar);

    return (
      <div className='duration-400 flex h-screen flex-col overflow-y-auto bg-white p-5 transition fade-in'>
        <div className='mb-4 flex flex-row justify-between border-b pb-2'>
          <div className='flex flex-col'>
            <p className='text-xl font-bold'>{thread?.title}</p>
            <p className='text-xs text-muted-fg'>{thread?.id}</p>
          </div>
          <IconX
            className='size-5 cursor-pointer'
            onClick={() => setDrawerOpen(false)}
          />
        </div>
        <AdminChatRenderer
          messages={thread?.messages ?? []}
          agentName={thread?.chat?.agentName}
          agentAvatar={avatarSrc}
        />
      </div>
    );
  },
);
