import type { ChatMessageType } from '@kanbu/schema';
import { ToolNames } from '@kanbu/schema/contracts';
import {
  ChatRenderer,
  ChatRendererProvider,
  createToolRegistry,
  GetOperatorTool,
  GetShopCategoriesTool,
  GetShopProductAccessoriesTool,
  GetShopProductsTool,
  GetShopProductTool,
  OperatorResultTool,
  RagResultTool,
  UsageLimitsErrorTool,
} from '@kanbu/shared-ui';

import { AdminFeedback } from './AdminFeedback';

const toolRegistry = createToolRegistry({
  [ToolNames.RagResult]: RagResultTool,
  [ToolNames.RagResultOperator]: OperatorResultTool,
  [ToolNames.GetShopProducts]: GetShopProductsTool,
  [ToolNames.UsageLimitsError]: UsageLimitsErrorTool,
  [ToolNames.GetShopProduct]: GetShopProductTool,
  [ToolNames.GetShopCategories]: GetShopCategoriesTool,
  [ToolNames.GetShopProductAccessories]: GetShopProductAccessoriesTool,
  [ToolNames.GetOperator]: GetOperatorTool,
});

export interface AdminChatRendererProps {
  messages: ChatMessageType[];
  agentName?: string;
  agentAvatar?: string;
}

export function AdminChatRenderer({
  messages,
  agentName,
  agentAvatar,
}: AdminChatRendererProps) {
  return (
    <ChatRendererProvider>
      <ChatRenderer
        messages={messages}
        toolRegistry={toolRegistry}
        FeedbackComponent={AdminFeedback}
        agentName={agentName}
        agentAvatar={agentAvatar}
      />
    </ChatRendererProvider>
  );
}
