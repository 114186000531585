/**
 * Status of the product availability
 *
 * The order is based on the assumption that the product is
 * available in stock and ready to ship immediately.
 *
 * InStock > OutOfStock > OnOrder > (PreOrder) > Unknown
 */
export enum ProductAvailabilityStatus {
  InStock = 'in_stock',
  OutOfStock = 'out_of_stock',
  OnOrder = 'on_order',
  PreOrder = 'pre_order',
  Unknown = 'unknown',
}

export enum FeedSource {
  Heureka = 'heureka',
}

/**
 * Status of the feed used for tracking the feed processing
 * and importing.
 */
export enum FeedStatus {
  Pending = 'pending',
  Processing = 'processing',
  Done = 'done',
  Failed = 'failed',
}
