import type { UserType } from '@kanbu/schema';
import type { ChatRole } from '@kanbu/schema/enums';
import { v7 as uuid } from 'uuid';

import type { ChatMessageItem } from './types';

/**
 * Helper for creating a message object in chat.
 */
export function createMessage(data: {
  message: string | ChatMessageItem;
  role: ChatRole;
  user?: UserType | null | undefined;
  isInitial?: boolean;
}): ChatMessageItem {
  return typeof data.message === 'string'
    ? {
        role: data.role,
        content: data.message,
        user: data.user,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: uuid(),
        isInitial: data.isInitial ?? false,
      }
    : {
        ...data.message,
        role: data.message?.role ?? data.role,
        user: data.message?.user ?? data.user,
        isInitial: data.isInitial ?? false,
      };
}
