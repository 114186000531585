import { getWsUrlFromHttpUrl } from '@kanbu/shared';
import { Trans, useLingui } from '@lingui/react/macro';
import { Button, cn, toast } from '@utima/ui';
import { Howl } from 'howler';
import { Phone, PhoneOff } from 'lucide-react';
import { memo, useEffect, useRef, useState } from 'react';

import { AppSettings } from '@/constants/AppSettings';

/**
 * Converts Blob data to ArrayBuffer and encodes it with JSON
 * metadata to as binary data.
 */
async function encodeWithMetadata(
  data: Blob,
  metadata: any,
): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const buffer = reader.result as ArrayBuffer;

      // Combine metadata and binary data
      const encoder = new TextEncoder();
      const metadataBytes = encoder.encode(JSON.stringify(metadata));
      const combinedData = new Uint8Array(
        4 + metadataBytes.length + buffer.byteLength,
      );

      // Create data view
      const dataView = new DataView(combinedData.buffer);
      dataView.setUint32(0, metadataBytes.length, true); // Prefix metadata length
      combinedData.set(metadataBytes, 4); // Write metadata
      combinedData.set(new Uint8Array(buffer), 4 + metadataBytes.length);

      // Send combined data to server
      resolve(combinedData.buffer);
    });

    // eslint-disable-next-line unicorn/prefer-blob-reading-methods
    reader.readAsArrayBuffer(data);
  });
}

export interface VoiceTesterProps {
  chatId: string;
}

export const VoiceTester = memo(function VoiceTester({
  chatId,
}: VoiceTesterProps) {
  const { t } = useLingui();
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const socket = useRef<WebSocket | null>(null);
  const howl = useRef<Howl | null>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const audioChunks = useRef<Blob[]>([]);

  const createHowlFromBlob = (blob: Blob) => {
    const url = URL.createObjectURL(blob);

    return new Howl({
      src: [url],
      format: ['mp3'],
      autoplay: true,
      onend: () => {
        URL.revokeObjectURL(url); // Clean up the URL
        // When the current chunk finishes, play the next one
        if (audioChunks.current.length > 0) {
          const nextBlob = audioChunks.current.shift(); // Take only the first chunk
          if (nextBlob) {
            howl.current = createHowlFromBlob(
              new Blob([nextBlob], { type: 'audio/mp3' }),
            );
          }
        }
      },
    });
  };

  const startRecording = async () => {
    try {
      // Init audio context
      audioContext.current = new AudioContext();

      // Connect web socket
      socket.current = new WebSocket(
        `${getWsUrlFromHttpUrl(AppSettings.voiceApiUrl)}/calls/chats/${chatId}/web`,
      );

      // We send binary data
      socket.current.binaryType = 'arraybuffer';

      socket.current.addEventListener('message', (event: MessageEvent) => {
        const audioData = event.data;
        audioChunks.current.push(audioData);

        // Only start playback if there's no Howl instance currently playing
        if (!howl.current || !howl.current.playing()) {
          const firstChunk = audioChunks.current.shift(); // Take only the first chunk
          if (firstChunk) {
            howl.current = createHowlFromBlob(
              new Blob([firstChunk], { type: 'audio/mp3' }),
            );
          }
        }
      });

      socket.current.addEventListener('close', () => {
        stopRecording();
      });

      socket.current.addEventListener('error', error => {
        console.error('WebSocket error:', error);
        toast.error(t`An error occurred while connecting to the server`, {
          description: t`The connection may occasionally drop due to server overload, please try again.`,
        });

        stopRecording();
      });

      // Start audio recording
      socket.current.addEventListener('open', async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            sampleRate: 16000,
          },
        });

        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = async event => {
          if (event.data.size <= 0 || socket.current?.readyState !== 1) {
            return;
          }

          // Encode data with metadata to binary
          const encodedData = await encodeWithMetadata(event.data, {
            timestamp: Date.now(),
            custom: 'custom',
            really: {
              long: {
                tree: true,
                random: Math.random(),
              },
            },
          });

          // Send data to server
          if (socket.current) {
            socket.current.send(encodedData);
          }
        };

        /// IMPORTANT!!! Deepgram can't handle less than 350ms interval
        mediaRecorder.current.start(350);
        setIsRecording(true);
      });
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);

    // Stop connections
    mediaRecorder.current?.stop();
    socket.current?.close();
    audioContext.current?.close();
    howl.current?.stop();

    // Reset refs
    mediaRecorder.current = null;
    socket.current = null;
    audioContext.current = null;
    howl.current = null;
    audioChunks.current = [];
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  return (
    <Button
      variant={isRecording ? 'danger' : 'success'}
      size='lg'
      onClick={toggleRecording}
      className={cn('shrink-0', isRecording && 'animate-pulse')}
      title={isRecording ? t`Stop recording` : t`Start recording`}
    >
      {isRecording ? (
        <>
          <PhoneOff className='size-5' />
          <Trans>Hang up</Trans>
        </>
      ) : (
        <>
          <Phone className='size-5' />
          <Trans>Call Agent</Trans>
        </>
      )}
    </Button>
  );
});
