import { cn } from '@utima/ui';
import { memo, type ReactNode } from 'react';

import { useSidebarPaneContext } from '@/components/sidebarPane/SidebarPaneContext';

export const SidebarPaneDrawer = memo(function ({
  children,
}: {
  children: ReactNode;
}) {
  const { isOpen } = useSidebarPaneContext();

  return (
    <div
      className={cn(
        `fixed top-0 h-full transition-right duration-200 ease-out w-screen md:w-[500px] max-w-screen-md border-l z-[99]`,
        {
          'right-0': isOpen,
          '-right-full': !isOpen,
          'md:-right-[500px]': !isOpen,
        },
      )}
    >
      {children}
    </div>
  );
});
