import { Trans, useLingui } from '@lingui/react/macro';
import { Button, cn } from '@utima/ui';
import { AlertCircle, Loader2 } from 'lucide-react';
import { memo, useState } from 'react';

interface TakeoverRequestProps {
  onRequestTakeover: (message: string) => Promise<void>;
  hasPendingRequest: boolean;
  isLoading: boolean;
  onCancel: () => Promise<void>;
  className?: string;
}

export const TakeoverRequest = memo(
  ({
    onRequestTakeover,
    hasPendingRequest,
    isLoading,
    onCancel,
    className,
  }: TakeoverRequestProps) => {
    const { t } = useLingui();
    const [requestMessage, setRequestMessage] = useState(
      t`Hello! I noticed you're chatting with our AI. Would you like to speak with a human operator instead?`,
    );

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();

      if (!requestMessage.trim() || isLoading) {
        return;
      }

      await onRequestTakeover(requestMessage);
    };

    if (hasPendingRequest) {
      return (
        <div className={cn(className)}>
          <div className='border-t border-border bg-amber-50 p-4'>
            <div className='flex items-center gap-3'>
              <AlertCircle className='size-8 shrink-0 text-amber-500' />
              <div className='flex-1'>
                <h3 className='mb-1 font-medium'>
                  <Trans>Takeover Request Pending</Trans>
                </h3>
                <p className='text-sm text-gray-600'>
                  <Trans>
                    Waiting for the user to respond to your request to take over
                    the conversation.
                  </Trans>
                </p>
              </div>
              {isLoading ? (
                <Loader2 className='size-5 animate-spin text-primary' />
              ) : (
                <Button variant='danger' size='sm' onClick={onCancel}>
                  <Trans>Cancel</Trans>
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={cn(className)}>
        <div className='border-t border-border bg-gray-50 p-4'>
          <div className='mb-3 flex items-start gap-3'>
            <AlertCircle className='mt-0.5 size-6 shrink-0 text-amber-500' />
            <div>
              <h3 className='mb-1 font-medium'>
                <Trans>User is chatting with AI</Trans>
              </h3>
              <p className='text-sm text-gray-600'>
                <Trans>
                  The user is currently in a conversation with our AI assistant.
                  Send a request to take over the conversation.
                </Trans>
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit} className='mt-4 space-y-3'>
            <textarea
              value={requestMessage}
              onChange={e => setRequestMessage(e.target.value)}
              placeholder={t`Enter a message user will see...`}
              className='min-h-[80px] w-full resize-none rounded-md border border-gray-300 p-3 text-sm focus:border-transparent focus:ring-2 focus:ring-primary'
            />
            <div className='flex justify-end'>
              <Button
                type='submit'
                variant='primary'
                size='sm'
                disabled={isLoading}
              >
                {isLoading && <Loader2 className='mr-2 size-4 animate-spin' />}
                <Trans>Request to Take Over</Trans>
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  },
);
