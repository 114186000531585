import { ONLINE_STATUS_THRESHOLD } from '@kanbu/shared';
import { useRef, useEffect, useState } from 'react';

export type OnlineStatus = 'online' | 'offline';

/**
 * Get the online status of a thread based on the last active at date.
 */
export function getOnlineStatus(
  lastActiveAt: string | null | undefined,
  onlineThreshold = ONLINE_STATUS_THRESHOLD,
): OnlineStatus {
  if (!lastActiveAt) {
    return 'offline';
  }

  const lastActiveAtDate = new Date(lastActiveAt);
  const isActive = lastActiveAtDate.getTime() + onlineThreshold >= Date.now();

  return isActive ? 'online' : 'offline';
}

/**
 * Hook to get the online status of a thread based on the last active at date.
 * It automatically updates the status when the online threshold is reached.
 */
export function useOnlineStatus(
  lastActiveAt: string | null | undefined,
  onlineThreshold = ONLINE_STATUS_THRESHOLD,
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [status, setStatus] = useState<OnlineStatus>(
    getOnlineStatus(lastActiveAt, onlineThreshold),
  );

  /**
   * Set a timeout to check the status when the online threshold is reached.
   */
  useEffect(() => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    // Set the initial status
    const currentStatus = getOnlineStatus(lastActiveAt, onlineThreshold);
    setStatus(currentStatus);

    // If there's no lastActiveAt or already offline, no need for a timeout
    if (!lastActiveAt || currentStatus === 'offline') {
      return;
    }

    // Calculate how much time is left until the status would change
    const lastActiveTime = new Date(lastActiveAt).getTime();
    const expirationTime = lastActiveTime + onlineThreshold;
    const now = Date.now();
    const timeUntilExpiration = expirationTime - now + 1000; // add 1 second to avoid race condition

    // Only set a timeout if the status will change in the future
    if (timeUntilExpiration > 0) {
      timeoutRef.current = setTimeout(() => {
        setStatus(getOnlineStatus(lastActiveAt, onlineThreshold));
      }, timeUntilExpiration);
    } else {
      // If the time has already passed, update the status immediately
      setStatus('offline');
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [lastActiveAt, onlineThreshold]);

  return status;
}
