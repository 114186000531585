import { Trans } from '@lingui/react/macro';
import { cn } from '@utima/ui';
import { memo } from 'react';

export interface OnlineIndicatorProps {
  withText?: boolean;
  className?: string;
  status: 'online' | 'offline';
}

export const OnlineIndicator = memo(
  ({ status, withText = true, className }: OnlineIndicatorProps) => {
    return (
      <div
        className={cn(
          'flex items-center gap-1',
          withText && 'py-0.5 px-2 rounded-full',
          withText && {
            'bg-green-100 text-green-800': status === 'online',
            'bg-red-100 text-red-800': status === 'offline',
          },
          className,
        )}
      >
        <span className='relative size-2 rounded-full'>
          {status === 'online' && (
            <span
              className={cn(
                'absolute top-0 inline-block size-2 rounded-full shrink-0 bg-green-500 animate-ping',
              )}
            />
          )}
          <span
            className={cn(
              'absolute top-0 inline-block size-2 rounded-full shrink-0',
              status === 'online' && 'bg-green-500',
              status === 'offline' && 'bg-red-500',
            )}
          />
        </span>

        {withText && (
          <span className='text-xs'>
            {status === 'online' && <Trans>Online</Trans>}
            {status === 'offline' && <Trans>Offline</Trans>}
          </span>
        )}
      </div>
    );
  },
);
