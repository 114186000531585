import { ThemePreset } from '@kanbu/schema/enums';

export const THEME_PRESETS = {
  light: {
    preset: ThemePreset.Light,
    primary: '#388BFD',
    primaryFg: '#ffffff',
    secondary: '#66BB6A',
    secondaryFg: '#ffffff',
    background: '#ffffff',
    backgroundSecondary: '#f2f2f2',
    foreground: '#333333',
    placeholder: '#aaaaaa',
  },
  dark: {
    preset: ThemePreset.Dark,
    primary: '#5A7BFD',
    primaryFg: '#FFFFFF',
    secondary: '#66BB6A',
    secondaryFg: '#FFFFFF',
    background: '#32323E',
    backgroundSecondary: '#43434F',
    foreground: '#f0f0f0',
    placeholder: '#43434F',
  },
  kanbu: {
    preset: ThemePreset.Kanbu,
    primary: '#FFCF52',
    primaryFg: '#242424',
    secondary: '#8A2BE2',
    secondaryFg: '#fff',
    background: '#fff',
    backgroundSecondary: '#f0f0f0',
    foreground: '#070606',
    placeholder: '#898989',
  },
} as const;

// TODO these should be localized
export const AgentDefaults = {
  name: 'AI Honza',
  defaultThemePreset: ThemePreset.Kanbu,
  defaultTheme: THEME_PRESETS.kanbu,
} as const;

export const DEFAULT_DOCUMENT_WEIGHT = 0.5;
