import type { ChatType } from '@kanbu/schema';
import type { EvalTestResults } from '@kanbu/schema/contracts';
import { formatUtils } from '@kanbu/shared';
import { Badge, cn, Separator } from '@utima/ui';
import { Check, X, Target, MessageCircle, Loader2 } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { EvaluationAccordion, getAccuracyColor } from './EvaluationAccordion';

export type EvaluationResultsProps = {
  chat: ChatType | undefined;
  data: EvalTestResults;
  totalMessages: number;
  loading?: boolean;
};

export const EvaluationResults = memo(function EvaluationResults({
  chat,
  data,
  totalMessages,
  loading = false,
}: EvaluationResultsProps) {
  const { t } = useTranslation(['glossary']);

  const filteredMessages = data.filter(
    test => test.messages.length > 0 && test.messages[0]?.results,
  );

  let medianAccuracy = filteredMessages.reduce(
    (acc, test) => acc + test.messages[0].results.accuracyPercentage,
    0,
  );
  medianAccuracy = medianAccuracy > 0 ? medianAccuracy / data.length : 0;

  const currentMessages = filteredMessages.reduce(
    (acc, test) => acc + test.messages.length,
    0,
  );

  const totalCorrect = filteredMessages.reduce(
    (acc, test) => acc + test.messages.filter(m => m.results.isCorrect).length,
    0,
  );

  const totalIncorrect = currentMessages - totalCorrect;
  const incorrectPercentage = totalIncorrect / currentMessages;
  const correctPercentage = totalCorrect / currentMessages;

  return (
    <div>
      <div className='flex w-full flex-col gap-2 text-sm font-medium'>
        <div className='grid w-full grid-cols-2 gap-2'>
          <span
            className='flex items-center gap-2 rounded-md bg-slate-100 p-3'
            style={{
              backgroundColor: getAccuracyColor(medianAccuracy / 100),
            }}
          >
            <Target className='size-5' />
            <span>{t('glossary:labels.accuracy')}:</span>
            <span className='font-bold'>
              {formatUtils.percentage(medianAccuracy || 0)}
            </span>
          </span>
          <span
            className={cn(
              'flex items-center justify-between gap-2 rounded-md bg-slate-200 p-3',
              totalMessages <= 0 && 'bg-red-300',
            )}
          >
            <div className='flex items-center gap-2'>
              <MessageCircle className='size-5' />
              <span>{t('glossary:labels.totalMessages')}:</span>
              <span className='font-bold'>
                {currentMessages}/{totalMessages}
              </span>
            </div>
            {loading && <Loader2 className='size-5 animate-spin' />}
          </span>
        </div>
        <div className='grid w-full grid-cols-2 gap-2'>
          <span className='flex items-center gap-2 rounded-md bg-green-100 p-3 text-green-700'>
            <Check className='size-5' />
            <span>{t('glossary:labels.totalCorrect')}:</span>
            <span className='font-bold'>
              {totalCorrect}/{currentMessages} (
              {formatUtils.percentage(correctPercentage || 0)})
            </span>
          </span>
          <span className='flex items-center gap-2 rounded-md bg-red-100 p-3 text-red-800'>
            <X className='size-5' />
            <span>{t('glossary:labels.totalIncorrect')}:</span>
            <span className='font-bold'>
              {totalIncorrect}/{currentMessages} (
              {formatUtils.percentage(incorrectPercentage || 0)})
            </span>
          </span>
        </div>
      </div>

      <Separator className='my-3' />

      <div className='flex flex-wrap gap-2 font-mono'>
        {[
          ...Object.entries(chat?.agentConfig ?? {}),
          ['agent', chat?.agent],
          ['model', chat?.model],
        ]
          .filter(
            ([key]) =>
              !key || !['systemPrompt', 'tone'].includes(key.toString()),
          )
          .map(([key, value]) => (
            <Badge key={key} variant='info' outline size='xs'>
              <span className='font-normal text-slate-800'>{key}:</span>
              <span className='font-bold text-black'>{value?.toString()}</span>
            </Badge>
          ))}
      </div>

      <Separator className='my-3' />

      {filteredMessages?.map((result, index) => (
        <EvaluationAccordion key={index} result={result} />
      ))}
    </div>
  );
});
